import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { OrderAttachments } from "../../../components/attachmentComponents";
import { SimpleBlueButton } from "../../../components/editComponents";
import {
  CreateableSelectField,
  DateInput,
  ReadOnlyField,
  StyledInput,
  StyledLabel,
  StyledTextarea,
} from "../../../components/inputFields";
import {
  ContactCard,
  Modal,
  PriceText,
  SubTitle,
  ProductCodeLinkToNewTab,
  XeroAccountMappingTip,
} from "../../../components/viewComponents";
import { convtPrice, formatDate } from "../../../functions/functions";
import commonStyle from "../../../style/commonStyle.module.css";
import salesStyle from "../../sale/salesStyle.module.css";
import ProjectForm from "../../settings/ProjectForm";
import NewSupplierModal from "../../supplier/NewSupplierModal";
import purchaseStyle from "../purchaseStyle.module.css";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function PurchaseOrderBasicInfoForm({
  initialOrderInfo,
  initialOrderItems,
  initialOrderSummary,
  initialCosts,
  initialFiles,
  onSubmit,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: detailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  // product values

  const { loading, purchaseOrderOptions, error, success } = useSelector(
    (state) => state.purchaseOrderOptions
  );

  /////////////////////////////initial states//////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [orderInfo, setOrderInfo] = useState(initialOrderInfo);
  const [orderItems, setOrderItems] = useState(initialOrderItems);

  const [addtionalCosts, setAddtionalCosts] = useState(initialCosts);
  const [orderFiles, setOrderFiles] = useState(initialFiles);
  // store the order items total cost and extra cost total. the tax amount and order amount calculate when display
  const [orderSummary, setOrderSummary] = useState(initialOrderSummary);

  //define the error message
  const [formErrors, setFormErrors] = useState({});
  /////////////////////////////////////////////////////////////////////////////////////////////
  const [newProjectModal, setNewProjectModal] = useState(false);
  const [newSupplierModal, setNewSupplierModal] = useState(false);

  useEffect(() => {
    if (Object.keys(purchaseOrderOptions).length) {
      let supplier_options_copy = [...purchaseOrderOptions.supplierOptions];
      let project_options_copy = [...purchaseOrderOptions.projectsOptions];
      setSupplierOptions(supplier_options_copy);
      setProjectOptions(project_options_copy);
    }
  }, [success]);

  /////////////////////////////Handle change functions////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrderInfo({ ...orderInfo, [name]: value });
  };

  const handleSupplierChange = (e) => {
    const { value } = e.target;

    if (value) {
      getSupplierDetails(value);
    } else {
      setOrderInfo({
        ...orderInfo,
        supplier: "",
      });
    }
  };

  const getSupplierDetails = (value) => {
    try {
      axios
        .get(SERVER_URL + `/suppliers/${value.id}`, config)
        .then(async (response) => {
          if (response.data.success) {
            let supplierDetails = response.data.data;
            setOrderInfo({
              ...orderInfo,
              supplier: value,
              supplierName: supplierDetails.name,
              supplierBillingName: supplierDetails.billingName,
              firstName: supplierDetails.firstName,
              lastName: supplierDetails.lastName,
              email: supplierDetails.email,
              phone: supplierDetails.phone,
              postalAddress: supplierDetails.postalAddress,
              billingAddress: supplierDetails.billingAddress,
            });
          } else {
            setFormErrors({ supplier: "Get supplier details failed!" });
          }
        })
        .catch((err) => {
          setFormErrors({ supplier: "Get supplier details failed!" });
        });
    } catch (error) {
      setFormErrors({ supplier: "Get supplier details failed!" });
    }
  };

  ////////////////////////////Submit and check values/////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////

  const checkValidation = () => {
    const sumitValues = {
      orderInfo: orderInfo,
      files: orderFiles,
    };

    const hasErrors = validate(sumitValues);

    if (hasErrors && Object.keys(hasErrors).length === 0) {
      onSubmit(sumitValues);
      setFormErrors({});
    } else {
      setFormErrors(hasErrors);
    }
  };

  // check input validation
  const validate = (values) => {
    const errors = {};
    if (!values.orderInfo.orderNumber) {
      errors.orderNumber = "Order number is required!";
    }
    if (!values.orderInfo.supplier) {
      errors.supplier = "Supplier is required!";
    }

    return errors;
  };

  const addNewProjectSuccess = (newPro) => {
    let optionCopy = [...projectOptions];
    optionCopy.unshift(newPro);
    setProjectOptions(optionCopy);
    setOrderInfo({ ...orderInfo, project: newPro });
    setNewProjectModal(false);
  };

  const getAttachments = (files) => {
    let new_files = [];
    files.forEach((x) => {
      new_files.push({
        fileName: x.fileName,
        uri: x.uri,
      });
    });

    setOrderFiles(new_files);
  };

  const getNewSupplierSuccess = (newSupplier) => {
    let optionCopy = [...supplierOptions];
    optionCopy.unshift(newSupplier);
    setSupplierOptions(optionCopy);
    getSupplierDetails(newSupplier);
    setNewSupplierModal(false);
  };

  return (
    <div>
      <Modal
        title="New Project"
        isModalOpen={newProjectModal}
        closeModal={() => setNewProjectModal(false)}
        content={
          <ProjectForm
            projectId=""
            type="new"
            btnName="Add New Project"
            values={{ name: "", code: "" }}
            onSuccess={addNewProjectSuccess}
          />
        }
      />
      <NewSupplierModal
        title="Create New Supplier"
        isModalOpen={newSupplierModal}
        closeModal={() => setNewSupplierModal(false)}
        onCreateSuccess={getNewSupplierSuccess}
      />
      <div className={salesStyle.sectionCard}>
        <XeroAccountMappingTip pageName="purchaseOrder" />

        <div className="w-full grid grid-cols-4 gap-8">

          {detailsSuccess && 
           <ReadOnlyField 
           label={"Issue Date*"} 
           value={formatDate( new Date(orderInfo.issueDate * 1000), userDetails.data.orgInfo.date_format, userDetails.data.orgInfo.time_zone)} />}

           
   
            <ReadOnlyField
              label={"Location*"}
              value={orderInfo.location ? orderInfo.location.value : ""}
            />
            <StyledInput
              label="Order Number"
              type="text"
              name="orderNumber"
              value={orderInfo.orderNumber}
              onChange={(orderNumber) =>
                handleChange({
                  target: { value: orderNumber, name: "orderNumber" },
                })
              }
              error={formErrors.orderNumber}
            />
  
        </div>
      </div>

      <div className={salesStyle.sectionCard}>
        <div className="w-full flex flex-row">
          <div className="w-2/5 mr-8">
            <SubTitle name="Supplier Information" />
            <div>
              <CreateableSelectField
                label="Supplier*"
                options={supplierOptions}
                name={"supplier"}
                value={orderInfo.supplier}
                onChange={(supplier) =>
                  handleSupplierChange({
                    target: { value: supplier },
                  })
                }
                placeholder="Select supplier"
                autoFocus={false}
                readOnly={false}
                clearable={true}
                noResultText="No options"
                addBtnName="Add a new supplier"
                openAddNewModal={() => setNewSupplierModal(true)}
                error={formErrors.supplier}
              />
            </div>

            {orderInfo.supplier ? (
              <div className="bg-lightbg p-4">
                <ContactCard
                  contactName={orderInfo.supplierName}
                  billingName={orderInfo.supplierBillingName}
                  firstName={orderInfo.firstName}
                  lastName={orderInfo.lastName}
                  phone={orderInfo.phone}
                  email={orderInfo.email}
                  postalAddress={orderInfo.postalAddress}
                  billingAddress={orderInfo.billingAddress}
                  contactLink={`/supplier/preview/${orderInfo.supplier.id}`}
                />
              </div>
            ) : (
              <></>
            )}
            <ReadOnlyField
              label={"Tax Rate*"}
              value={orderInfo.taxRate ? orderInfo.taxRate.value : ""}
            />
            <ReadOnlyField
              label={"Currency*"}
              value={orderInfo.currency ? orderInfo.currency : ""}
            />
            {projectOptions.length > 0 ? (
              <>
                <CreateableSelectField
                  label="Project"
                  extractKey="id"
                  options={projectOptions}
                  name={"year"}
                  value={orderInfo.project}
                  onChange={(project) =>
                    handleChange({
                      target: { value: project, name: "project" },
                    })
                  }
                  placeholder="Select project"
                  autoFocus={false}
                  readOnly={false}
                  clearable={true}
                  noResultText="No options"
                  addBtnName="Add a new project"
                  openAddNewModal={() => setNewProjectModal(true)}
                  error={formErrors.project}
                />
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="w-3/5 grid grid-cols-2 gap-8">

          <div className="w-full">
            <SubTitle name="Shipping Information" />

            <StyledInput
              label="Delivery Address"
              type="text"
              name="deliveryAddress"
              value={orderInfo.deliveryAddress}
              onChange={(deliveryAddress) =>
                handleChange({
                  target: { value: deliveryAddress, name: "deliveryAddress" },
                })
              }
              error={formErrors.deliveryAddress}
            />

            <ReadOnlyField
              label={"Expected Delivery Date"}
              value={
                Number(orderInfo.expectedDate) > 0
                  ? formatDate(
                      new Date(orderInfo.expectedDate * 1000),
                      userDetails.data.orgInfo.date_format,
                      userDetails.data.orgInfo.time_zone
                    )
                  : ""
              }
            />
            <StyledLabel label={"Bill Term"}/>
            <p>{orderInfo.billTerm} days after receive date</p>


            <StyledTextarea
              label="Notes"
              type="text"
              name="notes"
              value={orderInfo.notes}
              onChange={(notes) =>
                handleChange({
                  target: { value: notes, name: "notes" },
                })
              }
              error={formErrors.notes}
            />
          </div>
          <div className="w-full">
            <SubTitle name="Invoice" />
            <StyledInput
              label="Invoice No."
              type="text"
              name="invoiceNo"
              value={orderInfo.invoiceNumber}
              onChange={(invoiceNumber) =>
                handleChange({
                  target: {
                    value: invoiceNumber,
                    name: "invoiceNumber",
                  },
                })
              }
            />

            <DateInput
              label="Invoice Date"
              value={orderInfo.invoiceDate}
              onChange={(invoiceDate) =>
                handleChange({
                  target: {
                    value: invoiceDate,
                    name: "invoiceDate",
                  },
                })
              }
            />

            <DateInput
              label="Invoice Due Date"
              value={orderInfo.invoiceDue}
              onChange={(invoiceDue) =>
                handleChange({
                  target: {
                    value: invoiceDue,
                    name: "invoiceDue",
                  },
                })
              }
            />
          </div>
          </div>

        </div>
      </div>

      {/************ order items ***********************/}
      <div className={salesStyle.sectionCard}>
        <div className={purchaseStyle.orderItemsCon}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <SubTitle name="Order Products" />
            <p>
              {orderInfo.taxIncluded
                ? "*Amounts are tax inclusive"
                : "*Amounts are tax exclusive"}
            </p>
          </div>

          <div className={commonStyle.longDataTable}>
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Product Name</th>
                  <th>Unit</th>
                  <th>Latest Cost</th>
                  <th>Unit Cost</th>
                  <th>Discount</th>
                  <th>Net Cost</th>
                  <th>Quantity</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {orderItems.map((item, index1) => {
                  return (
                    <tr key={index1}>
                      <td>
                        {item.variantSku && Number(item.famid) > 0 && (
                          <ProductCodeLinkToNewTab productId={item.famid} name={item.variantSku} />
                        )}
                      </td>
                      <td className={purchaseStyle.productNameCol}>
                        {item.productName ? item.productName : ""}
                      </td>

                      <td>{item.unitName}</td>
                      <td>
                        <PriceText num={item.unitLatestCost} />
                        <span className={commonStyle.taxText}>
                          Excl. tax
                        </span>{" "}
                      </td>
                      <td
                        style={{
                          width: "10%",
                        }}
                      >
                        {item.unitCost}
                      </td>
                      <td
                        style={{
                          width: "10%",
                        }}
                      >
                        {item.discount}%
                      </td>
                      <td>
                        {item.discount ? (
                          <PriceText
                            num={convtPrice(
                              (1 - Number(item.discount) / 100) *
                                Number(item.unitCost)
                            )}
                          />
                        ) : (
                          <PriceText num={item.unitCost} />
                        )}
                      </td>
                      <td
                        style={{
                          width: "10%",
                        }}
                      >
                        {item.quantity}
                      </td>
                      <td
                        style={{
                          width: "10%",
                          maxWidth: "15%",
                        }}
                      >
                        <PriceText num={item.totalCost} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="7" className={commonStyle.tableFootName}>
                    Total
                  </td>

                  <td>
                    <PriceText num={orderSummary.productsTotal} />
                  </td>
                  <td></td>
                </tr>
              </tfoot>
            </table>

            <div className="w-full mt-8">
              <div className={purchaseStyle.summaryWrapper}>
                <div className={purchaseStyle.costCon}>
                  {/* additional cost */}
                  <SubTitle name="Additional Cost" />
                  <table className={commonStyle.pageTable}>
                    <thead>
                      <tr>
                        <th>Cost Name</th>
                        <th>Cost</th>
                      </tr>
                    </thead>

                    <tbody>
                      {addtionalCosts.map((c, index2) => {
                        return (
                          <tr key={index2}>
                            <td
                              className={purchaseStyle.productNameCol}
                              style={{
                                width: "60%",
                              }}
                            >
                              {c.costName}
                            </td>

                            <td
                              style={{
                                width: "36%",
                              }}
                            >
                              {c.cost}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className={commonStyle.tableFootName}>Total</td>

                        <td>
                          <PriceText num={orderSummary.additionalCostTotal} />
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  <div className="mt-4">
                    <OrderAttachments
                      onUploadSuccess={getAttachments}
                      initialFiles={orderFiles}
                    />
                  </div>
                </div>

                <div className={purchaseStyle.summaryCon}>
                  {/* summary total price */}
                  <SubTitle name="Order Summary" />
                  <table
                    className={commonStyle.summaryTable}
                    style={{ marginTop: "12px" }}
                  >
                    {orderInfo.taxIncluded ? (
                      <tbody>
                        <tr>
                          <td className={commonStyle.tableFootName}>
                            Products Total
                          </td>

                          <td style={{ width: "30%" }}>
                            <PriceText num={orderSummary.productsTotal} />
                          </td>
                        </tr>
                        <tr>
                          <td className={commonStyle.tableFootName}>
                            Additional Cost Total
                          </td>
                          <td style={{ width: "30%" }}>
                            <PriceText num={orderSummary.additionalCostTotal} />
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.summaryTableTotalName}>
                            Order Total{" "}
                            <span className={commonStyle.taxText}>
                              Tax Incl.
                            </span>
                          </td>

                          <td className={commonStyle.summaryTableTotalValue}>
                            {orderSummary.productsTotal ? (
                              <PriceText
                                num={
                                  Number(orderSummary.productsTotal) +
                                  Number(orderSummary.additionalCostTotal)
                                }
                              />
                            ) : (
                              "0.00"
                            )}{" "}
                            {orderInfo.currency}
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.tableFootName}>
                            Total{" "}
                            <span className={commonStyle.taxText}>
                              Tax Excl.
                            </span>
                          </td>

                          <td style={{ width: "30%" }}>
                            {orderInfo.taxRate ? (
                              <PriceText
                                num={convtPrice(
                                  (Number(orderSummary.productsTotal) +
                                    Number(orderSummary.additionalCostTotal)) /
                                    (1 + orderInfo.taxRate.rate / 100)
                                )}
                              />
                            ) : (
                              "0.00"
                            )}
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.tableFootName}>
                            Tax
                            <span className={commonStyle.taxText}>
                              {orderInfo.taxRate
                                ? "-" + orderInfo.taxRate.value
                                : ""}
                            </span>
                          </td>

                          <td style={{ width: "30%" }}>
                            {orderInfo.taxRate ? (
                              <PriceText
                                num={convtPrice(
                                  Number(orderSummary.productsTotal) +
                                    Number(orderSummary.additionalCostTotal) -
                                    (Number(orderSummary.productsTotal) +
                                      Number(
                                        orderSummary.additionalCostTotal
                                      )) /
                                      (1 + Number(orderInfo.taxRate.rate) / 100)
                                )}
                              />
                            ) : (
                              "0.00"
                            )}
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td className={commonStyle.tableFootName}>
                            Products Total
                          </td>

                          <td style={{ width: "30%" }}>
                            <PriceText num={orderSummary.productsTotal} />
                          </td>
                        </tr>
                        <tr>
                          <td className={commonStyle.tableFootName}>
                            Additional Cost Total
                          </td>
                          <td style={{ width: "30%" }}>
                            <PriceText num={orderSummary.additionalCostTotal} />
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.tableFootName}>
                            Total{" "}
                            <span className={commonStyle.taxText}>
                              Tax Excl.
                            </span>
                          </td>

                          <td style={{ width: "30%" }}>
                            {orderInfo.taxRate ? (
                              <PriceText
                                num={
                                  Number(orderSummary.productsTotal) +
                                  Number(orderSummary.additionalCostTotal)
                                }
                              />
                            ) : (
                              "0.00"
                            )}
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.tableFootName}>
                            Tax
                            <span className={commonStyle.taxText}>
                              {orderInfo.taxRate
                                ? "-" + orderInfo.taxRate.value
                                : ""}
                            </span>
                          </td>

                          <td style={{ width: "30%" }}>
                            {orderInfo.taxRate ? (
                              <PriceText
                                num={convtPrice(
                                  (Number(orderSummary.productsTotal) +
                                    Number(orderSummary.additionalCostTotal)) *
                                    (Number(orderInfo.taxRate.rate) / 100)
                                )}
                              />
                            ) : (
                              "0.00"
                            )}
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.summaryTableTotalName}>
                            Order Total{" "}
                            <span className={commonStyle.taxText}>
                              Tax Incl.
                            </span>
                          </td>

                          <td className={commonStyle.summaryTableTotalValue}>
                            {orderSummary.productsTotal && orderInfo.taxRate ? (
                              <PriceText
                                num={convtPrice(
                                  Number(orderSummary.productsTotal) +
                                    Number(orderSummary.additionalCostTotal) +
                                    (Number(orderSummary.productsTotal) +
                                      Number(
                                        orderSummary.additionalCostTotal
                                      )) *
                                      (Number(orderInfo.taxRate.rate) / 100)
                                )}
                              />
                            ) : (
                              "0.00"
                            )}{" "}
                            {orderInfo.currency}
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full mb-20 py-4">
        <SimpleBlueButton name="Update" action={checkValidation} />
      </div>
    </div>
  );
}
