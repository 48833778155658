import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  saleOrderOptionsRequest,
} from "../../../actions/saleOrderActions";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { ScreenTitle } from "../../../components/editComponents";
import {
  ErrorMessage,
  Loader,
  SimpleDialog,
} from "../../../components/viewComponents";
import { convtPrice, generateOrderNumber } from "../../../functions/functions";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import QuoteForm from "./QuoteForm";
import {
  newQuoteRequest,
} from "../../../api/quoteServices";
import { quoteDetailsRequest } from "../../../actions/quoteActions";

export default function NewQuoteScreen() {
  useDocumentTitle("New Quote");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const cloneOrderId = location.state;

  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const {
    loading: quoteOptionsLoading,
    options,
    error: quoteOptionsError,
    success: quoteOptionsSuccess,
  } = useSelector((state) => state.saleOrderOptions);

  const {
    loading: detailsLoading,
    quoteInfo,
    error: detailsError,
    success: detailsSuccess,
  } = useSelector((state) => state.quoteDetails);

  // clone product
  const [cloneDialog, setCloneDialog] = useState(true);

  // add new pdialog
  const [saveOrderDialog, setSaveOrderDialog] = useState(false);
  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [newOrderId, setNewOrderId] = useState();

  useEffect(() => {
    dispatch(saleOrderOptionsRequest());
    if (cloneOrderId) {
      dispatch(quoteDetailsRequest(cloneOrderId));
    }
  }, [dispatch, cloneOrderId]);

  const newOrderInfo = () => {
    let order_num = "";
    let order_pattern = options.soSettings.find(
      (x) => x.settingName === "quote_order_number_rule"
    );
    order_num = order_pattern
      ? generateOrderNumber(order_pattern.settingValue)
      : "";

    let tax_included = false;
    let tax_rule = options.soSettings.find(
      (x) => x.settingName === "sale_order_default_tax_rule"
    );
    if (tax_rule) {
      tax_included = tax_rule.settingValue === "inclusive" ? true : false;
    }

    let user_id =
      userInfo.data && userInfo.data.user_id ? userInfo.data.user_id : 0;
    let default_salesperson = "";
    if (user_id) {
      // find default salesperson value
      let find_user = options.userOptions.find((x) => x.id === user_id);
      if (find_user) {
        default_salesperson = find_user;
      }
    }

    let data = {
      issueDate: new Date(),
      expireDate: "",
      orderNumber: order_num,
      customer: "",
      taxRate: "",
      taxIncluded: tax_included,
      currency: "",
      customerName: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      postalAddress: "",
      billingAddress: "",
      location: userDetailsSuccess
        ? options.locationOptions.filter(
            (x) => x.id === userDetails.data.locationId
          )[0]
        : "",
      priceTier: "",
      project: "",
      notes: "",
      salesperson: default_salesperson,
    };
    return data;
  };

  const newOrderSummary = () => {
    return {
      productsTotal: 0,
      additionalCostTotal: 0,
    };
  };

  const cloneOrderBasicInfo = () => {
    // structure order basic info
    let find_location = options.locationOptions.find(
      (x) => x.id === quoteInfo.basicInfo.locationId
    );
    let find_customer = options.customerOptions.find(
      (x) => x.id === quoteInfo.basicInfo.customerId
    );

    let find_salesperson = options.userOptions.find(
      (x) => x.id === quoteInfo.basicInfo.salespersonId
    );

    let find_taxrate = options.taxrateOptions.find(
      (x) => x.rate === quoteInfo.basicInfo.taxrate
    );
    let find_price_tier = options.priceTierOptions.find(
      (x) => x.id === quoteInfo.basicInfo.priceTierId
    );
    let find_project = options.projectsOptions.find(
      (x) => x.id === quoteInfo.basicInfo.project_id
    );

    // genearte quote new order number
    let order_num = "";
    let order_pattern = options.soSettings.find(
      (x) => x.settingName === "quote_order_number_rule"
    );
    order_num = order_pattern
      ? generateOrderNumber(order_pattern.settingValue)
      : "";

    let basic_info = {
      issueDate: new Date(),
      expireDate: "",
      orderNumber: order_num,
      customer: find_customer ? find_customer : "",
      salesperson: find_salesperson ? find_salesperson : "",
      taxRate: find_taxrate ? find_taxrate : "",
      taxIncluded: quoteInfo.basicInfo.taxIncluded,
      currency: quoteInfo.basicInfo.currency,
      customerName: quoteInfo.basicInfo.customerName
        ? quoteInfo.basicInfo.customerName
        : "",
      firstName: quoteInfo.basicInfo.firstName
        ? quoteInfo.basicInfo.firstName
        : "",
      lastName: quoteInfo.basicInfo.lastName
        ? quoteInfo.basicInfo.lastName
        : "",
      email: quoteInfo.basicInfo.email ? quoteInfo.basicInfo.email : "",
      phone: quoteInfo.basicInfo.phone ? quoteInfo.basicInfo.phone : "",
      postalAddress: quoteInfo.basicInfo.shippingAddress
        ? quoteInfo.basicInfo.shippingAddress
        : {},
      billingAddress: quoteInfo.basicInfo.billingAddress
        ? quoteInfo.basicInfo.billingAddress
        : {},
      location: find_location ? find_location : "",
      priceTier: find_price_tier ? find_price_tier : "",
      project: find_project ? find_project : "",
      notes: "",
      status: "Draft",
    };

    return basic_info;
  };

  ////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////// submit //////////////////////////////////////////////

  const openCreateQuoteDialog = (values) => {
  
    let orderItemValues = [];

    values.orderItems.forEach((prod) => {
      let eachItem = {
        type: prod.type,
        packageId: prod.packageId,
        packageName: prod.packageName,
        productType: prod.productType,
        productName: prod.productName,
        variant: prod.variant,
        famid: prod.famid,
        variantCode: prod.variantCode,
        variantSku: prod.variantSku,
        quantity: prod.quantity,
        price: prod.unitPrice,
        discount: prod.discount,
        totalPrice: prod.totalPrice,
      };

      orderItemValues.push(eachItem);
    });

    let orderTotalExcltax;
    let orderTotalIncltax;
    let orderTax;

    if (values.orderInfo.taxIncluded) {
      orderTotalExcltax = convtPrice(
        (Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)) /
          (1 + values.orderInfo.taxRate.rate / 100)
      );

      orderTotalIncltax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)
      );

      orderTax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal) -
          (Number(values.orderSummary.productsTotal) +
            Number(values.orderSummary.additionalCostTotal)) /
            (1 + values.orderInfo.taxRate.rate / 100)
      );
    } else {
      orderTotalExcltax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)
      );
      orderTotalIncltax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal) +
          (Number(values.orderSummary.productsTotal) +
            Number(values.orderSummary.additionalCostTotal)) *
            (values.orderInfo.taxRate.rate / 100)
      );
      orderTax = convtPrice(
        (Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)) *
          (values.orderInfo.taxRate.rate / 100)
      );
    }

    if (typeof values.orderInfo.postalAddress === "object") {
      values.orderInfo.postalAddress = JSON.stringify(
        values.orderInfo.postalAddress
      );
    }
    if (typeof values.orderInfo.billingAddress === "object") {
      values.orderInfo.billingAddress = JSON.stringify(
        values.orderInfo.billingAddress
      );
    }
    let quoteOrderValues = {
      orderSource: "system",
      orderInfo: {
        issueDate: values.orderInfo.issueDate
          ? Math.floor(values.orderInfo.issueDate.getTime() / 1000)
          : "",
        expireDate: values.orderInfo.expireDate
          ? Math.floor(values.orderInfo.expireDate.getTime() / 1000)
          : "",
        orderNumber: values.orderInfo.orderNumber,
        customerId: values.orderInfo.customer
          ? values.orderInfo.customer.id
          : "",
        salespersonId: values.orderInfo.salesperson
          ? values.orderInfo.salesperson.id
          : "",
        taxrateId: values.orderInfo.taxRate.id,
        taxrate: values.orderInfo.taxRate.rate,
        taxIncluded: values.orderInfo.taxIncluded,
        currency: values.orderInfo.currency,
        locationId: values.orderInfo.location.id,
        customerName: values.orderInfo.customerName,
        firstName: values.orderInfo.firstName,
        lastName: values.orderInfo.lastName,
        email: values.orderInfo.email,
        phone: values.orderInfo.phone,
        postalAddress: values.orderInfo.postalAddress,
        billingAddress: values.orderInfo.billingAddress,
        priceTierId: values.orderInfo.priceTier
          ? values.orderInfo.priceTier.id
          : "",
        projectId: values.orderInfo.project ? values.orderInfo.project.id : "",
        notes: values.orderInfo.notes,
        orderTotalExcltax: orderTotalExcltax,
        orderTotalIncltax: orderTotalIncltax,
        orderTax: orderTax,
      },
      orderItems: orderItemValues,
      costs: values.costs,
      files: values.files,
    };
    saveQuote(quoteOrderValues);
  };

  const saveQuote = async (values) => {
    setSaveOrderDialog(true);
    try {
      setSaveStatus({ loading: true, success: false, error: "" });
      const responseData = await newQuoteRequest(values, config);

      if (responseData.data.success) {
        setNewOrderId(responseData.data.data);
        setSaveStatus({ loading: false, success: true, error: "" });
      } else if (responseData.data.error === 26) {
        throw responseData.data.message;
      } else {
        throw "Create quote failed.";
      }
    } catch (error) {
      setSaveStatus({ loading: false, success: false, error: error });
    }
  };

  const closeCreateDialogWithSuccess = (pageName) => {
    setSaveOrderDialog(false);
    if (pageName === "list") {
      navigate(`/sale/quote-list`, {
        replace: true,
        from: location,
      });
    } else {
      navigate(`/sale/quote/preview/${newOrderId}`, {
        replace: true,
        from: location,
      });
    }
  };

  return (
    <div className={commonStyle.pageContainer}>
      {cloneOrderId ? (
        <SimpleDialog
          title={"Clone Quote"}
          successMessage="Quote cloning was successful."
          isDialogOpen={cloneDialog}
          closeDialog={() => setCloneDialog(false)}
          loading={detailsLoading}
          loadingMessage="Cloning quote details..."
          success={detailsSuccess}
          error={detailsError}
          confirmAction={() => setCloneDialog(false)}
        />
      ) : (
        <></>
      )}

      <SimpleDialog
        title={"New Quote"}
        successMessage="Quote created successfully."
        isDialogOpen={saveOrderDialog}
        closeDialog={() => setSaveOrderDialog(false)}
        loading={saveStatus.loading}
        loadingMessage="Processing request..."
        success={saveStatus.success}
        error={saveStatus.error}
        confirmAction={closeCreateDialogWithSuccess}
      />

      <Breadcrumbs screenName={"New Quote"} />
      <ScreenTitle title={"New Quote"} status="New" />
      <div className={commonStyle.pageContentContainer}>
        {userDetailsSuccess ? (
          quoteOptionsLoading ? (
            <Loader mess="Requesting data, please wait a moment..." />
          ) : quoteOptionsSuccess ? (
            cloneOrderId ? (
              detailsLoading ? (
                <Loader mess="Requesting data, please wait a moment..." />
              ) : detailsSuccess ? (
                <QuoteForm
                  initialOrderInfo={cloneOrderBasicInfo}
                  initialOrderItems={quoteInfo.orderItems}
                  initialCosts={quoteInfo.orderCosts}
                  initialFiles={[]}
                  initialOrderSummary={quoteInfo.orderSummary}
                  submit={openCreateQuoteDialog}
                  type="clone"
                />
              ) : detailsError ? (
                <ErrorMessage mess={detailsError} />
              ) : (
                <></>
              )
            ) : (
              <QuoteForm
                initialOrderInfo={newOrderInfo}
                initialOrderItems={[]}
                initialOrderSummary={newOrderSummary}
                initialCosts={[]}
                initialFiles={[]}
                submit={openCreateQuoteDialog}
                type="new"
              />
            )
          ) : quoteOptionsError ? (
            <ErrorMessage mess={quoteOptionsError} />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
