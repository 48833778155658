import React, { useState } from "react";
import { useSelector } from "react-redux";
import { updateTemplateRequest } from "../../../api/pdfTemplatesServices";
import UploadFileField from "../../../components/UploadFileField";

export default function UploadTemplateForm({
  templateId,
  type,
  onSuccess,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [updateStatus, setUpdateStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const uploadTemplate = async (file) => {
    const formData = new FormData();
    formData.append("word", file);

    try {
      setUpdateStatus({ ...updateStatus, loading: true });
      const responseData = await updateTemplateRequest(
        templateId,
        type,
        formData,
        config
      );
      if (responseData.data.success) {
        onSuccess();
        setUpdateStatus({
          ...updateStatus,
          loading: false,
          success: true,
          error: "",
        });
      } else {
        if (typeof responseData.data.message === "string") {
          throw responseData.data.message;
        } else {
          throw "Failed to save template";
        }
      }
    } catch (error) {
      setUpdateStatus({ ...updateStatus, loading: false, error: error });
    }
  };

  return (
    <div className="mt-4">

      <div className="">
        <p className="mt-4">
        To create a customized template, start by downloading the default template. You can then add your logo and choose which information to display.
        </p>
        {type === "packingSlip" ? (
          <a
            href={`https://d1o1skvu5gf7wj.cloudfront.net/rui/StockUnify-packing-slip-default-template.docx`}
            download={"packing_slip_template"}
            target="_blank"
            rel="noreferrer"
            className="text-brandColor hover:underline"
          >
            <p>Packing Slip Template</p>
          </a>
        ) : type === "invoice" ? (
          <a
            href={`https://d1o1skvu5gf7wj.cloudfront.net/rui/StockUnify-invoice-default-template.docx`}
            download={"invoice_template"}
            target="_blank"
            rel="noreferrer"
             className="text-brandColor hover:underline"
          >
           <p>Invoice Template</p>
          </a>
        ) : type === "purchaseOrder" ? (
          <a
            href={`https://d1o1skvu5gf7wj.cloudfront.net/rui/StockUnify-purchase-order-default-template.docx`}
            download={"purchase_order_template"}
            target="_blank"
            rel="noreferrer"
             className="text-brandColor hover:underline"
          >
           <p>Purchase Order Template</p>
          </a>
        ) : type === "quote" ? (
          <a
            href={`https://d1o1skvu5gf7wj.cloudfront.net/rui/StockUnify-quote-default-template.docx`}
            download={"quote_template"}
            target="_blank"
            rel="noreferrer"
             className="text-brandColor hover:underline"
          >
           <p>Quote Template</p>
          </a>
        ) : (
          <></>
        )}

        <p className="mt-4">
        StockUnify offers a free template adjustment service. Simply send us your template, and we’ll customize it to align with your brand details. For assistance, please contact support@stockunify.com.

        </p>
      
      </div>

      <div className="my-4">
        <p>(Only .docx format files are supported.)</p>
      </div>

      <UploadFileField
        requiredFileType={"word"}
        onSubmit={uploadTemplate}
        showBtn={true}
        btnName={"Save Template"}
        uploadFileSuccess={false}
      />
    </div>
  );
}
