import React from "react";
import { NavLink } from "react-router-dom";
import commonStyle from "../../../style/commonStyle.module.css";

export default function SystemSettingsTab({ tabName }) {
  let className1;
  let className2;

  if (tabName === "purchase") {
    className1 = commonStyle.activeItem;
  } else {
    className1 = commonStyle.tabItem;
  }
  if (tabName === "sale") {
    className2 = commonStyle.activeItem;
  } else {
    className2 = commonStyle.tabItem;
  }



  return (
    <div className={commonStyle.tabsCon}>
      <NavLink to="/settings/system-settings/po" exact="true" className={className1}>
        Purchase
      </NavLink>
      <NavLink
        to="/settings/system-settings/so"
        exact="true"
        className={className2}
      >
        Sale
      </NavLink>
    </div>
  );
}
