import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import commonStyle from "../../../style/commonStyle.module.css";
import { ScreenTitle } from "../../../components/editComponents";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  ErrorMessage,
  InsufficientStockSaleOrderDialog,
  Loader,
  SaveSaleOrderDialog,
  SimpleDialog
} from "../../../components/viewComponents";
import { convtPrice, generateOrderNumber } from "../../../functions/functions";
import {
  saleOrderOptionsRequest,
  createNewSaleOrderRequest,
  saleOrderDetailsRequest,
} from "../../../actions/saleOrderActions";
import SaleOrderForm from "./SaleOrderForm";
import useDocumentTitle from "../../../useDocumentTitle";
import { quoteDetailsRequest } from "../../../actions/quoteActions";
import { purchaseOrderItemsForSaleRequest } from "../../../api/purchaseOrderServices";

export default function NewSaleOrderScreen() {
  useDocumentTitle("New Sale Order");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const cloneOrderId = location.state ? location.state.saleOrderId : "";;
  // from quote
  const quoteId = location.state ? location.state.quoteId : "";
  // from purchase order
  const purchaseOrderId = location.state ? location.state.purchaseOrderId : "";
  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////

  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  const {
    loading: cloneLoading,
    orderInfo,
    error: cloneError,
    success: cloneSuccess,
  } = useSelector((state) => state.saleOrderDetails);

  const { loading, options, error, success } = useSelector(
    (state) => state.saleOrderOptions
  );

  const {
    loading: quoteLoading,
    quoteInfo,
    error: quoteError,
    success: quoteSuccess,
  } = useSelector((state) => state.quoteDetails);

  const {
    loading: createLoading,
    orderId,
    error: createError,
    success: createSuccess,
  } = useSelector((state) => state.createSaleOrder);
  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  
  const [cloneDialog, setCloneDialog] = useState(true);

  // new order submit values
  const [newOrderValues, setNewOrderValues] = useState({});
  // clone product
  const [quoteDialog, setQuoteDialog] = useState(false);
  // clone products from purchase order
  const [clonePurchaseDialog, setClonePurchaseDialog] = useState(false);
  // add new pdialog
  const [saveOrderDialog, setSaveOrderDialog] = useState(false);
  const [insufficientStockDialog, setInsufficientStockDialog] = useState(false);

  const [purchaseItemsStatus, setPurchaseItemStatus] = useState({
    loading: false,
    success: false,
    error: ""
  })
  const [clonePurchaseLocationId, setClonePurchaseLocationId] = useState(0);
  const [cloneFromPurchaseOrderItems, setCloneFromPurchaseOrderItems] = useState([]);
  const [cloneFromPurchaseOrderSummary, setCloneFromPurchaseOrderSummary] = useState({
    productsTotal: 0,
    additionalCostTotal: 0,
  });

  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  useEffect(() => {
    dispatch(saleOrderOptionsRequest());
    if (cloneOrderId) {
      dispatch(saleOrderDetailsRequest(cloneOrderId, "clone", "saleOrder"));
    }
  }, [dispatch, cloneOrderId]);

  useEffect(() => {
    if (quoteId) {
      dispatch(quoteDetailsRequest(quoteId));
    }

  }, [quoteId]);

  useEffect(() => {
    const getPurchaseOrderItems = async () => {
      try {
        setPurchaseItemStatus({loading: true, success: false, error: ""});
        const responseData = await purchaseOrderItemsForSaleRequest(purchaseOrderId, config);
        if (responseData.data.success) {
          let po_items = responseData.data.data.orderItems;
          let new_so_items = [];
          let products_total = 0;
          for(const ele of po_items){
            new_so_items.push({
              batchTracked: ele.batchTracked,
              consumeBatchNumbers: [],
              consumeSerialNumbers: [],
              discount: "",
              famid: ele.familyId,
              packageId: 0,
              packageName: null,
              productName: ele.productName,
              productType: ele.productType,
              quantity: ele.quantity,
              serialized: ele.serialized,
              sku: ele.sku,
              stockOnHand: ele.stockOnHand,
              totalPrice: ele.totalPrice,
              type:"product",
              unitName: ele.unitName,
              unitPrice: ele.unitPrice,
              variantCode: ele.variantCode,
              variantSku: ele.variantSku,
            })
            products_total = products_total + Number(ele.totalPrice);
            products_total = Number(products_total);
            products_total = products_total.toFixed(2);
            products_total = Number(products_total);
          }
          setClonePurchaseLocationId(responseData.data.data.locationId);
          setCloneFromPurchaseOrderItems(new_so_items);
          setCloneFromPurchaseOrderSummary({
            productsTotal: products_total,
            additionalCostTotal: 0,
          });
          setPurchaseItemStatus({loading: false, success: true, error: ""});
        } else {
          throw "get purchase order items failed";
        }
      } catch (error) {
        setPurchaseItemStatus({loading: false, success: false, error: error});
      }
    };

    if(Number(purchaseOrderId) > 0){
      setClonePurchaseDialog(true);
      getPurchaseOrderItems();
    }

  }, [purchaseOrderId]);

  const newOrderInfo = () => {
    let order_num = "";
    let order_pattern = options.soSettings.find((x) => x.settingName === "sale_order_number_rule");
    order_num = order_pattern ? generateOrderNumber(order_pattern.settingValue) : "";

    let tax_included = false;
    let tax_rule = options.soSettings.find((x) => x.settingName === "sale_order_default_tax_rule");
    if(tax_rule){
      tax_included = tax_rule.settingValue === "inclusive" ? true : false;
    }

    let has_su_invoice = false;
    let su_inv_number = "";
    let generate_invoice = options.soSettings.find((x) => x.settingName === "generate_su_sale_invoice");
    if(generate_invoice && generate_invoice.settingValue === "yes"){
      has_su_invoice = true;
      let inv_pattern = options.soSettings.find((x) => x.settingName === "sale_invoice_number_rule");
      su_inv_number = inv_pattern ? generateOrderNumber(inv_pattern.settingValue) : "";
    }

    let user_id =  userInfo.data && userInfo.data.user_id ? userInfo.data.user_id : 0;
    let default_salesperson = "";
    if(user_id){
      // find default salesperson value
      let find_user = options.userOptions.find((x) => x.id === user_id);
      if(find_user){
        default_salesperson = find_user;
      }
    }


    let data = {
      issueDate: new Date(),
      orderNumber: order_num,
      customer: "",
      taxRate: "",
      taxIncluded: tax_included,
      currency: "",
      customerName: "",
      customerBillingName: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      postalAddress: "",
      billingAddress: "",
      location: userDetailsSuccess
        ? options.locationOptions.filter(
            (x) => x.id === userDetails.data.locationId
          )[0]
        : "",
      paymentTerm: 0,
      priceTier: "",
      project: "",
      isNeedShipping: false,
      notes: "",
      suInvoice: has_su_invoice,
      invNumber: su_inv_number,
      salesperson: default_salesperson
    };
    return data;
  };

  const newOrderSummary = () => {
    return {
      productsTotal: 0,
      additionalCostTotal: 0,
    };
  };

  const cloneOrderBasicInfo = () => {
    let order_num = "";
    let order_pattern = options.soSettings.find((x) => x.settingName === "sale_order_number_rule");
    order_num = order_pattern ? generateOrderNumber(order_pattern.settingValue) : "";

    let has_su_invoice = false;
    let su_inv_number = "";
    let generate_invoice = options.soSettings.find((x) => x.settingName === "generate_su_sale_invoice");
    if(generate_invoice && generate_invoice.settingValue === "yes"){
      has_su_invoice = true;

      let inv_pattern = options.soSettings.find((x) => x.settingName === "sale_invoice_number_rule");
      su_inv_number = inv_pattern ? generateOrderNumber(inv_pattern.settingValue) : "";
    }

    let user_id =  userInfo.data && userInfo.data.user_id ? userInfo.data.user_id : 0;
    let default_salesperson = "";
    if(user_id){
      // find default salesperson value
      let find_user = options.userOptions.find((x) => x.id === user_id);
      if(find_user){
        default_salesperson = find_user;
      }
    }


    let data = {
      issueDate: new Date(),
      orderNumber: order_num,
      customer: options.customerOptions.filter(
        (x) => x.id === orderInfo.basicInfo.customerId
      )[0],
      taxRate: options.taxrateOptions.filter(
        (x) => x.rate === orderInfo.basicInfo.taxrate
      )[0],
      taxIncluded: orderInfo.basicInfo.taxIncluded,
      currency: orderInfo.basicInfo.currency,
      customerName: orderInfo.basicInfo.customerName
        ? orderInfo.basicInfo.customerName
        : "",
      customerBillingName: orderInfo.basicInfo.customerBillingName
      ? orderInfo.basicInfo.customerBillingName
      : "",
      firstName: orderInfo.basicInfo.firstName
        ? orderInfo.basicInfo.firstName
        : "",
      lastName: orderInfo.basicInfo.lastName
        ? orderInfo.basicInfo.lastName
        : "",
      email: orderInfo.basicInfo.email ? orderInfo.basicInfo.email : "",
      phone: orderInfo.basicInfo.phone ? orderInfo.basicInfo.phone : "",
      postalAddress: orderInfo.basicInfo.shippingAddress
        ? orderInfo.basicInfo.shippingAddress
        : {},
      billingAddress: orderInfo.basicInfo.billingAddress
        ? orderInfo.basicInfo.billingAddress
        : {},
      location: options.locationOptions.filter(
        (x) => x.id === orderInfo.basicInfo.locationId
      )[0],
      paymentTerm: orderInfo.basicInfo.paymentTerm,
      priceTier: options.priceTierOptions.filter(
        (x) => x.id === orderInfo.basicInfo.priceTierId
      )[0],
      project: "",
      isNeedShipping: orderInfo.basicInfo.isNeedShipping,
      notes: orderInfo.basicInfo.notes ? orderInfo.basicInfo.notes : "",
      suInvoice: has_su_invoice,
      invNumber: su_inv_number,
      salesperson: default_salesperson
    };

    return data;
  };

  const cloneOrderItems = () => {
    let orderItemsCopy = [...orderInfo.orderItems];
    orderItemsCopy.forEach((x) => {
      x.consumeBatchNumbers = [];
      x.consumeSerialNumbers = [];
    });

    return orderItemsCopy;
  };

  const quoteOrderBasicInfo = () => {
    // structure order basic info
    let find_location = options.locationOptions.find(
      (x) => x.id === quoteInfo.basicInfo.locationId
    );
    let find_customer = options.customerOptions.find(
      (x) => x.id === quoteInfo.basicInfo.customerId
    );

    let find_salesperson = options.userOptions.find(
      (x) => x.id === quoteInfo.basicInfo.salespersonId
    );

    let find_taxrate = options.taxrateOptions.find(
      (x) => x.rate === quoteInfo.basicInfo.taxrate
    );
    let find_price_tier = options.priceTierOptions.find(
      (x) => x.id === quoteInfo.basicInfo.priceTierId
    );
    let find_project = options.projectsOptions.find(
      (x) => x.id === quoteInfo.basicInfo.project_id
    );

    let order_num = "";
    let order_pattern = options.soSettings.find((x) => x.settingName === "sale_order_number_rule");
    order_num = order_pattern ? generateOrderNumber(order_pattern.settingValue) : "";

    let has_su_invoice = false;
    let su_inv_number = "";
    let generate_invoice = options.soSettings.find((x) => x.settingName === "generate_su_sale_invoice");
    if(generate_invoice && generate_invoice.settingValue === "yes"){
      has_su_invoice = true;
      let inv_pattern = options.soSettings.find((x) => x.settingName === "sale_invoice_number_rule");
      su_inv_number = inv_pattern ? generateOrderNumber(inv_pattern.settingValue) : "";
    }

    let basic_info = {
      issueDate: new Date(),
      orderNumber: order_num,
      customer: find_customer ? find_customer : "",
      taxRate: find_taxrate ? find_taxrate : "",
      taxIncluded: quoteInfo.basicInfo.taxIncluded,
      currency: quoteInfo.basicInfo.currency,
      customerName: quoteInfo.basicInfo.customerName
        ? quoteInfo.basicInfo.customerName
        : "",
      customerBillingName: quoteInfo.basicInfo.customerBillingName
      ? quoteInfo.basicInfo.customerBillingName
      : "",
      firstName: quoteInfo.basicInfo.firstName
        ? quoteInfo.basicInfo.firstName
        : "",
      lastName: quoteInfo.basicInfo.lastName
        ? quoteInfo.basicInfo.lastName
        : "",
      email: quoteInfo.basicInfo.email ? quoteInfo.basicInfo.email : "",
      phone: quoteInfo.basicInfo.phone ? quoteInfo.basicInfo.phone : "",
      postalAddress: quoteInfo.basicInfo.shippingAddress
        ? quoteInfo.basicInfo.shippingAddress
        : {},
      billingAddress: quoteInfo.basicInfo.billingAddress
        ? quoteInfo.basicInfo.billingAddress
        : {},
      location: find_location ? find_location : "",
      paymentTerm: quoteInfo.basicInfo.paymentTerm ? quoteInfo.basicInfo.paymentTerm : 0,
      priceTier: find_price_tier ? find_price_tier : "",
      project: find_project ? find_project : "",
      isNeedShipping: false,
      notes: "",
      suInvoice: has_su_invoice,
      invNumber: su_inv_number,
      salesperson: find_salesperson ? find_salesperson : "",
    };

    return basic_info;
  };

  const quoteOrderItems = () => {
    let orderItemsCopy = [...quoteInfo.orderItems];
    orderItemsCopy.forEach((x) => {
      x.consumeBatchNumbers = [];
      x.consumeSerialNumbers = [];
    });

    return orderItemsCopy;
  };

  const quoteOrderFiles = () => {
    let copy = [...quoteInfo.orderFiles];
    let data = [];
    copy.forEach((x) => {
      data.push({
        fileName: x.fileName,
        uri: x.uri,
        loading: false,
        error: "",
      });
    });
    return data;
  };

  const clonePurchaseOrderInfo = () => {
    const base = newOrderInfo();
    const locationObj = options.locationOptions.find(x => x.id === clonePurchaseLocationId);
    return {
      ...base,
      location: locationObj || base.location,
    };
  }

  ////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////// submit //////////////////////////////////////////////

  const openCreateSaleOrderDialog = (values, status) => {

    let isPackageSale = false;
    let isApproved = false;
    let isInsufficientStock = false;

    if (values.orderItems.some((item) => item.type === "package")) {
      isPackageSale = true;
    }

    if (status === "Approved") {
      isApproved = true;
    }

    let orderItemValues = [];

    values.orderItems.forEach((prod) => {
      if (
        status === "Approved" &&
        prod.type !== "package" && prod.productType === "product" &&
        (Number(prod.quantity) > Number(prod.stockOnHand))
      ) {
        isInsufficientStock = true;
      }

      let eachItem = {
        type: prod.type,
        packageId: prod.packageId,
        packageName: prod.packageName,
        productType: prod.productType,
        productName: prod.productName,
        variant: prod.variant,
        famid: prod.famid,
        variantCode: prod.variantCode,
        variantSku: prod.variantSku,
        quantity: prod.quantity,
        price: prod.unitPrice,
        discount: prod.discount,
        totalPrice: prod.totalPrice,
        serialized: prod.serialized,
        batchTracked: prod.batchTracked,
        consumeSerialNumbers: prod.consumeSerialNumbers,
        consumeBatchNumbers: prod.consumeBatchNumbers,
      };

      orderItemValues.push(eachItem);
    });

    let orderTotalExcltax;
    let orderTotalIncltax;
    let orderTax;

    if (values.orderInfo.taxIncluded) {
      orderTotalExcltax = convtPrice(
        (Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)) /
          (1 + values.orderInfo.taxRate.rate / 100)
      );

      orderTotalIncltax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)
      );

      orderTax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal) -
          (Number(values.orderSummary.productsTotal) +
            Number(values.orderSummary.additionalCostTotal)) /
            (1 + values.orderInfo.taxRate.rate / 100)
      );
    } else {
      orderTotalExcltax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)
      );
      orderTotalIncltax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal) +
          (Number(values.orderSummary.productsTotal) +
            Number(values.orderSummary.additionalCostTotal)) *
            (values.orderInfo.taxRate.rate / 100)
      );
      orderTax = convtPrice(
        (Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)) *
          (values.orderInfo.taxRate.rate / 100)
      );
    }

    if (typeof values.orderInfo.postalAddress === "object") {
      values.orderInfo.postalAddress = JSON.stringify(
        values.orderInfo.postalAddress
      );
    }
    if (typeof values.orderInfo.billingAddress === "object") {
      values.orderInfo.billingAddress = JSON.stringify(
        values.orderInfo.billingAddress
      );
    }
    let saleOrderValues = {
      orderSource: "system",
      isPackageSale: isPackageSale,
      approved: isApproved,
      status: status,
      orderInfo: {
        issueDate: values.orderInfo.issueDate
        ? Math.floor(values.orderInfo.issueDate.getTime() / 1000)
        : "",
        orderNumber: values.orderInfo.orderNumber,
        suInvoice: values.orderInfo.suInvoice ? 1 : 0,
        invNumber: values.orderInfo.invNumber,
        customerId: values.orderInfo.customer
          ? values.orderInfo.customer.id
          : "",
        salespersonId: values.orderInfo.salesperson
        ? values.orderInfo.salesperson.id
        : "",
        taxrateId: values.orderInfo.taxRate.id,
        taxrate: values.orderInfo.taxRate.rate,
        taxIncluded: values.orderInfo.taxIncluded,
        currency: values.orderInfo.currency,
        locationId: values.orderInfo.location.id,
        customerName: values.orderInfo.customerName,
        customerBillingName: values.orderInfo.customerBillingName,
        firstName: values.orderInfo.firstName,
        lastName: values.orderInfo.lastName,
        email: values.orderInfo.email,
        phone: values.orderInfo.phone,
        postalAddress: values.orderInfo.postalAddress,
        billingAddress: values.orderInfo.billingAddress,
        paymentTerm: values.orderInfo.paymentTerm,
        priceTierId: values.orderInfo.priceTier
          ? values.orderInfo.priceTier.id
          : "",
        projectId: values.orderInfo.project
        ? values.orderInfo.project.id
        : "",
        isNeedShipping: values.orderInfo.isNeedShipping ? 1 : 0,
        notes: values.orderInfo.notes,
        // calcute order total amount
        orderTotalExcltax: orderTotalExcltax,
        orderTotalIncltax: orderTotalIncltax,
        orderTax: orderTax,
      },
      orderItems: orderItemValues,
      costs: values.costs,
      files: values.files,
    };

    setNewOrderValues(saleOrderValues);
    if (isInsufficientStock) {
      setInsufficientStockDialog(true);
    } else {
      setSaveOrderDialog(true);
    }
  };

  const addNewSaleOrder = (syncXero) => {
    let orderValuesCopy = {...newOrderValues};
    orderValuesCopy.syncXero = syncXero;

    dispatch(createNewSaleOrderRequest(orderValuesCopy));
  };

  const proceedInsufficiectStockOrder = (saveType) => {
    let orderValuesCopy = {...newOrderValues};
    orderValuesCopy.status = saveType;
    orderValuesCopy.approved = false;
    setNewOrderValues(orderValuesCopy);
    setSaveOrderDialog(true);
    setInsufficientStockDialog(false);
  };

  const closeCreateDialogWithSuccess = (pageName) => {
    setSaveOrderDialog(false)
    if (pageName === "list"){
      navigate(`/sale/sale-order-list`, {
        replace: true,
        from: location,
      });
    }else{
      navigate(`/sale/order/preview/${orderId}`, {
        replace: true,
        from: location,
      });
    }
    
  };

  return (
    <div className={commonStyle.pageContainer}>
      {cloneOrderId ? (
        <SimpleDialog
          title={"Clone Sale Order"}
          successMessage="Order cloning was successful."
          isDialogOpen={cloneDialog}
          closeDialog={() => setCloneDialog(false)}
          loading={cloneLoading}
          loadingMessage="Cloning sale order details..."
          success={cloneSuccess}
          error={cloneError}
          confirmAction={() => setCloneDialog(false)}
        />
      ) : (
        <></>
      )}
      {quoteId ? (
        <SimpleDialog
          title={"Create Sale Order From Quote"}
          successMessage="Sale order created successfully."
          isDialogOpen={quoteDialog}
          closeDialog={() => setQuoteDialog(false)}
          loading={quoteLoading}
          loadingMessage="Requesting quote details..."
          success={quoteSuccess}
          error={quoteError}
          confirmAction={() => setQuoteDialog(false)}
        />
      ) : (
        <></>
      )}

      {Number(purchaseOrderId) > 0 ? (
        <SimpleDialog
          title="Clone Products From Purchase Order"
          successMessage="Clone order products from purchase order successfully."
          isDialogOpen={clonePurchaseDialog}
          closeDialog={() => setClonePurchaseDialog(false)}
          loading={purchaseItemsStatus.loading}
          loadingMessage="Cloning order products from purchase order ..."
          success={purchaseItemsStatus.success}
          error={purchaseItemsStatus.error}
          confirmAction={() => setClonePurchaseDialog(false)}
        />
      ) : (
        <></>
      )}

      <InsufficientStockSaleOrderDialog
        title={"New Sale Order"}
        isDialogOpen={insufficientStockDialog}
        closeDialog={() => setInsufficientStockDialog(false)}
        confirmAction={proceedInsufficiectStockOrder}
      />

      <SaveSaleOrderDialog
        title={"New Sale Order"}
        successMessage="Sale order saved successfully."
        isDialogOpen={saveOrderDialog}
        closeDialog={() => setSaveOrderDialog(false)}
        closeDialogWithSuccess={closeCreateDialogWithSuccess}
        loading={createLoading}
        loadingMessage="Processing request..."
        success={createSuccess}
        error={createError}
        confirmAction={addNewSaleOrder}
        saveStatus={newOrderValues.status}
        hasOriSyncXeroStatus={false}
        oriSyncXeroStatus={false}
        orderSource={"system"}
      />

      <Breadcrumbs screenName={"New Sale Order"} />
      <ScreenTitle title={"New Sale Order"} status="New" />
      <div className={commonStyle.pageContentContainer}>
        {userDetailsSuccess ? (
          loading ? (
            <Loader mess="Requesting data, please wait a moment..." />
          ) : success ? (
            cloneOrderId ? (
              cloneLoading ? (
                <Loader mess="Cloning order details, please wait a moment..." />
              ) : cloneSuccess ? (
                <SaleOrderForm
                  initialOrderInfo={cloneOrderBasicInfo}
                  initialOrderItems={cloneOrderItems}
                  initialCosts={orderInfo.orderCosts}
                  initialFiles={[]}
                  initialOrderSummary={orderInfo.orderSummary}
                  submit={openCreateSaleOrderDialog}
                  type="clone"
                  itemEditable={true}
                />
              ) : (
                <ErrorMessage mess={cloneError} />
              )
            ) : quoteId ? (
              quoteLoading ? (
                <Loader mess="Cloning order details, please wait a moment..." />
              ) : quoteSuccess ? (
                <SaleOrderForm
                  initialOrderInfo={quoteOrderBasicInfo}
                  initialOrderItems={quoteOrderItems}
                  initialCosts={quoteInfo.orderCosts}
                  initialFiles={quoteOrderFiles}
                  initialOrderSummary={quoteInfo.orderSummary}
                  submit={openCreateSaleOrderDialog}
                  type="new"
                  itemEditable={true}
                />
              ) : quoteError ? (
                <ErrorMessage mess={quoteError} />
              ) : <></>
            ) : purchaseOrderId ? (
              purchaseItemsStatus.loading ? (
                <Loader mess="Cloning order details, please wait a moment..." />
              ) : purchaseItemsStatus.success ? (
                <SaleOrderForm
                  initialOrderInfo={clonePurchaseOrderInfo}
                  initialOrderItems={cloneFromPurchaseOrderItems}
                  initialCosts={[]}
                  initialFiles={[]}
                  initialOrderSummary={cloneFromPurchaseOrderSummary}
                  submit={openCreateSaleOrderDialog}
                  type="new"
                  itemEditable={true}
                />
              ) : purchaseItemsStatus.error ? (
                <ErrorMessage mess={purchaseItemsStatus.error} />
              ) : <></>
            ) : (
              <SaleOrderForm
                initialOrderInfo={newOrderInfo}
                initialOrderItems={[]}
                initialOrderSummary={newOrderSummary}
                initialCosts={[]}
                initialFiles={[]}
                submit={openCreateSaleOrderDialog}
                type="new" 
                itemEditable={true}
              />
            )
          ) : (
            <ErrorMessage mess={error} />
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
