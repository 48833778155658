import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, NavLink } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import { ScreenTitle } from "../../components/editComponents";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import {
  PriceText,
  ViewDateWithTime,
  ErrorMessage,
  Loader,
  ViewDateText,
  ProductNameLink,
} from "../../components/viewComponents";
import inventoryStyle from "./inventoryStyle.module.css";
import LocationTab from "./LocationTab";
import { convtPrice, convtQuantity } from "../../functions/functions";
import {
  stockLogsLocationsRequest,
  stockLogsRequest,
} from "../../api/productServices";
import { AdjustTableColumns } from "../../components/filters";
import {
  customTableHeaderSettingsRequest,
  updateCustomTableHeaderSettingsRequest,
} from "../../api/systemSettingsServices";

export default function StockLogsScreen() {
  useDocumentTitle("Stock Logs");
  const { id: productId } = useParams();
  const location = useLocation();
  const stateData = location.state;

  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const staticColumns = [
    "Time",
    "Operator",
    "Change Type",
    "Change Qty",
    "Stock After Change",
    "Unit Revenue",
    "Unit Expense",
    "Unit Cost",
    "Unit Landing Cost",
    "Reference ID",
  ];
  const dynamicColumns = [
    "Project",
    "Contact",
    "Salesperson",
    "Batch/Serial Number",
    "Expire Date",
    "Reason",
    "Notes",
  ];

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [reqHeaderStatus, setReqHeaderStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [saveHeaderStatus, setSaveHeaderStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [logsStatus, setLogsStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [locations, setLocations] = useState([]);
  const [customHeaders, setCustomHeaders] = useState([]);
  const [updateCustomHeadersCnt, setUpdateCustomHeadersCnt] = useState(0);

  const [activeLocationId, setActiveLocationId] = useState(0);

  // State to manage additional records loading
  const [allLogs, setAllLogs] = useState([]);
  const [loadMore, setLoadMore] = useState(true);

  // pagination
  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);

  const changeLocationAction = (locationId) => {
    setActiveLocationId(locationId);
    setPageNum(0);
    setAllLogs([]); // Clear existing logs when changing location
  };

  // get stock locations
  useEffect(() => {
    const getLogsLocations = async () => {
      try {
        setReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await stockLogsLocationsRequest(productId, config);
        if (responseData.data.success) {
          let locations = responseData.data.data;
          setLocations(locations);
          if (locations.length) {
            setActiveLocationId(locations[0].locationId);
          }
          setReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
        }
      } catch (error) {
        setReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    getLogsLocations();
  }, [productId]);

  useEffect(() => {
    const loadMoreLogs = async () => {
      try {
        setLogsStatus((prevState) => ({
          ...prevState,
          loading: true,
        }));

        // Fetch logs
        const response = await stockLogsRequest(
          productId,
          activeLocationId,
          pageNum,
          pageSize,
          config
        );
        if (response.data.success) {
          const newLogs = response.data.data;
          // Append new logs to existing logs
          const reversedNewLogs = newLogs.reverse();
          const updatedLogs = [...reversedNewLogs, ...allLogs];
          setAllLogs(updatedLogs);
          setLoadMore(newLogs.length === pageSize); // Determine if more logs are available
          setLogsStatus((prevState) => ({
            ...prevState,
            loading: false,
            success: true,
          }));
        }
      } catch (error) {
        setLogsStatus((prevState) => ({
          ...prevState,
          loading: false,
          error: error,
        }));
      }
    };

    if (Number(activeLocationId) > 0) {
      loadMoreLogs();
    }
  }, [activeLocationId, pageNum, productId]);

  const moreLogButtonAction = () => {
    setPageNum(pageNum + 1);
  };

  useEffect(() => {
    const getCustomHeaders = async () => {
      try {
        setReqHeaderStatus({ loading: true, success: false, error: "" });
        const responseData = await customTableHeaderSettingsRequest(
          "stock-logs",
          config
        );
        if (responseData.data.success) {
          setCustomHeaders(responseData.data.data);
          setReqHeaderStatus({ loading: false, success: true, error: "" });
        }
      } catch (error) {
        setReqHeaderStatus({ loading: false, success: false, error: error });
      }
    };
    getCustomHeaders();
  }, [updateCustomHeadersCnt]);

  const saveColumns = async (values) => {
    let save_values = {
      tableName: "stock-logs",
      headers: values,
    };
    try {
      setSaveHeaderStatus({ loading: true, success: false, error: "" });
      const responseData = await updateCustomTableHeaderSettingsRequest(
        save_values,
        config
      );
      if (responseData.data.success) {
        setUpdateCustomHeadersCnt(updateCustomHeadersCnt + 1);
        setSaveHeaderStatus({ loading: false, success: true, error: "" });
      }
    } catch (error) {
      setSaveHeaderStatus({ loading: false, success: false, error: error });
    }
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Stock Logs" />
      <ScreenTitle title="Stock Logs" />

      <div className={commonStyle.pageContentContainer}>
        {reqStatus.loading ? (
          <Loader mess="Requesting stock logs, please wait a moment..." />
        ) : reqStatus.success ? (
          <div>
            <LocationTab
              locationsData={locations}
              changeLocation={changeLocationAction}
              activeId={activeLocationId}
            />

            <div className="flex flex-row justify-between mt-4">
              {stateData ? (
                <ProductNameLink
                  productId={productId}
                  name={stateData.productName}
                  code={stateData.variantSku}
                  largeText={true}
                />
              ) : (
                <div></div>
              )}

              <AdjustTableColumns
                staticColumns={staticColumns}
                dynamicColumns={dynamicColumns}
                selectedColumns={reqHeaderStatus.success ? customHeaders : []}
                onSave={saveColumns}
              />
            </div>
            <div className={inventoryStyle.logsContentWrapper}>
              {logsStatus.loading ? (
                <Loader mess={"Requesting logs..."} />
              ) : logsStatus.success ? (
                allLogs.length ? (
                  <div className={inventoryStyle.logsContentCon}>
                      <div className={commonStyle.longDataTableContainer}>
                      <div className={commonStyle.tableWrapper}>
                    <table className={commonStyle.logsTable}>
                      <thead>
                        <tr>
                          {/* <th></th> */}
                          <th className={`min-w-[180px] ${commonStyle.stickyColumn}`}>Time</th>
                          <th className={`min-w-[100px] ${commonStyle.stickyColumn}`}>Operator</th>
                          <th className="min-w-[100px]">Change Type</th>
                          <th className="min-w-[60px]">Change Qty</th>
                          <th className="min-w-[60px]">Stock After Change</th>
                          <th className="min-w-[60px]">Unit Revenue</th>
                          <th className="min-w-[60px]">Unit Expense</th>
                          {userDetailsSuccess &&
                            userDetails.data.permissions.includes(
                              "view_product_cost"
                            ) && <th className="min-w-[60px]">Unit Cost</th>}
                          {userDetailsSuccess &&
                            userDetails.data.permissions.includes(
                              "view_product_cost"
                            ) && <th className="min-w-[60px]">Unit Landing Cost</th>}
                          <th className="min-w-[180px]">Reference ID</th>
                          {customHeaders.includes("Contact") && (
                            <th className="min-w-[180px]">Contact</th>
                          )}
                          {customHeaders.includes("Salesperson") && (
                            <th className="min-w-[100px]">Salesperson</th>
                          )}
                          {customHeaders.includes("Project") && (
                            <th className="min-w-[240px]">Project</th>
                          )}
                          {customHeaders.includes("Batch/Serial Number") && (
                            <th className="min-w-[120px]">Batch/Serial Number</th>
                          )}
                          {customHeaders.includes("Expire Date") && (
                            <th className="min-w-[100px]">Expire Date</th>
                          )}
                          {customHeaders.includes("Reason") && <th className="min-w-[100px]">Reason</th>}
                          {customHeaders.includes("Notes") && <th className="min-w-[240px]">Notes</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {loadMore && (
                          <tr>
                            <td colSpan="12" style={{ textAlign: "left" }} className={`${commonStyle.stickyColumn}`}>
                              <button
                                onClick={moreLogButtonAction}
                                className="text-brandColor hover:underline"
                              >
                                Load previous logs
                              </button>
                            </td>
                          </tr>
                        )}
                        {allLogs.map((item, index3) => (
                          <tr key={index3}>
                            <td className={`${commonStyle.stickyColumn}`}>
                              <ViewDateWithTime value={item.atime} />
                            </td>
                            <td className={`${commonStyle.stickyColumn}`}>
                              {item.creatorFirstName}{" "}
                              {item.creatorLastName ? item.creatorLastName : ""}
                            </td>
                            <td>{item.actionType}</td>
                            <td>{convtQuantity(item.stockChange)}</td>
                            <td>{convtQuantity(item.stockAfterChange)}</td>
                            <td>{convtPrice(item.unitIncome)}</td>
                            <td>
                              <PriceText num={item.unitExpense} />
                            </td>
                            {userDetailsSuccess &&
                              userDetails.data.permissions.includes(
                                "view_product_cost"
                              ) && (
                                <td>
                                  <PriceText num={item.unitCost} />
                                </td>
                              )}
                            {userDetailsSuccess &&
                              userDetails.data.permissions.includes(
                                "view_product_cost"
                              ) && (
                                <td>
                                  <PriceText num={item.unitLandingCost} />
                                </td>
                              )}
                            <td>
                              {item.actionType === "PURCHASE" ? (
                                <NavLink
                                  to={`/purchase/order/preview/${item.refSystemId}`}
                                  className="block underline hover:text-brandColor"
                                >
                                  {item.refOrderNumber}
                                </NavLink>
                              ) : (item.actionType === "SALE" || item.actionType === "VOIDSALE") ? (
                                <NavLink
                                  to={`/sale/order/preview/${item.refSystemId}`}
                                  className="block underline hover:text-brandColor"
                                >
                                  {item.refOrderNumber}
                                </NavLink>
                              ) : (item.actionType === "RECEIVESTOCK" || item.actionType === "STOCKDEDUCTION") ? (
                                <NavLink
                                  to={`/inventory/bulk-stock-adjustment/preview/${item.refSystemId}`}
                                  className="block underline hover:text-brandColor"
                                >
                                  {item.referenceId}
                                </NavLink>
                              ) : (item.actionType === "TRANSFERIN" || item.actionType === "TRANSFEROUT") ? (
                                <NavLink
                                  to={`/inventory/bulk-stock-transfer/preview/${item.refSystemId}`}
                                  className="block underline hover:text-brandColor"
                                >
                                  {item.referenceId}
                                </NavLink>
                              ) : (
                                <>{item.referenceId}</>
                              )}
                              
                            </td>
                            {customHeaders.includes("Contact") && (
                              <td>
                                {item.contactName ? item.contactName : ""}
                              </td>
                            )}
                            {customHeaders.includes("Salesperson") && (
                              <td>
                                {item.salesperson ? item.salesperson : ""}
                              </td>
                            )}
                            {customHeaders.includes("Project") && (
                              <td>{item.project ? item.project : ""}</td>
                            )}
                            {customHeaders.includes("Batch/Serial Number") && (
                              <td>
                              {item.serialBatchNumbers && item.serialBatchNumbers.length ? item.serialBatchNumbers.map((item, index) => <p key={index}>{item}</p> ) : <></>}
                            </td>

                            )}
                            {customHeaders.includes("Expire Date") && (
                            <td>
                              {item.expireDates && item.expireDates.length ? item.expireDates.map((item, index) => <div className="flex flex-col"><ViewDateText key={index} value={item} /></div> ) : <></>}
                            </td>
                            )}
                            {customHeaders.includes("Reason") && (
                              <td>{item.reason ? item.reason : ""}</td>
                            )}
                            {customHeaders.includes("Notes") && (
                              <td>{item.notes ? item.notes : ""}</td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )
              ) : logsStatus.error ? (
                <ErrorMessage mess={logsStatus.error} />
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : reqStatus.error ? (
          <ErrorMessage mess={reqStatus.error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
