import React, { useState } from "react";
import { SimpleBlueButton, SingleRatioButton } from "../../../components/editComponents";

export default function CreateSaleOrderFromQuoteForm({ quoteNumber, onSubmit }) {
  const [markAsCompleted, setMarkAsCompleted] = useState(false);
  const handleSubmit = () =>{
    onSubmit(markAsCompleted)
  }

  return (
    <div className="mt-4">
      <p>Create sale order for quote: {quoteNumber}</p>
      <SingleRatioButton
        label={"Mark quote as Completed"}
        name="markAsCompleted"
        isOn={markAsCompleted}
        onChange={(markAsCompleted) => setMarkAsCompleted(markAsCompleted)}
      />

      <div className="w-full mt-6">
        <SimpleBlueButton name={"Create Sale Order"} action={handleSubmit} />
      </div>
    </div>
  );
}
