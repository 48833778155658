import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saleOrderListRequest } from "../../../actions/saleOrderActions";
import { OrderAttachmentsPreview } from "../../../components/attachmentComponents";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  AddLinkButton,
  ScreenTitle,
  SelectedFilterButton,
} from "../../../components/editComponents";
import { Search } from "../../../components/inputFields";
import Pagination from "../../../components/Pagination";
import {
  DollarTag,
  ErrorMessage,
  FailedSyncToXero,
  Loader,
  PriceText,
  SuccessSyncToXero,
  Tag,
  ViewDateText,
} from "../../../components/viewComponents";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import {
  CloneLink,
  DeleteOrderButton,
  EditLink,
  PreviewLink,
  ShippingSaleOrderButton,
} from "./soActionButtons";
import { SaleOrderListFilter } from "../../../components/filters";
import { formatDate } from "../../../functions/functions";

export default function SaleOrderListScreen() {
  useDocumentTitle("Manage Sale Orders");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // access check for the actions
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const { loading, saleOrders, error, success } = useSelector(
    (state) => state.saleOrderList
  );

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);
  // search
  const [keyWords, setKeywords] = useState("");
  const [hasXeroInvoice, setHasXeroInvoice] = useState(false);

  const [refreshCnt, setRefreshCnt] = useState(0);
  const [filterDeleteCnt, setFilterDeleteCnt] = useState(0);
  // filter
  let initialFilterData = {
    status: [],
    location: [],
    startDate: "",
    endDate: "",
  };
  const [selectedFilterData, setSelectedFilterData] = useState(initialFilterData);

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };
  const applyFilter = (values) => {
    setSelectedFilterData(values);
    setPageNum(0);
  };

  const deleteFilterOption = (type, index) => {
    let copy = { ...selectedFilterData };
    let dataCopy = copy[type];
    dataCopy.splice(index, 1);
    setSelectedFilterData(copy);
    setFilterDeleteCnt(filterDeleteCnt + 1);
    setPageNum(0);
  };
  const deleteStartDate = () => {
    let copy = { ...selectedFilterData };
    copy.startDate = ""
    setSelectedFilterData(copy);
    setFilterDeleteCnt(filterDeleteCnt + 1);
    setPageNum(0);
  };
  const deleteEndDate = () => {
    let copy = { ...selectedFilterData };
    copy.endDate = ""
    setSelectedFilterData(copy);
    setFilterDeleteCnt(filterDeleteCnt + 1);
    setPageNum(0);
  };
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (userDetailsSuccess) {
      if (
        userDetails.data.orgInfo.xeroConfigured &&
        userDetails.data.orgInfo.xeroConnected
      ) {
        setHasXeroInvoice(true);
      }
    }
  }, [userDetailsSuccess]);

  useEffect(() => {
    let selectedFilterStatus = [];
    let selectedFilterLocation = [];
    let startTimestamp = "";
    let endTimestamp = "";

    if (selectedFilterData.startDate) {
      startTimestamp = Math.floor(
        new Date(selectedFilterData.startDate).setHours(0, 0, 0, 0) / 1000
      );
    }
    if (selectedFilterData.endDate) {
      endTimestamp = Math.floor(
        new Date(selectedFilterData.endDate).setHours(23, 59, 59, 999) / 1000
      );
    }

    if (Number(endTimestamp) > 0 && Number(startTimestamp) > 0) {
      if (Number(startTimestamp) > Number(endTimestamp)) {
        // if start date is greater than end date, remove end date
        endTimestamp = "";
      }
    }

    if (selectedFilterData.location.length > 0) {
      selectedFilterData.location.forEach((loc) => {
        selectedFilterLocation.push(loc.id);
      });
    }

    if (selectedFilterData.status.length > 0) {
      selectedFilterData.status.forEach((s) => {
        selectedFilterStatus.push(s.value);
      });
    }

    if(userDetailsSuccess){
      if(userDetails.data.permissions.includes("sale_order_list")){
        dispatch(
          saleOrderListRequest(
            keyWords,
            pageNum,
            pageSize,
            "all",
            selectedFilterStatus.join(),
            selectedFilterLocation.join(),
            startTimestamp,
            endTimestamp
          )
        );
      }else{
        dispatch(
          saleOrderListRequest(
            keyWords,
            pageNum,
            pageSize,
            "creator",
            selectedFilterStatus.join(),
            selectedFilterLocation.join(),
            startTimestamp,
            endTimestamp
          )
        );
      }
    }
  }, [dispatch, userDetailsSuccess, pageNum, keyWords, selectedFilterData, refreshCnt, filterDeleteCnt]);

  const handleSearchChange = (value) => {
    setKeywords(value);
    setPageNum(0);
  };

  ////////////////////////////////////////////////////////////////////////////////////
  const deleteSuccessAction = () => {
    setRefreshCnt(refreshCnt + 1);
  };
  ////////////////////////////////////////////////////////////////////////////////////
  const addShippingInfoSuccessAction = () => {
    setRefreshCnt(refreshCnt + 1);
  };

  const naviogateToOrderDetails = (orderId) => {
    navigate(`/sale/order/preview/${orderId}`, { replace: false });
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName={"All Sale Orders"} />
      <ScreenTitle title={"All Sale Orders"} buttonComponent={<AddLinkButton name="New Sale Order" path="/sale/order/new" />}/>
      {
        userDetailsSuccess &&  
        <div className={commonStyle.pageContentContainer}>
        <div className="w-full flex">
          <div className="flex-grow">
            <Search
              placeholdertext="Type order number / invoice number/ customer name to search..."
              initialQuery={keyWords}
              onChange={handleSearchChange}
            />
          </div>
          <div>
            <SaleOrderListFilter
              onSelect={applyFilter}
              initialFilter={selectedFilterData}
              onDelete={filterDeleteCnt}
            />
          </div>
        </div>

        <div className="w-full flex flex-row mt-4">
          {selectedFilterData.location.length > 0 ? (
            <div className="w-fit flex flex-row">
              <span>Location:</span>
              {selectedFilterData.location.map((x, index2) => (
                <SelectedFilterButton key={index2} name={x.value} action={() => deleteFilterOption("location", index2)} />
              ))}
            </div>
          ) : (
            <></>
          )}
          {selectedFilterData.status.length > 0 ? (
            <div className="w-fit flex flex-row">
              <span>Status:</span>
              {selectedFilterData.status.map((x, index3) => (
                  <SelectedFilterButton key={index3} name={x.value} action={() => deleteFilterOption("status", index3)} />
              ))}
            </div>
          ) : (
            <></>
          )}
          {selectedFilterData.startDate && (
            <div className="w-fit flex flex-row">
              <span>Start Date:</span>
              <SelectedFilterButton name={formatDate(new Date(selectedFilterData.startDate),userDetails.data.orgInfo.date_format,
              userDetails.data.orgInfo.time_zone)} action={deleteStartDate} />
            </div>
          )}
          {selectedFilterData.endDate && (
            <div className="w-fit flex flex-row">
              <span>End Date:</span>
              <SelectedFilterButton name={formatDate(new Date(selectedFilterData.endDate),userDetails.data.orgInfo.date_format,
              userDetails.data.orgInfo.time_zone)} action={deleteEndDate} />
            </div>
          )}
        </div>

        {loading ? (
          <Loader mess="Requesting sale order list, please wait a moment..." />
        ) : success ? (
          <div className={commonStyle.longDataTable}>
            {/* hold the sale order list table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  {/* <th>Order Type</th> */}
                  <th>Order Number</th>
                  <th>Issue Date</th>
                  <th>Customer</th>
                  <th>Location</th>
                  <th>Total Price</th>
                  <th>Salesperson</th>
                  <th>Order Status</th>
                  <th>Shipping Status</th>
                  <th>Attachments</th>
                  {saleOrders.hasSuInvoice && <th>SU Invoice</th>}
                  {hasXeroInvoice && <th>Sent to Xero</th>}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {saleOrders.orders.length > 0 ? (
                  saleOrders.orders.map((item, index) => (
                    <tr key={index}>
                      <td
                        onClick={() => naviogateToOrderDetails(item.id)}
                        className="hover:cursor-pointer"
                      >
                        {item.orderNumber}
                      </td>
                      <td
                        onClick={() => naviogateToOrderDetails(item.id)}
                        className="hover:cursor-pointer"
                      >
                        <ViewDateText value={item.issueDate} />
                      </td>
                      <td
                        style={{ width: "10%" }}
                        onClick={() => naviogateToOrderDetails(item.id)}
                        className="hover:cursor-pointer"
                      >
                        {item.noCustomer ? (
                          <Tag name="No Customer" color="gray" />
                        ) : (
                          item.customerName
                        )}
                      </td>
                      <td
                        style={{ width: "10%" }}
                        onClick={() => naviogateToOrderDetails(item.id)}
                        className="hover:cursor-pointer"
                      >
                        <Tag name={item.locationName} color="gray" />
                      </td>
                      <td
                        onClick={() => naviogateToOrderDetails(item.id)}
                        className="hover:cursor-pointer"
                      >
                        <DollarTag />
                        <PriceText num={item.totalPriceInclTax} />
                      </td>
                      <td
                        onClick={() => naviogateToOrderDetails(item.id)}
                        className="hover:cursor-pointer"
                      >
                        {item.salespersonName}
                      </td>
                      <td
                        onClick={() => naviogateToOrderDetails(item.id)}
                        className="hover:cursor-pointer"
                      >
                        <div className="flex flex-row items-center justify-start">
                          <div>
                            {item.status === "Approved" ? (
                              <Tag name={item.status} color="green" />
                            ) : item.status === "Draft" ? (
                              <Tag name={item.status} color="yellow" />
                            ) : item.status === "Back Order" ? (
                              <Tag name={item.status} color="yellow" />
                            ) : item.status === "Completed" ? (
                              <Tag name={item.status} color="blue" />
                            ) : item.status === "Deleted" ? (
                              <Tag name={item.status} color="gray" />
                            ) : item.status === "Void" ? (
                              <Tag name={item.status} color="red" />
                            ) : (
                              <></>
                            )}
                          </div>
                          {item.sent && item.sent === 1 ? (
                            <div>
                              {" "}
                              <Tag name={"Sent"} color="blue" />{" "}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                      <td
                        onClick={() => naviogateToOrderDetails(item.id)}
                        className="hover:cursor-pointer"
                      >
                        {item.status !== "Draft" ? (
                          item.shippingStatus === "Waiting" ? (
                            <Tag name="Waiting for shipping" color="yellow" />
                          ) : item.shippingStatus === "Not required" ? (
                            <Tag name="Not Required" color="gray" />
                          ) : item.shippingStatus === "Shipped" ? (
                            <Tag name="Shipped" color="blue" />
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                      </td>
                      <td
                        className="hover:cursor-pointer"
                      >
                        {item.files && item.files.length > 0 ? (
                          <OrderAttachmentsPreview
                            files={item.files}
                            style="list"
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                      {saleOrders.hasSuInvoice && (
                        <td
                          onClick={() => naviogateToOrderDetails(item.id)}
                          className="hover:cursor-pointer"
                        >
                          {item.suInvoiceNumber}
                        </td>
                      )}
                      {hasXeroInvoice && (
                        <td
                          onClick={() => naviogateToOrderDetails(item.id)}
                          className="hover:cursor-pointer"
                        >
                          {item.invoiceStatus === "ISSUED" ? (
                            <SuccessSyncToXero />
                          ) : item.xeroTaskError ? (
                            <FailedSyncToXero mess={item.xeroTaskError} />
                          ) : (
                            <></>
                          )}
                        </td>
                      )}
                      {/* according to different status show different actions */}
                      <td>
                        <div className={commonStyle.actionsGrop}>
                          {(item.status === "Draft" ||
                            item.status === "Back Order") && (
                            <EditLink orderId={item.id} buttonView={false} />
                          )}
                          <PreviewLink orderId={item.id} buttonView={false} />
                          {item.shippingStatus === "Waiting" &&
                            item.status === "Approved" && (
                              <ShippingSaleOrderButton
                                orderId={item.id}
                                buttonView={false}
                                shipSuccess={addShippingInfoSuccessAction}
                              />
                            )}
                          <CloneLink orderId={item.id} buttonView={false} />
                          {(item.status === "Draft" ||
                            item.status === "Back Order") && (
                            <DeleteOrderButton
                              orderId={item.id}
                              deleteSuccess={deleteSuccessAction}
                              buttonView={false}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      There are no order available, create a new order now.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {saleOrders.orders.length > 0 ? (
              <Pagination
                totalPageNum={saleOrders.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
      }

    </div>
  );
}
