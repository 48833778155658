import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import commonStyle from "../../../style/commonStyle.module.css";
import {
  ScreenTitle,
} from "../../../components/editComponents";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  ErrorMessage,
  Loader,
  ViewText,
  SubTitle,
  PriceText,
  VariantTag,
  ViewLabel,
  ProductImage
} from "../../../components/viewComponents";
import { productPackageDetailsRequest } from "../../../actions/productActions";
import useDocumentTitle from "../../../useDocumentTitle";
import { ProductTypeIcon } from "../../../components/icons";
import { convtPrice, convtQuantity } from "../../../functions/functions";

export default function PreviewProductPackageScreen() {
  useDocumentTitle("Preview Stock Adjustment");
  const dispatch = useDispatch();
  const { id: packageid } = useParams();

  const { loading, packageDetails, error, success } = useSelector(
    (state) => state.productPackageDetails
  );

  /////////////////////////////////////////////////////////////////////

  useEffect(() => {
    dispatch(productPackageDetailsRequest(packageid));
  }, [dispatch, packageid]);

  /////////////////////////////////////////////////////////////////////////////

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Preview Product Package" />
      <ScreenTitle title="Preview Product Package" />
      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting details, please wait a moment..." />
        ) : success ? (
          <div>
            {/* purchase order infomation */}
            <SubTitle name="Product Package Basic Information" />

            <div className={commonStyle.previewContentContainer}>
              <div className={commonStyle.previewWrapper}>
                <div className={commonStyle.col1}>
                  <ViewText
                    title="Name"
                    value={packageDetails.basicInfo.name}
                  />
              

                  <ViewLabel title="Price" />
                  <PriceText num={packageDetails.basicInfo.price} />

                  <ViewText
                    title="Description"
                    value={packageDetails.basicInfo.description}
                  />
                </div>
                <div className={commonStyle.col3}>
                <ProductImage alt="product preview" src={packageDetails.basicInfo.image} />
                </div>
              </div>
            </div>

            {/* invoice section */}

            <SubTitle name="Product Package Products" />

            <div className={commonStyle.longDataTable}>
              <table className={commonStyle.pageTable}>
                <thead>
                  <tr>
                    <th>Product Type</th>
                    <th>Product Name</th>
                    <th>Unit</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {packageDetails.packageProducts.map((item, index1) => {
                    return (
                      <tr key={index1}>
                         <td><ProductTypeIcon name={item.productType}/></td>
                        <td>{item.productName}</td>
                        <td>{item.unitName}</td>
                        <td>{convtQuantity(item.quantity)}</td>
                    
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
