import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { quoteListRequest } from "../../../actions/quoteActions";
import { OrderAttachmentsPreview } from "../../../components/attachmentComponents";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  AddLinkButton,
  ScreenTitle,
  SelectedFilterButton,
} from "../../../components/editComponents";
import { QuoteListFilter } from "../../../components/filters";
import { Search } from "../../../components/inputFields";
import Pagination from "../../../components/Pagination";
import {
  DollarTag,
  ErrorMessage,
  Loader,
  PriceText,
  Tag,
  ViewDateText
} from "../../../components/viewComponents";
import { formatDate } from "../../../functions/functions";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import {
  CloneLink,
  EditLink,
  PreviewLink
} from "./quoteActionButtons";

export default function QuoteListScreen() {
  useDocumentTitle("Quotes");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // access check for the actions
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const { loading, quotes, error, success } = useSelector(
    (state) => state.quoteList
  );

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);
  // search
  const [keyWords, setKeywords] = useState("");

  const [refreshCnt, setRefreshCnt] = useState(0);
  const [filterDeleteCnt, setFilterDeleteCnt] = useState(0);
  // filter
  let initialFilterData = {
    status: [],
    location: [],
    startDate: "",
    endDate: "",
  };
  const [selectedFilterData, setSelectedFilterData] = useState(initialFilterData);

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };
  const applyFilter = (values) => {
    setSelectedFilterData(values);
    setPageNum(0);
  };

  const deleteFilterOption = (type, index) => {
    let copy = { ...selectedFilterData };
    let dataCopy = copy[type];
    dataCopy.splice(index, 1);
    setSelectedFilterData(copy);
    setFilterDeleteCnt(filterDeleteCnt + 1);
    setPageNum(0);
  };
  const deleteStartDate = () => {
    let copy = { ...selectedFilterData };
    copy.startDate = ""
    setSelectedFilterData(copy);
    setFilterDeleteCnt(filterDeleteCnt + 1);
    setPageNum(0);
  };
  const deleteEndDate = () => {
    let copy = { ...selectedFilterData };
    copy.endDate = ""
    setSelectedFilterData(copy);
    setFilterDeleteCnt(filterDeleteCnt + 1);
    setPageNum(0);
  };
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    let selectedFilterStatus = [];
    let selectedFilterLocation = [];
    let startTimestamp = "";
    let endTimestamp = "";

    if (selectedFilterData.startDate) {
      startTimestamp = Math.floor(
        new Date(selectedFilterData.startDate).setHours(0, 0, 0, 0) / 1000
      );
    }
    if (selectedFilterData.endDate) {
      endTimestamp = Math.floor(
        new Date(selectedFilterData.endDate).setHours(23, 59, 59, 999) / 1000
      );
    }

    if (Number(endTimestamp) > 0 && Number(startTimestamp) > 0) {
      if (Number(startTimestamp) > Number(endTimestamp)) {
        // if start date is greater than end date, remove end date
        endTimestamp = "";
      }
    }

    if (selectedFilterData.location.length > 0) {
      selectedFilterData.location.forEach((loc) => {
        selectedFilterLocation.push(loc.id);
      });
    }

    if (selectedFilterData.status.length > 0) {
      selectedFilterData.status.forEach((s) => {
        selectedFilterStatus.push(s.value);
      });
    }

    if(userDetailsSuccess){
      dispatch(
        quoteListRequest(
          keyWords,
          pageNum,
          pageSize,
          "all",
          selectedFilterStatus.join(),
          selectedFilterLocation.join(),
          startTimestamp,
          endTimestamp
        )
      );
    }
  }, [dispatch, userDetailsSuccess, pageNum, keyWords, selectedFilterData, refreshCnt, filterDeleteCnt]);

  const handleSearchChange = (value) => {
    setKeywords(value);
    setPageNum(0);
  };

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  const naviogateToOrderDetails = (orderId) => {
    navigate(`/sale/quote/preview/${orderId}`, { replace: false });
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName={"Quotes"} />
      <ScreenTitle title={"Quotes"}  buttonComponent={<AddLinkButton name="New Quote" path="/sale/quote/new" />}/>
      {
        userDetailsSuccess &&  
        <div className={commonStyle.pageContentContainer}>
        <div className="w-full flex">
          <div className="flex-grow">
            <Search
              placeholdertext="Type quote order number / customer name to search..."
              initialQuery={keyWords}
              onChange={handleSearchChange}
            />
          </div>
          <div>
            <QuoteListFilter
              onSelect={applyFilter}
              initialFilter={selectedFilterData}
              onDelete={filterDeleteCnt}
            />
          </div>
        </div>

        <div className="w-full flex flex-row mt-4">
          {selectedFilterData.location.length > 0 ? (
            <div className="w-fit flex flex-row">
              <span>Location:</span>
              {selectedFilterData.location.map((x, index2) => (
                <SelectedFilterButton key={index2} name={x.value} action={() => deleteFilterOption("location", index2)} />
              ))}
            </div>
          ) : (
            <></>
          )}
          {selectedFilterData.status.length > 0 ? (
            <div className="w-fit flex flex-row">
              <span>Status:</span>
              {selectedFilterData.status.map((x, index3) => (
                  <SelectedFilterButton key={index3} name={x.value} action={() => deleteFilterOption("status", index3)} />
              ))}
            </div>
          ) : (
            <></>
          )}
          {selectedFilterData.startDate && (
            <div className="w-fit flex flex-row">
              <span>Start Date:</span>
              <SelectedFilterButton name={formatDate(new Date(selectedFilterData.startDate),userDetails.data.orgInfo.date_format,
              userDetails.data.orgInfo.time_zone)} action={deleteStartDate} />
            </div>
          )}
          {selectedFilterData.endDate && (
            <div className="w-fit flex flex-row">
              <span>End Date:</span>
              <SelectedFilterButton name={formatDate(new Date(selectedFilterData.endDate),userDetails.data.orgInfo.date_format,
              userDetails.data.orgInfo.time_zone)} action={deleteEndDate} />
            </div>
          )}
        </div>

        {loading ? (
          <Loader mess="Requesting sale order list, please wait a moment..." />
        ) : success ? (
          <div className={commonStyle.longDataTable}>
            {/* hold the list table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  {/* <th>Order Type</th> */}
                  <th>Order Number</th>
                  <th>Issue Date</th>
                  <th>Customer</th>
                  <th>Location</th>
                  <th>Total Price</th>
                  <th>Expire Date</th>
                  <th>Salesperson</th>
                  <th>Status</th>
                  <th>Attachments</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {quotes.quotes.length > 0 ? (
                  quotes.quotes.map((item, index) => (
                    <tr key={index}>
                      <td
                        onClick={() => naviogateToOrderDetails(item.id)}
                        className="hover:cursor-pointer"
                      >
                        {item.orderNumber}
                      </td>
                      <td
                        onClick={() => naviogateToOrderDetails(item.id)}
                        className="hover:cursor-pointer"
                      >
                        <ViewDateText value={item.issueDate} />
                      </td>
                      <td
                        style={{ width: "10%" }}
                        onClick={() => naviogateToOrderDetails(item.id)}
                        className="hover:cursor-pointer"
                      >
                        {item.customerName}
                      </td>
                      <td
                        style={{ width: "10%" }}
                        onClick={() => naviogateToOrderDetails(item.id)}
                        className="hover:cursor-pointer"
                      >
                        <Tag name={item.locationName} color="gray" />
                      </td>
                      <td
                        onClick={() => naviogateToOrderDetails(item.id)}
                        className="hover:cursor-pointer"
                      >
                        <DollarTag />
                        <PriceText num={item.totalPriceInclTax} />
                      </td>
                      <td
                        onClick={() => naviogateToOrderDetails(item.id)}
                        className="hover:cursor-pointer"
                      >
                        <ViewDateText value={item.expireDate} />
                      </td>
                      <td
                        onClick={() => naviogateToOrderDetails(item.id)}
                        className="hover:cursor-pointer"
                      >
                        {item.salespersonName}
                      </td>
                      <td
                        onClick={() => naviogateToOrderDetails(item.id)}
                        className="hover:cursor-pointer"
                      >
                        <div className="flex flex-row items-center justify-start">
                          <div>
                            { item.status === "Draft" ? (
                              <Tag name={item.status} color="yellow" />
                            ) : item.status === "Sent" ? (
                              <Tag name={item.status} color="brandBlue" />
                            ) : item.status === "Accepted" ? (
                              <Tag name={item.status} color="green" />
                            ) : item.status === "Completed" ? (
                              <Tag name={item.status} color="blue" />
                            ) : item.status === "Deleted" ? (
                              <Tag name={item.status} color="gray" />
                            ) : item.status === "Declined" ? (
                              <Tag name={item.status} color="red" />
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </td>
                      <td className="hover:cursor-pointer">
                        {item.files && item.files.length > 0 ? (
                          <OrderAttachmentsPreview
                            files={item.files}
                            style="list"
                          />
                        ) : (
                          <></>
                        )}
                      </td>
       
                      {/* according to different status show different actions */}
                      <td>
                        <div className={commonStyle.actionsGrop}>
                          {item.status === "Draft" && (
                            <EditLink quoteId={item.id} buttonView={false} />
                          )}
                          <PreviewLink quoteId={item.id} buttonView={false} />
                          <CloneLink quoteId={item.id} buttonView={false} />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      There are no quote available, create a new quote now.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {quotes.quotes.length > 0 ? (
              <Pagination
                totalPageNum={quotes.total_pages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
      }

    </div>
  );
}
