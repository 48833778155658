import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ScreenTitle } from "../../../components/editComponents";
import inventoryStyle from "../inventoryStyle.module.css";
import {
  SimpleDialog,
  Loader,
  ErrorMessage,
  MappingXeroAccountDialog,
} from "../../../components/viewComponents";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  productOptionsRequest,
  createNewProductRequest,
  productDetailsRequest,
} from "../../../actions/productActions";
import ProductDetailsForm from "./ProductDetailsForm";
import { productTypeOptions } from "../../../constantsData/productType";
import { autoGenerateVariantSku } from "../../../functions/functions";

export default function NewProductScreen() {
  useDocumentTitle("Create New Product");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  //////////////////////////////////////////////////////////////////////////////
  const cloneProductId = location.state;
  //////////////////////////////////////////////////////////////////////////////
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const { loading, options, error, success } = useSelector(
    (state) => state.productOptions
  );
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  // clone product
  const [cloneDialog, setCloneDialog] = useState(true);

  // add new product dialog
  const [dialog, setDialog] = useState(false);
  const [xeroDialog, setXeroDialog] = useState(false);

  const [productData, setProductData] = useState("");

  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    dispatch(productOptionsRequest());
    if (cloneProductId) {
      dispatch(productDetailsRequest(cloneProductId, "clone"));
    }
  }, [dispatch]);
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  const {
    loading: detailsLoading,
    productInfo,
    error: detailsError,
    success: detailsSuccess,
  } = useSelector((state) => state.productDetails);

  const {
    loading: createLoading,
    error: createError,
    success: createSuccess,
  } = useSelector((state) => state.createProduct);

  const newProductDefaultValues = () => {
    let data = {
      type: productTypeOptions.filter((x) => x.isDefault)[0],
      category: "",
      productName: "",
      unit: options.units.filter((x) => x.isDefault)[0],

      supplier: "",
      description: "",
      image: "",

      originalManaged: false,
      managed: false,
      serialized: false,
      batchTracked: false,
      
      sku: "",
      barcode: "", 
      reorderLevel: "",
      salePrice: "",
      latestCost: "",
      hasStockOnHand: false,
      hasBatches: false,
      
      reference: "",
      internalNotes: ""
    };
    return data;
  };

  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  const cloneProductValues = () => {
    let data = {
      type: productTypeOptions.filter(
        (x) => x.value === productInfo.basicInfo.type
      )[0],
      category: options.category.filter(
        (x) => Number(x.id) === Number(productInfo.basicInfo.categoryId)
      )[0],
      productName: productInfo.basicInfo.name,
      unit: options.units.filter(
        (x) => Number(x.id) === Number(productInfo.basicInfo.unitId)
      )[0],
     
      supplier: options.suppliers.filter(
        (x) => Number(x.id) === Number(productInfo.basicInfo.supplierId)
      )[0],
      description: productInfo.basicInfo.description,
      image: productInfo.basicInfo.image,

      originalManaged: false,
      managed: false,
      serialized: productInfo.basicInfo.serialized,
      batchTracked: productInfo.basicInfo.batchTracked,

      sku: "",
      barcode: "",
      reorderLevel: productInfo.basicInfo.reorderLevel,
      salePrice: productInfo.basicInfo.salePrice,
      latestCost: productInfo.basicInfo.latestCost,
      hasStockOnHand: false,
      hasBatches: false,
      reference: productInfo.basicInfo.reference,
      internalNotes: productInfo.basicInfo.internalNotes,
    };
    return data;
  };

  ///////////////////////////////////////////////////////

  const saveProductInfo = (values, isDraft) => {
    let productValues = {
      draft: isDraft,
      type: values.type.value,
      categoryId: values.category
        ? values.category.id
        : "",
      productName: values.productName,
      unitId: values.unit ? values.unit.id : "",
      supplierId: values.supplier
      ? values.supplier.id
      : "",
      description: values.description,
      image: values.image,
      managed: values.managed,
      serialized: values.serialized,
      batchTracked: values.batchTracked,
      
      sku: values.sku ? values.sku : autoGenerateVariantSku(),
      barcode: values.barcode,
      reorderLevel: values.reorderLevel,
      salePrice: values.salePrice,
      latestCost: values.latestCost,
      reference: values.reference,
      internalNotes: values.internalNotes,
    };
    setProductData(productValues);
    if (
      userDetailsSuccess &&
      userDetails.data.orgInfo.xeroConnected &&
      !userDetails.data.orgInfo.xeroConfigured
    ) {
      setXeroDialog(true);
    } else {
      setDialog(true);
      dispatch(createNewProductRequest(productValues));
    }
  };

  const closeDialogWithSuccess = () => {
    navigate("/inventory/product-list", { replace: true, from: location });
  };

  const proceedWithoutUpdateToXero = () => {
    setXeroDialog(false);
    setDialog(true);
    dispatch(createNewProductRequest(productData));
  };

  return (
    <div className={commonStyle.pageContainer}>
      <MappingXeroAccountDialog
        title="Create New Product"
        isDialogOpen={xeroDialog}
        closeDialog={() => setXeroDialog(false)}
        proceedAction={proceedWithoutUpdateToXero}
      />

      <SimpleDialog
        title="Create New Product"
        successMessage="New product added successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={createLoading}
        loadingMessage="Processing request..."
        success={createSuccess}
        error={createError}
        confirmAction={closeDialogWithSuccess}
      />
      {cloneProductId ? (
        <SimpleDialog
          title="Clone Product"
          successMessage="The product has been cloned successfully."
          isDialogOpen={cloneDialog}
          closeDialog={() => setCloneDialog(false)}
          loading={detailsLoading}
          loadingMessage="Cloning product details..."
          success={detailsSuccess}
          error={detailsError}
          confirmAction={() => setCloneDialog(false)}
        />
      ) : (
        <></>
      )}

      <Breadcrumbs screenName="New Product" />
      <ScreenTitle title="New Product" status="New" />

      {cloneProductId ? (
        <>
          {loading ? (
            <Loader mess="Requesting data, please wait a moment..." />
          ) : success ? (
            detailsLoading ? (
              <Loader mess="Cloning product details, please wait a moment..." />
            ) : detailsSuccess ? (
              <div>
                <ProductDetailsForm
                  type="new"
                  initialBasicInfo={cloneProductValues}
                  onSave={saveProductInfo}
                />
              </div>
            ) : (
              <ErrorMessage mess={detailsError} />
            )
          ) : (
            <ErrorMessage mess={error} />
          )}
        </>
      ) : (
        <>
          {loading ? (
            <Loader mess="Requesting data, please wait a moment..." />
          ) : success ? (
            <div>
              <ProductDetailsForm
                type="new"
                initialBasicInfo={newProductDefaultValues}
                onSave={saveProductInfo}
                btnName="Create New Product"
              />
            </div>
          ) : (
            <ErrorMessage mess={error} />
          )}
        </>
      )}
    </div>
  );
}
