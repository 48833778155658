import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userlogout } from "../../actions/authAction.js";
import { productOptionsRequest } from "../../actions/productActions.js";
import {
  purchaseByProductDetailsReportRequest
} from "../../api/reportingServices.js";
import Breadcrumbs from "../../components/Breadcrumbs.js";
import Pagination from "../../components/Pagination.js";
import {
  LoadingButton,
  ScreenTitle,
  SimpleBlueButton,
  SimpleOutlineButton,
} from "../../components/editComponents.js";
import {
  DateInput,
  StyledMultiSelect,
  StyledInput
} from "../../components/inputFields.js";
import {
  DollarTag,
  ErrorMessage,
  Loader,
  Tag,
  TextErrorMessage,
  VariantSkuTag,
  ViewDateText
} from "../../components/viewComponents.js";
import { formatDate } from "../../functions/functions.js";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle.js";
import { CsvWriter } from "./CsvWriter.js";

export default function PurchaseSummaryByProductScreen() {
  useDocumentTitle("Purchase Summary By Product Report");
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );
  const {
    loading: optionsLoading,
    options,
    error: optionsError,
    success: optionsSuccess,
  } = useSelector((state) => state.productOptions);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  // pagination

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);

  const [reportData, setReportData] = useState([]);
  const [hasXeroInvoice, setHasXeroInvoice] = useState(false);
  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [downloadStatus, setDownloadStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };


  const [filterLocation, setFilterLocation] = useState([]);
  const [filterCategory, setFilterCategory] = useState([]);
  const [filterBatch, setFilterBatch] = useState("");

  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterError, setFilterError] = useState("");

  useEffect(() => {
    if (!options) {
      dispatch(productOptionsRequest());
    }
    if (options && !Object.keys(options).length > 0) {
      dispatch(productOptionsRequest());
    }
  }, [dispatch, options]);

  const applyFilter = () => {
    getProducts("filter");
    setPageNum(0);
  };

  useEffect(() => {
    if (userDetailsSuccess) {
      if (
        userDetails.data.orgInfo.xeroConfigured &&
        userDetails.data.orgInfo.xeroConnected
      ) {
        setHasXeroInvoice(true);
      }
    }
  }, [userDetailsSuccess]);

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  const downloadFile = async () => {
    // get full stocktake list

    let startTimestamp = filterStartDate
      ? Math.floor(filterStartDate.setHours(0, 0, 0, 0) / 1000)
      : "";
    let endTimestamp = filterEndDate
      ? Math.floor(filterEndDate.setHours(23, 59, 59, 999) / 1000)
      : "";

    if(startTimestamp && endTimestamp) {
      if (startTimestamp > endTimestamp) {
        setFilterError("The start date is greater than end date.");
        return;
      }
    }

    let stockListdata = [];

    let selectedFilterCategory = [];
    let selectedFilterLocation = [];

    if (filterCategory.length > 0) {
      filterCategory.forEach((cate) => {
        selectedFilterCategory.push(cate.id);
      });
    }

    if (filterLocation.length > 0) {
      filterLocation.forEach((loc) => {
        selectedFilterLocation.push(loc.id);
      });
    }

    try {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: true,
      }));

      const responseData = await purchaseByProductDetailsReportRequest(
        0,
        99999999999,
        selectedFilterCategory.join(),
        selectedFilterLocation.join(),
        startTimestamp,
        endTimestamp,
        filterBatch,
        config
      );

      if (responseData.success) {
        stockListdata = responseData.data.products;

        setDownloadStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      }else if(responseData.error === -3){
        dispatch(userlogout());
      }
    } catch (error) {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }

    //get csv file
    let csvString = "";

    let csvWriter = new CsvWriter([
      "Issue Date",
      "Order Number",
      "Supplier",
      "Code",
      "Product Name",
      "Category",
      "Unit",
      "Location",
      "Ordered Qty",
      "Currency",
      "Unit Cost",
      "Discount(%)",
      "Received Qty",
      "Total Cost",
      "User",
      "Project",
      "Serial/Batch Number",
      "Expire Date",
    ]);
    for (let line of stockListdata) {
      // get expire date string
      let expire_dates = [];
      if(line.expireDates.length){
        expire_dates = line.expireDates.map(date => 
          date ? formatDate(new Date(Number(date) * 1000), 
                            userDetailsSuccess ? userDetails.data.orgInfo.date_format : "DD/MM/YYYY",
                            userDetailsSuccess ? userDetails.data.orgInfo.time_zone : "Pacific/Auckland") : ""
        )
      }
      csvWriter.addLine([
        formatDate(
          new Date(line.issueDate * 1000),
          userDetailsSuccess ? userDetails.data.orgInfo.date_format : "DD/MM/YYYY",
          userDetailsSuccess
            ? userDetails.data.orgInfo.time_zone
            : "Pacific/Auckland"
        ),
        line.orderNumber,
        line.supplierName,
        line.variantSku,
        line.productName,
        line.category ? line.category : "",
        line.unitName ? line.unitName : "",
        line.locationName,
        line.orderedQty,
        line.currency,
        line.unitCost,
        line.discount,
        line.receivedQty,
        line.totalCost,
        line.creatorFirstName + " " + line.creatorLastName,
        line.projectCode ? line.projectCode + "-" + line.projectName : "",
        line.serialBatchNumbers.length > 0 ? line.serialBatchNumbers.join(", ") : "",
        expire_dates.length ? expire_dates.join(", ") : ""
      ]);
    }
      csvString = csvWriter.content;
    
    

    // create a download link
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "sales-gross-profit-by-product-report.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  /////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getProducts("initial");
  }, [pageNum]);


  const getProducts = async (type) => {
    let startTimestamp = filterStartDate
      ? Math.floor(filterStartDate.setHours(0, 0, 0, 0) / 1000)
      : "";
    let endTimestamp = filterEndDate
      ? Math.floor(filterEndDate.setHours(23, 59, 59, 999) / 1000)
      : "";

    if(startTimestamp && endTimestamp) {
      if (startTimestamp > endTimestamp) {
        setFilterError("The start date is greater than end date.");
        return;
      }
    }

    let selectedFilterCategory = [];
    let selectedFilterLocation = [];

    if (filterCategory.length > 0) {
      filterCategory.forEach((cate) => {
        selectedFilterCategory.push(cate.id);
      });
    }

    if (filterLocation.length > 0) {
      filterLocation.forEach((loc) => {
        selectedFilterLocation.push(loc.id);
      });
    }

    try {
      setRequestStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await purchaseByProductDetailsReportRequest(
        type === "filter" ? 0 : pageNum,
        pageSize,
        selectedFilterCategory.join(),
        selectedFilterLocation.join(),
        startTimestamp,
        endTimestamp,
        filterBatch,
        config
      );

      if (responseData.success) {
        setReportData(responseData.data);
        setFilterError("");
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      }else if(responseData.error === -3){
        dispatch(userlogout());
      }
    } catch (error) {
      setRequestStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const DownloadBtn = () => {
    return (
      <div style={{ width: "auto" }}>
        {downloadStatus.loading ? (
          <LoadingButton name="Downloading.." />
        ) : (
          <SimpleBlueButton name="Download" action={downloadFile} />
        )}
      </div>
    );
  };
  const naviogateToOrderDetails = (orderId) => {
    navigate(`/purchase/order/preview/${orderId}`, { replace: false });
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Purchase Summary By Product Report" />

      <ScreenTitle
        title="Purchase Summary By Product Report"
        buttonComponent={<DownloadBtn />}
      />
       <div className={commonStyle.pageContentContainer}>
        <div className="relative z-50"  style={{ width: "100%", display: "flex", alignItems: "flex-end" }}>
          <div
            style={{
              width: "80%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="w-[45%]">
              <DateInput
                label="Start Date"
                value={filterStartDate}
                disableFuturedate
                onChange={(startDate) => setFilterStartDate(startDate)}
              />
              <StyledMultiSelect
                label="Location"
                selectOptions={optionsSuccess ? options.location : []}
                value={filterLocation}
                onChange={(location) => setFilterLocation(location)}
              />
              <StyledInput
                  label="Serial/Batch Number"
                  type="text"
                  name="serial-batch-number"
                  value={filterBatch}
                  onChange={(batchCode) => setFilterBatch(batchCode)}
                  error={""}
              />
            </div>
            <div style={{ width: "45%" }}>
              <DateInput
                label="End Date"
                value={filterEndDate}
                disableFuturedate
                onChange={(endDate) => setFilterEndDate(endDate)}
              />
              <StyledMultiSelect
                label="Category"
                selectOptions={optionsSuccess ? options.category : []}
                value={filterCategory}
                onChange={(category) => setFilterCategory(category)}
              />
            </div>
          </div>
          <div
            style={{
              width: "20%",
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "-20px",
            }}
          >
            <SimpleOutlineButton name="Apply Filter" action={applyFilter} />
          </div>
        </div>

        {requestStatus.loading ? (
          <Loader mess="Requesting report, please wait a moment..." />
        ) : requestStatus.success && userDetailsSuccess ? (
          <div className={commonStyle.longDataTable}>
            {filterError ? (
              <div style={{ marginTop: "20px" }}>
                <TextErrorMessage mess={filterError} />
              </div>
            ) : (
              <></>
            )}
            {/* hold the product list table */}
            <div className={commonStyle.longDataTableContainer}>
            <div className={commonStyle.tableWrapper}>
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th className={`min-w-[100px] ${commonStyle.stickyColumn}`}>Issue Date</th>
                  <th className={`min-w-[180px] ${commonStyle.stickyColumn}`}>Order Number</th>
                  <th className="min-w-[240px]">Supplier</th>
                  <th className="min-w-[160px]">Code</th>
                  <th className="min-w-[280px]">Product Name</th>
                  <th className="min-w-[120px]">Category</th>
                  <th className="min-w-[60px]">Unit</th>
                  <th className="min-w-[160px]">Location</th>
                  <th className="min-w-[80px]">Ordered Qty</th>
                  <th className="min-w-[80px]">Currency</th>
                  <th className="min-w-[80px]">Unit Cost</th>
                  <th className="min-w-[80px]">Discount(%)</th>
                  <th className="min-w-[80px]">Received Qty</th>
                  <th className="min-w-[80px]">Total Cost</th>
                  <th className="min-w-[100px]">User</th>
                  <th className="min-w-[240px]">Project</th>
                  <th className="min-w-[160px]">Serial/Batch Number</th>
                  <th className="min-w-[100px]">Expire Date</th>
                </tr>
              </thead>
              <tbody>
                {reportData.products.length > 0 ? (
                  reportData.products.map((item, index) => (
                    <React.Fragment key={index}>
                      <tr>
                      <td className={`${commonStyle.stickyColumn}`}>
                          <ViewDateText value={item.issueDate} />
                        </td>
                        <td className={`hover:cursor-pointer hover:underline hover:font-medium" ${commonStyle.stickyColumn}`}  onClick={() => naviogateToOrderDetails(item.purchaseOrderId)}>
                          {item.orderNumber}
                        </td>
                        <td>{item.supplierName}</td>
                        <td><VariantSkuTag name={item.variantSku} /></td>
                        <td>{item.productName}</td>
                        <td>{item.category ? item.category : ""}</td>
                        <td>{item.unitName ? item.unitName : ""}</td>
                        <td><Tag name={item.locationName} color="gray" /></td>
                        <td>
                          {item.orderedQty}
                        </td>
                        <td>
                          {item.currency}
                        </td>
                        <td>
                        <DollarTag />{item.unitCost}
                        </td>
                        <td>
                          {Number(item.discount) > 0 ? item.discount : ""}
                        </td>
                        <td>
                          {item.receivedQty}
                        </td>
                        <td>
                        <DollarTag />{item.totalCost}
                        </td>
                        <td>
                         {item.creatorFirstName} {item.creatorLastName ? item.creatorLastName : ""}
                        </td>
                        <td>{item.projectCode ? item.projectCode + "-" + item.projectName : ""}</td>
                        <td>
                          {item.serialBatchNumbers.length ? item.serialBatchNumbers.map((item, index) => <p key={index}>{item}</p> ) : <></>}
                        </td>
                        <td>
                          {item.expireDates.length ? item.expireDates.map((item, index) => <div className="flex flex-col"><ViewDateText key={index} value={item} /></div> ) : <></>}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan={18}>
                      <p>There are no data in selected period.</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            </div>
            </div>
            {reportData.products.length > 0 ? (
              <Pagination
                totalPageNum={reportData.total_pages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : requestStatus.error ? (
          <ErrorMessage mess="Requesting report data failed, please try again later." />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
