import { QUOTE_DETAILS_FAIL, QUOTE_DETAILS_REQUEST, QUOTE_DETAILS_SUCCESS, QUOTE_LIST_FAIL, QUOTE_LIST_REQUEST, QUOTE_LIST_SUCCESS } from "../constants/quoteConstants";



export const quoteListReducer = (state = { quotes: [] }, action) => {
  switch (action.type) {
    case QUOTE_LIST_REQUEST:
      return { loading: true, success: false, error: "" };
    case QUOTE_LIST_SUCCESS:
      return { loading: false, quotes: action.payload, success: true, error: "" };
    case QUOTE_LIST_FAIL:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};

export const quoteDetailsReducer = (state = { quoteInfo: {} }, action) => {
  switch (action.type) {
    case QUOTE_DETAILS_REQUEST:
      return { loading: true, success: false, error: "" };
    case QUOTE_DETAILS_SUCCESS:
      return { loading: false, quoteInfo: action.payload, success: true, error: "" };
    case QUOTE_DETAILS_FAIL:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};