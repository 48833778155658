import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import ProductPicker from "../../../components/ProductPicker";
import {
  SimpleBlueButton,
  SingleRatioButton,
  XDeleteButton,
} from "../../../components/editComponents";
import { ProductTypeIcon } from "../../../components/icons";
import {
  NoBorderInput,
  StyledInput,
  StyledTextarea,
} from "../../../components/inputFields";
import {
  SubTitle,
  TextErrorMessage,
  VariantTag
} from "../../../components/viewComponents";
import { validatePrice, validateQuantity } from "../../../functions/validate";
import commonStyle from "../../../style/commonStyle.module.css";
import inventoryStyle from "../inventoryStyle.module.css";
import ProductImageUploader from "../product/ProductImageUploader";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function ProductPackageForm({
  initialBasicInfo,
  initialProducts,
  onSave,
  btnName,
  type,
}) {
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  const { userInfo } = useSelector((state) => state.userLogin);

  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  const [basicInfo, setBasicInfo] = useState(initialBasicInfo);
  const [packageProducts, setPackageProducts] = useState(initialProducts);

  const [formErrors, setFormErrors] = useState({});
  const [uploadLoading, setUploadLoading] = useState(false);

  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////
  const handleChange = (e) => {
    const { type, name, value } = e.target;
    if (type === "price") {
      const validated = validatePrice(value);
      if (!validated) return;
      if (validated) {
        setBasicInfo({ ...basicInfo, [name]: value });
      }
    } else {
      setBasicInfo({ ...basicInfo, [name]: value });
    }
  };

  ///////////////////////////////////////////////////////
  // handle change when upload image
  const handleComponentsChange = (e) => {
    const { type, key, value, index } = e.target;

    if (type === "quantity") {
      const validated = validateQuantity(value);
      if (!validated) return;
    }

    let newState = [...packageProducts];

    newState[index][key] = value;
    setPackageProducts(newState);
  };

  ///////////////////////////////////////////////////////
  // handle change when upload image
  const handleImageChange = (file) => {
    if (file) {
      const formData = new FormData();
      formData.append("image", file);

      // if is edit product, append product id in the file
      formData.append("productId", "0");

      try {
        setUploadLoading(true);
        axios
          .post(SERVER_URL + `/product/image/update`, formData, config)
          .then(async (response) => {
            setUploadLoading(false);
            if (response.data.success) {
              setBasicInfo({ ...basicInfo, image: response.data.data.url });
            } else {
              setFormErrors({
                image: "Update product image failed, please try again later",
              });
            }
          })
          .catch((err) => {
            setUploadLoading(false);
            setFormErrors({
              image: "Update product image failed, please try again later",
            });
          });
      } catch (error) {
        setUploadLoading(false);
        setFormErrors({ image: "Connection error, please try again later" });
      }
    }
  };

  const deleteProductImage = () => {
    setBasicInfo({ ...basicInfo, image: "" });
  };
  /////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////
  const getProductFromPicker = (result) => {
    if (result.productType !== "package") {
      addNewItem(result.data);
    }
  };

  const addNewItem = (proDetails) => {
    let packageProductsCopy = [...packageProducts];
    // remove empty row in order items
    packageProductsCopy = packageProductsCopy.filter((x) => x.famid);
    // check exist products, error
    let exists = packageProductsCopy.some(
      (item) =>
        item.famid === proDetails.familyId &&
        item.variantCode === proDetails.variantCode
    );
    if (exists) {
      let index = packageProductsCopy.findIndex(
        (item) =>
          item.famid === proDetails.familyId &&
          item.variantCode === proDetails.variantCode
      );
      packageProductsCopy[index].quantity =
        Number(packageProductsCopy[index].quantity) + 1;
    } else {
      packageProductsCopy.push({
        productType: proDetails.productType,
        famid: proDetails.familyId,
        productName: proDetails.name,
        variant: proDetails.variant ? proDetails.variant : "",
        variantCode: proDetails.variantCode,
        unitName: proDetails.unit,
        quantity: "",
        editable: false,
      });
    }

    setPackageProducts(packageProductsCopy);
  };
  const deleteProductItem = (index) => {
    let productsCopy = [...packageProducts];
    productsCopy.splice(index, 1);

    setPackageProducts(productsCopy);
  };

  /////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////
  const checkValidation = () => {
    let packageProductsCopy = [...packageProducts];
    packageProductsCopy = packageProductsCopy.filter((x) => x.famid);
    setPackageProducts(packageProductsCopy);

    let saveValues = {
      basicInfo: basicInfo,
      packageProducts: packageProducts,
    };
    const hasErrors = validate(saveValues);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      onSave(saveValues);
    } else {
      setFormErrors(hasErrors);
    }
  };

  // check input validation
  const validate = (values) => {
    const errors = {};

    if (!values.basicInfo.packageName) {
      errors.packageName = "Package name is required!";
    }

    if (!values.basicInfo.price) {
      errors.price = "Package price is required!";
    }

    if (values.packageProducts.length < 1) {
      errors.products = "Package add at least one product to this package.";
    } else {
      //values.packageProducts.some

      let hasZeroQuantity = values.packageProducts.some(
        (item) => Number(item.quantity) === 0
      );
      if (hasZeroQuantity) {
        errors.products = "Product quantity must be greater than 0.";
      }
    }

    return errors;
  };

  return (
    <div>
      <div>
        {/* basic info con */}
        <div className={inventoryStyle.basicInfo}>
          <SubTitle name="Product Package Basic Info" />
          <div className={inventoryStyle.basicInfoCol}>
            <div className={inventoryStyle.col1}>
              <StyledInput
                label="Package Code*"
                type="text"
                name="sku"
                placeHolder={"-Auto-"}
                value={basicInfo.sku}
                onChange={(sku) =>
                  handleChange({
                    target: { type: "text", value: sku, name: "sku" },
                  })
                }
                error={formErrors.sku}
              />
              <StyledInput
                label="Product Package Name*"
                type="text"
                name="packageName"
                autofocus="autofocus"
                value={basicInfo.packageName}
                onChange={(packageName) =>
                  handleChange({
                    target: {
                      type: "text",
                      value: packageName,
                      name: "packageName",
                    },
                  })
                }
                error={formErrors.packageName}
              />
              <StyledInput
                label="Price*"
                type="text"
                name="price"
                value={basicInfo.price}
                onChange={(price) =>
                  handleChange({
                    target: { type: "price", value: price, name: "price" },
                  })
                }
                error={formErrors.price}
              />

              <StyledTextarea
                label="Description"
                type="text"
                name="description"
                value={basicInfo.description}
                onChange={(description) =>
                  handleChange({
                    target: {
                      type: "text",
                      value: description,
                      name: "description",
                    },
                  })
                }
                error={formErrors.description}
              />
            </div>

            <div className={inventoryStyle.col3}>
              <div className={inventoryStyle.imageWrapper}>
                <ProductImageUploader
                  imageUri={basicInfo.image}
                  uploadLoading={uploadLoading}
                  uploadError={formErrors.image}
                  onChange={handleImageChange}
                  onDelete={deleteProductImage}
                />
              </div>
            </div>
          </div>
        </div>
        {/* basic info con end*/}

        {/************ Attrs ***********************/}

        <div className={inventoryStyle.basicInfo}>
          <SubTitle name="Package Products" />

          <div
            style={{
              margin: "20px 0",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "50%" }}>
              <ProductPicker
                locationId=""
                priceTierId=""
                onSelect={getProductFromPicker}
                requiredType="productAndService"
                locationRequired={false}
                showCreateBtn={true}
              />
            </div>
          </div>

          <>
            {/************ Variants table ***********************/}
            <div>
              {formErrors.products ? (
                <TextErrorMessage mess={formErrors.products} />
              ) : (
                <></>
              )}
              <table className={inventoryStyle.dynTable}>
                <thead>
                  <tr>
                    <th>Product Type</th>
                    <th>Product Name</th>
                    <th>Unit</th>
                    <th>Quantity</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {packageProducts.length > 0 ? (
                    packageProducts.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {" "}
                          <ProductTypeIcon name={item.productType} />
                        </td>
                        <td>
                          {item.productName}{" "}
                          <>
                            {item.variant ? (
                              <VariantTag name={item.variant} />
                            ) : (
                              <></>
                            )}{" "}
                          </>
                        </td>
                        <td>{item.unitName}</td>

                        <td className={inventoryStyle.priceField}>
                          <NoBorderInput
                            type="text"
                            name="quantity"
                            value={item.quantity}
                            onChange={(quantity) =>
                              handleComponentsChange({
                                target: {
                                  type: "quantity",
                                  value: quantity,
                                  key: "quantity",
                                  index: index,
                                },
                              })
                            }
                          />
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid var(--border-color)",
                          }}
                        >
                          <XDeleteButton
                            action={() => deleteProductItem(index)}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>

            {/************ Variants table end ***********************/}
          </>
        </div>
      </div>

      <div className={commonStyle.btnWrapper}>
        {/************  ***********************/}

        <div className="w-full mt-4">
          <SimpleBlueButton name={btnName} action={() => checkValidation()} />
        </div>
      </div>
    </div>
  );
}
