import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { stockTransferDetailsRequest } from "../../../actions/productActions";
import {
  stockAdjustOptionsRequest,
  updateStockTransferRequest,
} from "../../../api/productServices";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  ScreenTitle,
  SimpleBlueButton,
} from "../../../components/editComponents";
import {
  CreateableSelectField,
  ReadOnlyField,
  StyledInput,
} from "../../../components/inputFields";
import {
  ErrorMessage,
  Loader,
  Modal,
  PriceText,
  SimpleDialog,
  SubTitle,
  ProductCodeLinkToNewTab,
} from "../../../components/viewComponents";
import { convtPrice, convtQuantity, formatDate } from "../../../functions/functions";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import ProjectForm from "../../settings/ProjectForm";

export default function EditStockTransferScreen() {
  useDocumentTitle("Edit Stock Transfer");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id: transferid } = useParams();

  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: detailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const { loading, transferDetails, error, success } = useSelector(
    (state) => state.stockTransferDetails
  );

  const [options, setOptions] = useState();
  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  // const [adjustReason, setAdjustReason] = useState();
  const [adjustNotes, setAdjustNotes] = useState("");
  const [adjustProject, setAdjustProject] = useState();
  const [refNumber, setRefNumber] = useState("");

  const [newProjectModal, setNewProjectModal] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  // mange dialog content, new and edit
  const [dialog, setDialog] = useState(false);

  /////////////////////////////////////////////////////////////////////

  useEffect(() => {
    dispatch(stockTransferDetailsRequest(transferid));
  }, [dispatch, transferid]);

  useEffect(() => {
    const getAdjustOptions = async () => {
      try {
        setRequestStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await stockAdjustOptionsRequest(config);
        if (responseData.data.success) {
          setOptions(responseData.data.data);
          let project_list = responseData.data.data.projects;
          // let reason_list = responseData.data.data.reasons;
          if (success) {
            let find_project = project_list.find(
              (x) => x.id === transferDetails.basicInfo.projectId
            );
            if (find_project) {
              setAdjustProject(find_project);
            }

            setAdjustNotes(transferDetails.basicInfo.notes);
            setRefNumber(transferDetails.basicInfo.reference);
          }
          setRequestStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
            error: "",
          }));
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    getAdjustOptions();
  }, [success]);

  const addNewProjectSuccess = (newPro) => {
    let optionCopy = { ...options };
    let projectCopy = optionCopy.projects;
    projectCopy.unshift(newPro);
    setOptions(optionCopy);
    setAdjustProject(newPro);
    setNewProjectModal(false);
  };

  const updateStockTransfer = async () => {
    setDialog(true);

    let updateValues = {
      projectId: adjustProject ? adjustProject.id : "",
      notes: adjustNotes,
      refNumber: refNumber,
    };

    try {
      setSaveStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await updateStockTransferRequest(
        transferid,
        updateValues,
        config
      );
      if (responseData.data.success) {
        setSaveStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setSaveStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const closeDialogWithSuccess = () => {
    navigate(`/inventory/bulk-stock-transfer/preview/${transferid}`, {
      replace: true,
      from: location,
    });
    setDialog(false);
  };

  /////////////////////////////////////////////////////////////////////////////

  return (
    <div className={commonStyle.pageContainer}>
      <Modal
        title="New Project"
        isModalOpen={newProjectModal}
        closeModal={() => setNewProjectModal(false)}
        content={
          <ProjectForm
            projectId=""
            type="new"
            btnName="Add New Project"
            values={{ name: "", code: "" }}
            onSuccess={addNewProjectSuccess}
          />
        }
      />

      <SimpleDialog
        title="Edit Stock Transfer"
        successMessage="Stock transfer order updated successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={saveStatus.loading}
        loadingMessage="Processing request..."
        success={saveStatus.success}
        error={saveStatus.error}
        confirmAction={closeDialogWithSuccess}
      />

      <Breadcrumbs screenName="Edit Stock Transfer" />
      <ScreenTitle title="Edit Stock Transfer" />
      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting details, please wait a moment..." />
        ) : success ? (
          <div>
            {/* adjust order infomation */}
            {requestStatus.loading ? (
              <Loader mess="Requesting locations..." />
            ) : requestStatus.success ? (
              <div className="w-full shadow-cardShadow p-4">
                <SubTitle name="Order Basic Information" />
                <div className="grid grid-cols-3 gap-6">
                  <ReadOnlyField
                    label={"Transfer Out Location*"}
                    value={transferDetails.basicInfo.outLocation}
                  />
                  <ReadOnlyField
                    label={"Transfer In Location*"}
                    value={transferDetails.basicInfo.inLocation}
                  />
                   {detailsSuccess && 
                    <ReadOnlyField
                      label={"Issue Date*"}
                      value={formatDate( new Date(transferDetails.basicInfo.issueTime * 1000), userDetails.data.orgInfo.date_format, userDetails.data.orgInfo.time_zone)} 
                    />
                  }
                 
                </div>
                <div className="w-full grid grid-cols-3 gap-6">
                  {options.projects && options.projects.length > 0 ? (
                    <CreateableSelectField
                      label="Project"
                      extractKey="id"
                      options={options.projects}
                      name={"year"}
                      value={adjustProject}
                      onChange={(project) => setAdjustProject(project)}
                      placeholder="Select project"
                      autoFocus={false}
                      readOnly={false}
                      clearable={true}
                      noResultText="No options"
                      addBtnName="Add a new project"
                      openAddNewModal={() => setNewProjectModal(true)}
                      error={formErrors.project}
                    />
                  ) : (
                    <></>
                  )}
                 <StyledInput
                  label="Reference"
                  type="text"
                  name="reference"
                  value={refNumber}
                  onChange={(ref) => setRefNumber(ref)}
                 />
                </div>
                <div className="w-full">
                  <StyledInput
                    label="Notes"
                    type="text"
                    name="notes"
                    value={adjustNotes}
                    onChange={(notes) => setAdjustNotes(notes)}
                  />
                </div>
                <div className="w-full mt-4">
                  <SimpleBlueButton
                    action={updateStockTransfer}
                    name="Update"
                  />
                </div>
              </div>
            ) : requestStatus.error ? (
              <ErrorMessage mess={requestStatus.error} />
            ) : (
              <></>
            )}

            {/* invoice section */}
            <div className="mt-8 w-full shadow-cardShadow p-4">
              <SubTitle name="Transfer Products" />
              <div className={commonStyle.longDataTable}>
                <table className={commonStyle.pageTable}>
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Product Name</th>
                      <th>Unit</th>
                      <th>Transfer Cost</th>
                      <th>Quantity</th>
                      <th>Transfer Total Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transferDetails.orderItems.map((item, index1) => {
                      return (
                        <tr key={index1}>
                          <td>
                            {item.variantSku && Number(item.famid) > 0 && (
                              <ProductCodeLinkToNewTab productId={item.famid} name={item.variantSku} />
                            )}
                          </td>
                          <td>{item.productName}</td>
                          <td>{item.unitName}</td>

                          <td>
                            <PriceText num={convtPrice(item.unitCost)} />
                          </td>
                          <td>{convtQuantity(item.quantity)}</td>

                          <td>
                            <PriceText num={convtPrice(item.totalCost)} />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
