import React, { useEffect, useState } from "react";
import { BsX } from "react-icons/bs";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  productOptionsRequest
} from "../../../actions/productActions";
import { createNewProductApiRequest } from "../../../api/productServices";
import {
  ErrorMessage,
  Loader,
  SimpleDialog
} from "../../../components/viewComponents";
import { productTypeOptions } from "../../../constantsData/productType";
import { autoGenerateVariantSku } from "../../../functions/functions";
import viewStyle from "../../../style/styledViewComponentsStyle.module.css";
import inventoryStyle from "../inventoryStyle.module.css";
import ProductDetailsForm from "./ProductDetailsForm";

export default function NewProductModal({
  title,
  isModalOpen,
  closeModal,
  onCreateSuccess,
}) {
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
      "Content-type": "application/json",
    },
  };
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  const { loading, options, error, success } = useSelector(
    (state) => state.productOptions
  );
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  // add new product dialog
  const [dialog, setDialog] = useState(false);
  const [newProductStatus, setNewProductStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [newProId, setNewProId] = useState("");

  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    dispatch(productOptionsRequest());
  }, [dispatch]);
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  const newProductDefaultValues = () => {
    let data = {
      type: productTypeOptions.filter((x) => x.isDefault)[0],
      category: "",
      productName: "",
      unit: options.units.filter((x) => x.isDefault)[0],

      supplier: "",
      description: "",
      image: "",

      originalManaged: false,
      managed: false,
      serialized: false,
      batchTracked: false,

      sku: "",
      barcode: "",
      reorderLevel: "",
      salePrice: "",
      latestCost: "",
      hasStockOnHand: false,
      hasBatches: false,

      reference: "",
      internalNotes: ""
    };
    return data;
  };

  ///////////////////////////////////////////////////////

  const saveProductInfo = async (values, isDraft) => {
    setDialog(true);

    let productValues = {
      draft: isDraft,
      type: values.type.value,
      categoryId: values.category ? values.category.id : "",
      productName: values.productName,
      unitId: values.unit ? values.unit.id : "",
      supplierId: values.supplier ? values.supplier.id : "",
      description: values.description,
      image: values.image,
      managed: values.managed,
      serialized: values.serialized,
      batchTracked: values.batchTracked,

      sku: values.sku ? values.sku : autoGenerateVariantSku(),
      barcode: values.barcode,
      reorderLevel: values.reorderLevel,
      salePrice: values.salePrice,
      latestCost: values.latestCost,
      
      reference: values.reference,
      internalNotes: values.internalNotes,
    };

    try {
      setNewProductStatus((preState) => ({
        ...preState,
        loading: true,
      }));

      const responseData = await createNewProductApiRequest(
        productValues,
        config
      );
      if (responseData.data.success) {
        setNewProId(responseData.data.data);
        setNewProductStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setNewProductStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const closeDialogWithSuccess = () => {
    setDialog(false);
    onCreateSuccess(newProId);
  };

  return (
    <div>
      <ReactModal
        isOpen={isModalOpen}
        appElement={document.getElementById("root")}
        className={viewStyle.fullHeightContentModal}
        overlayClassName={viewStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className={viewStyle.modalContentContainer}>
          <div className={viewStyle.modalTitle}>
            <h3>{title}</h3>
            <div className={viewStyle.closeIconContainer} onClick={closeModal}>
              <BsX className={viewStyle.closeIcon} />
            </div>
          </div>

          <div className={viewStyle.modalContent_full}>
            <SimpleDialog
              title="Create New Product"
              successMessage="New product added successfully."
              isDialogOpen={dialog}
              closeDialog={() => setDialog(false)}
              loading={newProductStatus.loading}
              loadingMessage="Processing request..."
              success={newProductStatus.success}
              error={newProductStatus.error}
              confirmAction={closeDialogWithSuccess}
            />
            {loading ? (
              <Loader mess="Requesting data, please wait a moment..." />
            ) : success ? (
              <div>
                <ProductDetailsForm
                  type="modalNew"
                  initialBasicInfo={newProductDefaultValues}
                  onSave={saveProductInfo}
                  btnName="Create New Product"
                />
              </div>
            ) : (
              <ErrorMessage mess={error} />
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}
