import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import dashboardStyle from "./dashboardStyle.module.css";
import { HiCube } from "react-icons/hi";
import { stockAlertProductsRequest } from "../../actions/dashboardActions";
import {
  ErrorMessage,
  Loader,
  MoreLink,
  Tag,
  VariantTag,
} from "../../components/viewComponents";
import { AddLinkButton } from "../../components/editComponents";
import NodataChart from "./charts/NodataChart";
import { convtQuantity } from "../../functions/functions";
import NoPermission from "../../components/NoPermission";

export default function StockAlert() {
  const dispatch = useDispatch();
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );
  const { loading, alterProducts, success, error } = useSelector(
    (state) => state.stockAlertBoard
  );

  useEffect(() => {
    if(userDetailsSuccess){
      if(userDetails.data.permissions.includes("view_low_stock_alerts")){
        dispatch(stockAlertProductsRequest());
      }
    }  
  }, [dispatch]);

  return (
    <div className={dashboardStyle.section_wrapper_left_half}>
      {userDetailsSuccess &&
      userDetails.data.permissions.includes("view_low_stock_alerts") ? (
        <div className="w-full">
          <div className={dashboardStyle.section_titleCon}>
            <div className="w-full flex flex-row justify-between">
              <div className="flex items-center ">
                <HiCube className={dashboardStyle.section_icon} />
                <p className={dashboardStyle.section_title}>Low Stock Alert</p>
              </div>

              <MoreLink href={`/reporting/inventory/stock-alert-report`} />
            </div>
          </div>

          {loading ? (
            <Loader mess="Requesting data..." />
          ) : success ? (
            alterProducts.length > 0 ? (
              <div className={dashboardStyle.LeaderBoardWrapepr}>
                {/* <LeaderBoard title="Today's Top Seller" data={topSales} /> */}
                <table className={dashboardStyle.leadBoradTable}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th style={{ width: "100px" }}>Location</th>
                      <th style={{ width: "100px" }}></th>
                      <th>Stock on Hand</th>
                      <th>Re-order Level</th>
                    </tr>
                  </thead>
                  <tbody>
                    {alterProducts.map((item, index) => (
                      <tr key={index}>
                        <td className={dashboardStyle.leadBoradItemCon}>
                          <div className={dashboardStyle.productNameCon}>
                            <p>{item.name}</p>{" "}
                            {item.variant ? (
                              <VariantTag name={item.variant} />
                            ) : (
                              <></>
                            )}
                          </div>
                        </td>
                        <td>
                          <Tag name={item.locationName} color="gray" />
                        </td>
                        <td>
                          {item.stockLevel === 0 ? (
                            <span className={dashboardStyle.noStocktag}>
                              Out of Stock
                            </span>
                          ) : item.stockLevel < 3 ? (
                            <span className={dashboardStyle.lowStocktag}>
                              Low Stock
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>{convtQuantity(item.stockLevel)} </td>
                        <td>{convtQuantity(item.reorderLevel)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <NodataChart text="No low stock products" />
            )
          ) : error ? (
            <ErrorMessage mess={error} />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div className="w-full">
          <div className={dashboardStyle.section_titleCon}>
            <div className="w-full flex flex-row justify-between">
              <div className="flex items-center ">
                <HiCube className={dashboardStyle.section_icon} />
                <p className={dashboardStyle.section_title}>Low Stock Alert</p>
              </div>
            </div>
          </div>
          <NoPermission requiredPermission={"View low stock alerts"} />
        </div>
      )}
    </div>
  );
}
