import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { saleOrderOptionsRequest } from "../../../actions/saleOrderActions";
import { editQuoteRequest } from "../../../api/quoteServices";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { ScreenTitle } from "../../../components/editComponents";
import {
  ErrorMessage,
  Loader,
  SimpleDialog,
} from "../../../components/viewComponents";
import { convtPrice } from "../../../functions/functions";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import QuoteForm from "./QuoteForm";
import { quoteDetailsRequest } from "../../../actions/quoteActions";

export default function EditQuoteScreen() {
  useDocumentTitle("Edit Quote");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { id: quoteId } = useParams();

  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  const { loading, options, error, success } = useSelector(
    (state) => state.saleOrderOptions
  );

  const {
    loading: detailsLoading,
    quoteInfo,
    error: detailsError,
    success: detailsSuccess,
  } = useSelector((state) => state.quoteDetails);

  // edit quote
  const [saveOrderDialog, setSaveOrderDialog] = useState(false);

  const [updateStatus, setUpdateStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });


  useEffect(() => {
    dispatch(saleOrderOptionsRequest());
    if(quoteId){
      dispatch(quoteDetailsRequest(quoteId));
    }
  }, [dispatch, quoteId]);

  //////////////////////////////////////////////////////////////////////////////////
  const orderBasicInfo = () => {
    // structure order basic info
    let find_location = options.locationOptions.find(
      (x) => x.id === quoteInfo.basicInfo.locationId
    );
    let find_customer = options.customerOptions.find(
      (x) => x.id === quoteInfo.basicInfo.customerId
    );

    let find_salesperson = options.userOptions.find(
      (x) => x.id === quoteInfo.basicInfo.salespersonId
    );

    let find_taxrate = options.taxrateOptions.find(
      (x) => x.rate === quoteInfo.basicInfo.taxrate
    );
    let find_price_tier = options.priceTierOptions.find(
      (x) => x.id === quoteInfo.basicInfo.priceTierId
    );
    let find_project = options.projectsOptions.find(
      (x) => x.id === quoteInfo.basicInfo.project_id
    );

    let basic_info = {
      id: quoteInfo.basicInfo.id,
      issueDate: quoteInfo.basicInfo.issueDate
        ? new Date(quoteInfo.basicInfo.issueDate * 1000)
        : "",
      expireDate: quoteInfo.basicInfo.expireDate
      ? new Date(quoteInfo.basicInfo.expireDate * 1000)
      : "",
      orderNumber: quoteInfo.basicInfo.orderNumber,
      customer: find_customer ? find_customer : "",
      salesperson: find_salesperson ? find_salesperson : "",
      taxRate: find_taxrate ? find_taxrate : "",
      taxIncluded: quoteInfo.basicInfo.taxIncluded,
      currency: quoteInfo.basicInfo.currency,
      customerName: quoteInfo.basicInfo.customerName
        ? quoteInfo.basicInfo.customerName
        : "",
      firstName: quoteInfo.basicInfo.firstName
        ? quoteInfo.basicInfo.firstName
        : "",
      lastName: quoteInfo.basicInfo.lastName
        ? quoteInfo.basicInfo.lastName
        : "",
      email: quoteInfo.basicInfo.email ? quoteInfo.basicInfo.email : "",
      phone: quoteInfo.basicInfo.phone ? quoteInfo.basicInfo.phone : "",
      postalAddress: quoteInfo.basicInfo.shippingAddress
        ? quoteInfo.basicInfo.shippingAddress
        : {},
      billingAddress: quoteInfo.basicInfo.billingAddress
        ? quoteInfo.basicInfo.billingAddress
        : {},
      location: find_location ? find_location : "",
      priceTier: find_price_tier ? find_price_tier : "",
      project: find_project ? find_project : "",
      notes: quoteInfo.basicInfo.notes ? quoteInfo.basicInfo.notes : "",
    };
    return basic_info;
  };

  const quoteFiles = () => {
    let copy = [...quoteInfo.orderFiles];
    let data = [];
    copy.forEach((x) => {
      data.push({
        fileName: x.fileName,
        uri: x.uri,
        loading: false,
        error: "",
      });
    });
    return data;
  };

  ////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////// submit //////////////////////////////////////////////
  const openEditQuoteDialog = (values) => {

    let orderItemValues = [];

    values.orderItems.forEach((prod) => {
      let eachItem = {
        type: prod.type,
        packageId: prod.packageId,
        packageName: prod.packageName,
        productName: prod.productName,
        productType: prod.productType,
        variant: prod.variant,
        famid: prod.famid,
        variantCode: prod.variantCode,
        variantSku: prod.variantSku,
        quantity: prod.quantity,
        price: prod.unitPrice,
        discount: prod.discount,
        totalPrice: prod.totalPrice,
      };

      orderItemValues.push(eachItem);
    });

    let orderTotalExcltax;
    let orderTotalIncltax;
    let orderTax;

    if (values.orderInfo.taxIncluded) {
      orderTotalExcltax = convtPrice(
        (Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)) /
          (1 + values.orderInfo.taxRate.rate / 100)
      );

      orderTotalIncltax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)
      );

      orderTax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal) -
          (Number(values.orderSummary.productsTotal) +
            Number(values.orderSummary.additionalCostTotal)) /
            (1 + values.orderInfo.taxRate.rate / 100)
      );
    } else {
      orderTotalExcltax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)
      );
      orderTotalIncltax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal) +
          (Number(values.orderSummary.productsTotal) +
            Number(values.orderSummary.additionalCostTotal)) *
            (values.orderInfo.taxRate.rate / 100)
      );
      orderTax = convtPrice(
        (Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)) *
          (values.orderInfo.taxRate.rate / 100)
      );
    }

    if (typeof values.orderInfo.postalAddress === "object") {
      values.orderInfo.postalAddress = JSON.stringify(
        values.orderInfo.postalAddress
      );
    }
    if (typeof values.orderInfo.billingAddress === "object") {
      values.orderInfo.billingAddress = JSON.stringify(
        values.orderInfo.billingAddress
      );
    }

    let quoteOrderValues = {
      orderSource: quoteInfo.basicInfo.source,
      orderInfo: {
        issueDate: values.orderInfo.issueDate
          ? Math.floor(values.orderInfo.issueDate.getTime() / 1000)
          : "",
        expireDate: values.orderInfo.expireDate
          ? Math.floor(values.orderInfo.expireDate.getTime() / 1000)
          : "",
        orderNumber: values.orderInfo.orderNumber,
        customerId: values.orderInfo.customer
          ? values.orderInfo.customer.id
          : "",
        salespersonId: values.orderInfo.salesperson
          ? values.orderInfo.salesperson.id
          : "",
        taxrateId: values.orderInfo.taxRate.id,
        taxrate: values.orderInfo.taxRate.rate,
        taxIncluded: values.orderInfo.taxIncluded,
        currency: values.orderInfo.currency,
        locationId: values.orderInfo.location.id,
        customerName: values.orderInfo.customerName,
        firstName: values.orderInfo.firstName,
        lastName: values.orderInfo.lastName,
        email: values.orderInfo.email,
        phone: values.orderInfo.phone,
        postalAddress: values.orderInfo.postalAddress,
        billingAddress: values.orderInfo.billingAddress,
        priceTierId: values.orderInfo.priceTier
          ? values.orderInfo.priceTier.id
          : "",
        projectId: values.orderInfo.project ? values.orderInfo.project.id : "",
        notes: values.orderInfo.notes,
        orderTotalExcltax: orderTotalExcltax,
        orderTotalIncltax: orderTotalIncltax,
        orderTax: orderTax,
      },
      orderItems: orderItemValues,
      costs: values.costs,
      files: values.files,
    };

    editSaleOrder(quoteOrderValues);
  };

  const editSaleOrder = async(values) => {
    setSaveOrderDialog(true);
    try {
      setUpdateStatus({loading: true,success: false,error: "",});
      const responseData = await editQuoteRequest(quoteId, values, config);
      if (responseData.data.success) {
        setUpdateStatus({loading: false,success: true,error: "",});
      } else if (responseData.data.error === 26) {
        throw responseData.data.message;
      } else {
        throw "Update quote failed.";
      }
    } catch (error) {
      setUpdateStatus({loading: false,success: false,error: error,});
    }
  };

  const closeDialogWithSuccess = (pageName) => {
    setSaveOrderDialog(false);
    if (pageName === "list") {
      navigate(`/sale/quote-list`, {
        replace: true,
        from: location,
      });
    } else {
      navigate(`/sale/quote/preview/${quoteId}`, {
        replace: true,
        from: location,
      });
    }
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title={"Edit Quote"}
        successMessage="Quote updated successfully."
        isDialogOpen={saveOrderDialog}
        closeDialog={() => setSaveOrderDialog(false)}
        loading={updateStatus.loading}
        loadingMessage="Processing request..."
        success={updateStatus.success}
        error={updateStatus.error}
        confirmAction={closeDialogWithSuccess}
      />

      <Breadcrumbs screenName={"Edit Quote"} />
      <ScreenTitle title={"Edit Quote"} status="Edit" />
      <div className={commonStyle.pageContentContainer}>
        {userDetailsSuccess && (
          <>
            {loading ? (
              <Loader mess="Requesting quote details, please wait a moment..." />
            ) : success ? (
              detailsLoading ? (
                <Loader mess="Requesting quote details, please wait a moment..." />
              ) : detailsSuccess ? (
                <QuoteForm
                  initialOrderInfo={orderBasicInfo}
                  initialOrderItems={quoteInfo.orderItems}
                  initialCosts={quoteInfo.orderCosts}
                  initialFiles={quoteFiles}
                  initialOrderSummary={quoteInfo.orderSummary}
                  submit={openEditQuoteDialog}
                  type="edit"
                />
              ) : detailsError ? (
                <ErrorMessage mess={detailsError} />
              ) : (
                <></>
              )
            ) : error ? (
              <ErrorMessage mess={error} />
            ) : (
              <> </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
