import React, { useState } from "react";
import { BsX } from "react-icons/bs";
import { LuImagePlus } from "react-icons/lu";
import { StyledLabel } from "../../../components/inputFields";
import { ErrorMessage, Loader } from "../../../components/viewComponents";
import viewStyle from "../../../style/styledViewComponentsStyle.module.css";

export default function ProductImageUploader({
  imageUri,
  uploadLoading,
  uploadError,
  onChange,
  onDelete,
}) {
  // drag state
  const [dragActive, setDragActive] = useState(false);
  const [fileError, setFileError] = useState("");

  // triggers when file is dropped
  const dropHandler = (ev) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
    setFileError("");

    let allowedFiles = [];

    if (ev.dataTransfer && ev.dataTransfer.items) {
      const files = [...ev.dataTransfer.items]
        .filter((item) => item.kind === "file")
        .map((item) => item.getAsFile());

      allowedFiles = files.filter((file) => file.type.startsWith("image/"));
    } else if (ev.target.files) {
      const files = [...ev.target.files];
      allowedFiles = files.filter((file) => file.type.startsWith("image/"));
    }

    if (allowedFiles.length === 0) {
      setFileError("Only image files accepted.");
      return;
    }
    setDragActive(false);
    onChange(allowedFiles[0]);
  };

  const dragOverHandler = (ev) => {
    ev.preventDefault();
    setDragActive(true);
  };

  return (
    <div className="w-full">
      <StyledLabel label={"Product Image"} />
      {fileError ? <ErrorMessage mess={fileError} /> : null}
      <div className="w-full" style={{ aspectRatio: "1 / 1" }}>
        {uploadLoading ? (
          <div className="w-full h-full border border-dashed border-borderGray flex items-center justify-center">
            <Loader mess={"Uploading..."} />
          </div>
        ) : !imageUri ? (
          <div
            className="w-full h-full text-center relative"
            onDrop={dropHandler}
            onDragOver={dragOverHandler}
          >
            <input
              type="file"
              id="input-file-upload"
              className="hidden"
              accept="image/*"
              onChange={dropHandler}
            />
            <label
              id="label-file-upload"
              htmlFor="input-file-upload"
              className={`flex w-full items-center justify-center h-full border rounded border-dashed hover:cursor-pointer ${
                dragActive ? "border-brandColor" : "border-borderGray"
              }`}
            >
              <div className="flex flex-col items-center py-6 hover:cursor-pointer">
                <LuImagePlus className="w-14 h-14 text-borderGray" />
                <div className="flex flex-col items-center mt-2 hover:cursor-pointer">
                  <span className="block text-brandColor">
                    Click to browse images
                  </span>
                  <span className="hidden lg:block text-gray">
                    or drag and drop image here
                  </span>
                </div>
              </div>
            </label>
          </div>
        ) : (
          <div className="w-full h-full relative border border-dashed border-borderGray">
            {imageUri ? (
              <div className="relative w-full h-full flex flex-col items-center justify-center">
                <img
                  alt={imageUri}
                  src={imageUri}
                  className="w-full h-full"
                  style={{objectFit:"contain"}}
                />
                <button className="w-8 h-8 bg-brandColor rounded-full absolute -top-2 -right-2 flex items-center justify-center hover:cursor-pointer"
                onClick={onDelete}>
                  <BsX className="w-5 h-5 text-white"/>
                </button>
              </div>
            ) : uploadError ? (
              <ErrorMessage mess={uploadError} />
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}
