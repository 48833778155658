import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userlogout } from "../../actions/authAction.js";
import {
  getLocationOptionsRequest,
  productsTransactionLogReportRequest,
} from "../../api/reportingServices.js";
import Breadcrumbs from "../../components/Breadcrumbs.js";
import Pagination from "../../components/Pagination.js";
import {
  LoadingButton,
  ScreenTitle,
  SimpleBlueButton,
} from "../../components/editComponents.js";
import {
  DateInput,
  Search,
  StyledSelect,
  StyledInput,
} from "../../components/inputFields.js";
import {
  ErrorMessage,
  Loader,
  Tag,
  TextErrorMessage,
  VariantSkuTag,
  ViewDateWithTime,
  DollarTag,
  ViewDateText,
} from "../../components/viewComponents.js";
import { formatDate } from "../../functions/functions";
import { K_DATE_RANGES } from "../../constantsData/dateRanges.js";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle.js";
import { CsvWriter } from "./CsvWriter.js";

export default function ProductsTransactionLogReportScreen() {
  useDocumentTitle("Products Transaction Log Report");
  const dispatch = useDispatch();
  const optionsRef = useRef(true);

  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const today = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today.getDate() - 30);
  const sixtyDaysAgo = new Date();
  sixtyDaysAgo.setDate(today.getDate() - 60);
  const ninetyDaysAgo = new Date();
  ninetyDaysAgo.setDate(today.getDate() - 90);

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);
  const [filterDateRange, setFilterDateRange] = useState(K_DATE_RANGES[0]);
  const [filterStartDate, setFilterStartDate] = useState(thirtyDaysAgo);
  const [filterEndDate, setFilterEndDate] = useState(today);
  const [filterLocation, setFilterLocation] = useState();
  const [filterBatch, setFilterBatch] = useState("");
  const [filterError, setFilterError] = useState("");
  const [locationOptions, setLocationOptions] = useState([]);

  const [reportData, setReportData] = useState([]);
  const [optionStatus, setOptionStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [downloadStatus, setDownloadStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [keyWords, setKeywords] = useState("");
  const [showCost, setShowCost] = useState(false);

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  const handleDateRangeChange = (dateRange) => {
    setFilterDateRange(dateRange);
    let date_value = dateRange ? dateRange.value : "";
    if (Number(date_value) === 30) {
      setFilterStartDate(thirtyDaysAgo);
      setFilterEndDate(today);
    } else if (Number(date_value) === 60) {
      setFilterStartDate(sixtyDaysAgo);
      setFilterEndDate(today);
    } else if (Number(date_value) === 90) {
      setFilterStartDate(ninetyDaysAgo);
      setFilterEndDate(today);
    } else if (Number(date_value) === 90) {
      setFilterStartDate(ninetyDaysAgo);
      setFilterEndDate(today);
    }
  };

  const handleStartDateChange = (date) => {
    setFilterStartDate(date);
    let find_custom_date = K_DATE_RANGES.find((x) => x.value === "custom");
    if (find_custom_date) {
      setFilterDateRange(find_custom_date);
    }
  };
  const handleEndDateChange = (date) => {
    setFilterEndDate(date);
    let find_custom_date = K_DATE_RANGES.find((x) => x.value === "custom");
    if (find_custom_date) {
      setFilterDateRange(find_custom_date);
    }
  };

  useEffect(() => {
    const getOptions = async () => {
      try {
        setOptionStatus((preState) => ({
          ...preState,
          loading: true,
        }));

        const responseData = await getLocationOptionsRequest(config);
        if (responseData.success) {
          optionsRef.current = false;
          let org_locations = responseData.data;
          let option_locations = [];

          org_locations.forEach((element) => {
            option_locations.push({
              id: element.id,
              label: element.name,
              value: element.name,
              is_default: element.is_default,
            });
          });

          if (option_locations.length) {
            setLocationOptions(option_locations);
            let find_default_location = option_locations.find(
              (x) => x.is_default === 1
            );
            if (find_default_location) {
              setFilterLocation(find_default_location);
            } else {
              setFilterLocation(option_locations[0]);
            }
          }
          setOptionStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
        } else if (responseData.error === -3) {
          dispatch(userlogout());
        }
      } catch (error) {
        setOptionStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };

    if (optionsRef) {
      getOptions();
    }
  }, [optionsRef]);

  /////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if(userDetailsSuccess){
      if(userDetails.data.permissions.includes("view_product_cost")){
        setShowCost(true);
      }else{
        setShowCost(false);
      }
    }
  }, [userDetailsSuccess]);
  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (filterLocation) {
      getProducts("initial");
    }
  }, [filterLocation, pageNum]);

  const applyFilter = (e) => {
    e.preventDefault();
    getProducts("filter");
    setPageNum(0);
  };

  const getProducts = async (type) => {
    if (!filterLocation) {
      setFilterError("Please select a location.");
      return;
    }

    let startTimestamp = filterStartDate
      ? Math.floor(filterStartDate.setHours(0, 0, 0, 0) / 1000)
      : "";
    let endTimestamp = filterEndDate
      ? Math.floor(filterEndDate.setHours(23, 59, 59, 999) / 1000)
      : "";
    if (endTimestamp && startTimestamp > endTimestamp) {
      setFilterError("The start date is greater than end date.");
      return;
    }

    try {
      setRequestStatus((preState) => ({
        ...preState,
        loading: true,
      }));

      const responseData = await productsTransactionLogReportRequest(
        keyWords,
        type === "filter" ? 0 : pageNum,
        pageSize,
        filterLocation.id,
        startTimestamp,
        endTimestamp,
        filterBatch,
        config
      );
      if (responseData.success) {
        setReportData(responseData.data);
        setFilterError("");
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else if (responseData.error === -3) {
        dispatch(userlogout());
      } else {
        throw responseData.message ? responseData.message : "Error";
      }
    } catch (error) {
      setRequestStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  const downloadFile = async () => {
    // get full stocktake list
    if (!filterLocation) {
      setFilterError("Please select a location.");
      return;
    }
    let startTimestamp = filterStartDate
      ? Math.floor(filterStartDate.setHours(0, 0, 0, 0) / 1000)
      : "";
    let endTimestamp = filterEndDate
      ? Math.floor(filterEndDate.setHours(23, 59, 59, 999) / 1000)
      : "";
    if (endTimestamp && startTimestamp > endTimestamp) {
      setFilterError("The start date is greater than end date.");
      return;
    }

    let csv_data = [];
    try {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: true,
      }));

      const responseData = await productsTransactionLogReportRequest(
        keyWords,
        0,
        99999999999,
        filterLocation.id,
        startTimestamp,
        endTimestamp,
        filterBatch,
        config
      );

      if (responseData.success) {
        csv_data = responseData.data.products;
        setDownloadStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else if (responseData.error === -3) {
        dispatch(userlogout());
      }
    } catch (error) {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
    //get csv file
    let csvString = "";
    if(showCost){
      let csvWriter = new CsvWriter([
        "Location",
        "Date",
        "Product Code",
        "Product Name",
        "Operator",
        "Change Type",
        "Change Qty",
        "Stock After Change",
        "Unit Revenue",
        "Unit Expense",
        "Unit Cost",
        "Unit Landing Cost",
        "Ref ID",
        "Contact",
        "Salesperson",
        "Project",
        "Batch/Serial Number",
        "Expire Date",
        "Reason",
        "Notes",
        "SU Invoice Number",
        "Xero Invoice Number",
        "Supplier Invoice Number"
      ]);
      for (let line of csv_data) {
        let expire_dates = [];
        if (line.expireDates.length) {
          expire_dates = line.expireDates.map((date) =>
            date
              ? formatDate(
                  new Date(Number(date) * 1000),
                  userDetailsSuccess
                    ? userDetails.data.orgInfo.date_format
                    : "DD/MM/YYYY",
                  userDetailsSuccess
                    ? userDetails.data.orgInfo.time_zone
                    : "Pacific/Auckland"
                )
              : ""
          );
        }
  
        csvWriter.addLine([
          line.locationName,
          formatDate(
            new Date(line.atime * 1000),
            userDetailsSuccess ? userDetails.data.orgInfo.date_format : "DD/MM/YYYY",
            userDetailsSuccess
              ? userDetails.data.orgInfo.time_zone
              : "Pacific/Auckland"
          ),
          line.variantSku,
          line.productName,
          line.creatorFirstName + (line.creatorLastName ? line.creatorLastName : ""),
          line.actionType,
          line.stockChange,
          line.stockAfterChange,
          line.unitIncome,
          line.unitExpense,
          line.unitCost,
          line.unitLandingCost,
          line.refOrderNumber ? line.refOrderNumber : line.referenceId ? line.referenceId : "",
          line.contactName ? line.contactName : "",
          line.salesperson ? line.salesperson : "",
          line.project ? line.project : "",
          line.serialBatchNumbers.length > 0 ? line.serialBatchNumbers.join(", ") : "",
          expire_dates.length ? expire_dates.join(", ") : "",
          line.reason ? line.reason : "",
          line.notes ? line.notes : "",
          line.suInvoiceNumber ? line.suInvoiceNumber : "",
          line.xeroInvoiceNumber ? line.xeroInvoiceNumber : "",
          line.poInvoiceNumber ? line.poInvoiceNumber : "",
        ]);
      }
      csvString = csvWriter.content;

    }else{
      let csvWriter = new CsvWriter([
        "Location",
        "Date",
        "Product Code",
        "Product Name",
        "Operator",
        "Change Type",
        "Change Qty",
        "Stock After Change",
        "Unit Revenue",
        "Ref ID",
        "Contact",
        "Salesperson",
        "Project",
        "Batch/Serial Number",
        "Expire Date",
        "Reason",
        "Notes",
        "SU Invoice Number",
        "Xero Invoice Number",
        "Supplier Invoice Number"
      ]);
      for (let line of csv_data) {
        let expire_dates = [];
        if (line.expireDates.length) {
          expire_dates = line.expireDates.map((date) =>
            date
              ? formatDate(
                  new Date(Number(date) * 1000),
                  userDetailsSuccess
                    ? userDetails.data.orgInfo.date_format
                    : "DD/MM/YYYY",
                  userDetailsSuccess
                    ? userDetails.data.orgInfo.time_zone
                    : "Pacific/Auckland"
                )
              : ""
          );
        }
  
        csvWriter.addLine([
          line.locationName,
          formatDate(
            new Date(line.atime * 1000),
            userDetailsSuccess ? userDetails.data.orgInfo.date_format : "DD/MM/YYYY",
            userDetailsSuccess
              ? userDetails.data.orgInfo.time_zone
              : "Pacific/Auckland"
          ),
          line.variantSku,
          line.productName,
          line.creatorFirstName + (line.creatorLastName ? line.creatorLastName : ""),
          line.actionType,
          line.stockChange,
          line.stockAfterChange,
          line.unitIncome,
          line.refOrderNumber ? line.refOrderNumber : line.referenceId ? line.referenceId : "",
          line.contactName ? line.contactName : "",
          line.salesperson ? line.salesperson : "",
          line.project ? line.project : "",
          line.serialBatchNumbers.length > 0 ? line.serialBatchNumbers.join(", ") : "",
          expire_dates.length ? expire_dates.join(", ") : "",
          line.reason ? line.reason : "",
          line.notes ? line.notes : "",
          line.suInvoiceNumber ? line.suInvoiceNumber : "",
          line.xeroInvoiceNumber ? line.xeroInvoiceNumber : "",
          line.poInvoiceNumber ? line.poInvoiceNumber : "",
        ]);
      }
      csvString = csvWriter.content;
    }

    // create a download link
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

    const startDate = new Date(filterStartDate);
    const endDate = new Date(filterEndDate);

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `products_transaction_log_report_${startDate.toLocaleDateString()}_to_${endDate.toLocaleDateString()}.csv`
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const DownloadBtn = () => {
    return (
      <div style={{ width: "auto" }}>
        {downloadStatus.loading ? (
          <LoadingButton name="Downloading.." />
        ) : (
          <SimpleBlueButton name="Download" action={downloadFile} />
        )}
      </div>
    );
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Products Transaction Log Report" />

      <ScreenTitle
        title="Products Transaction Log Report"
        buttonComponent={<DownloadBtn />}
      />
      <div className={commonStyle.pageContentContainer}>
        <div className="relative z-20">
          <div className="w-full flex flex-row items-center">
            <div className="w-[30%] mr-6">
              <StyledSelect
                label="Location"
                selectOptions={optionStatus.success ? locationOptions : []}
                value={filterLocation}
                onChange={(location) => setFilterLocation(location)}
                clearable={false}
              />
            </div>

            <div className="w-[20%]">
              <StyledSelect
                label="Filter by date range"
                selectOptions={K_DATE_RANGES}
                value={filterDateRange}
                clearable={false}
                onChange={(dateRange) => handleDateRangeChange(dateRange)}
              />
            </div>
            <div className="mt-8 mx-2">OR</div>
            <div className="w-[15%] mr-6">
              <DateInput
                label="Start Date"
                value={filterStartDate}
                disableFuturedate
                onChange={(startDate) => handleStartDateChange(startDate)}
              />
            </div>
            <div className="w-[15%] mr-6">
              <DateInput
                label="End Date"
                value={filterEndDate}
                disableFuturedate
                onChange={(endDate) => handleEndDateChange(endDate)}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex flex-row">
          <div className="w-[30%] mr-6">
            <StyledInput
              label="Product name / code keywords"
              type="text"
              name="keyWords"
              value={keyWords}
              onChange={(keyWords) => setKeywords(keyWords)}
              
              error={""}
            />
          </div>
          <div className="w-[30%] mr-6">
            <StyledInput
              label="Serial/Batch Number"
              type="text"
              name="serial-batch-number"
              value={filterBatch}
              onChange={(batchCode) => setFilterBatch(batchCode)}
              error={""}
            />
          </div>
          <div className="w-[35%] flex flex-row items-end justify-end">
            <button
              onClick={applyFilter}
              className="h-[38px] border border-solid border-brandColor hover:bg-brandColor hover:text-white rounded px-2 hover:cursor-pointer"
            >
              <span>{"Apply Filter"}</span>
            </button>
          </div>
        </div>

        {requestStatus.loading ? (
          <Loader mess="Requesting report, please wait a moment..." />
        ) : requestStatus.success && userDetailsSuccess ? (
          <div className="mt-4">
            <p className="text-left">{`Showing results from ${
              filterDateRange ? filterDateRange.label : ""
            }`}</p>

            <div className={commonStyle.longDataTable}>
              {filterError ? (
                <div style={{ marginTop: "20px" }}>
                  <TextErrorMessage mess={filterError} />
                </div>
              ) : (
                <></>
              )}
              {/* hold the product list table */}
              <div className={commonStyle.longDataTableContainer}>
                <div className={commonStyle.tableWrapper}>
                  <table className={commonStyle.pageTable}>
                    <thead>
                      <tr>
                        <th
                          className={`min-w-[140px] ${commonStyle.stickyColumn}`}
                        >
                          Date
                        </th>
                        <th
                          className={`min-w-[100px] ${commonStyle.stickyColumn}`}
                        >
                          Code
                        </th>
                        <th className="min-w-[240px]">Product Name</th>
                        <th className="min-w-[100px]">Operator</th>
                        <th className="min-w-[100px]">Change Type</th>
                        <th className="min-w-[100px]">Change Qty</th>
                        <th className="min-w-[100px]">Stock After Change</th>
                        <th className="min-w-[100px]">Unit Revenue</th>
                        {showCost && <th className="min-w-[100px]">Unit Expense</th>}
                        {showCost && <th className="min-w-[100px]">Unit Cost</th>}
                        {showCost && <th className="min-w-[100px]">Unit Landing Cost</th>}
                        <th className="min-w-[140px]">Ref ID</th>
                        <th className="min-w-[120px]">Contact</th>
                        <th className="min-w-[100px]">Salesperson</th>
                        <th className="min-w-[120px]">Project</th>
                        <th className="min-w-[120px]">Batch/Serial Number</th>
                        <th className="min-w-[100px]">Expire Date</th>
                        <th className="min-w-[100px]">Reason</th>
                        <th className="min-w-[100px]">Notes</th>
                        <th className="min-w-[100px]">SU Invoice Number</th>
                        <th className="min-w-[100px]">Xero Invoice Number</th>
                        <th className="min-w-[100px]">Supplier Invoice Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reportData.products.length > 0 ? (
                        reportData.products.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td
                                className={`min-w-[140px] ${commonStyle.stickyColumn}`}
                              >
                                <ViewDateWithTime value={item.atime} />
                              </td>
                              <td
                                className={`min-w-[100px] ${commonStyle.stickyColumn}`}
                              >
                                <VariantSkuTag name={item.variantSku} />
                              </td>
                              <td>{item.productName}</td>
                              <td>
                                {item.creatorFirstName}{" "}
                                {item.creatorLastName
                                  ? item.creatorLastName
                                  : ""}
                              </td>
                              <td>{item.actionType}</td>
                              <td>{item.stockChange}</td>
                              <td>{item.stockAfterChange}</td>
                              <td>
                                <DollarTag />
                                {item.unitIncome}
                              </td>
                              {showCost && <td><DollarTag />{item.unitExpense}</td>}
                              {showCost && <td><DollarTag />{item.unitCost}</td>}
                              {showCost && <td><DollarTag />{item.unitLandingCost}</td>}
                              <td>
                                {item.refOrderNumber
                                  ? item.refOrderNumber
                                  : item.referenceId
                                  ? item.referenceId
                                  : ""}
                              </td>
                              <td>
                                {item.contactName ? item.contactName : ""}
                              </td>
                              <td>
                                {item.salesperson ? item.salesperson : ""}
                              </td>
                              <td>{item.project ? item.project : ""}</td>
                              <td>
                                {item.serialBatchNumbers.length ? (
                                  item.serialBatchNumbers.map((item, index) => (
                                    <p key={index}>{item}</p>
                                  ))
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td>
                                {item.expireDates.length ? (
                                  item.expireDates.map((item, index) => (
                                    <div className="flex flex-col">
                                      <ViewDateText key={index} value={item} />
                                    </div>
                                  ))
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td>{item.reason ? item.reason : ""}</td>
                              <td>{item.notes ? item.notes : ""}</td>
                              <td>{item.suInvoiceNumber ? item.suInvoiceNumber : ""}</td>
                              <td>{item.xeroInvoiceNumber ? item.xeroInvoiceNumber : ""}</td>
                              <td>{item.poInvoiceNumber ? item.poInvoiceNumber : ""}</td>
                            </tr>
                          </React.Fragment>
                        ))
                      ) : (
                        <tr>
                          <td>
                            <p>There are no data available.</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {reportData.products.length > 0 ? (
                <Pagination
                  totalPageNum={reportData.total_pages}
                  forcePage={pageNum}
                  onPageChange={onPageChangeRequest}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : requestStatus.error ? (
          <ErrorMessage mess={requestStatus.error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
