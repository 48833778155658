import axios from "axios";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const newQuoteRequest = async (values, config) => {
  try {
    const response = await axios.post(SERVER_URL + `/quote/create`, values, config);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const quoteOrderActionsRequest = async (quoteId, config) => {
  try {
    const response = await axios.get(SERVER_URL + `/quote/actions/${quoteId}`, config);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const editQuoteRequest = async (quoteId, values, config) => {
  try {
    const response = await axios.post(SERVER_URL + `/quote/update/${quoteId}`, values, config);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteQuoteRequest = async (quoteId, config) => {
   try {
     const response = await axios.post(SERVER_URL + `/quote/delete/${quoteId}`, "", config);
     return response.data;
   } catch (error) {
     throw new Error(error.message);
   }
};

export const updateQuoteStatusRequest = async (quoteId, data, config) => {
  try {
    const response = await axios.post(SERVER_URL + `/quote/update-status/${quoteId}`, data, config);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};


