
export const QUOTE_LIST_REQUEST = "QUOTE_LIST_REQUEST";
export const QUOTE_LIST_SUCCESS = "QUOTE_LIST_SUCCESS";
export const QUOTE_LIST_FAIL = "QUOTE_LIST_FAIL";

export const QUOTE_DETAILS_REQUEST = "QUOTE_DETAILS_REQUEST";
export const QUOTE_DETAILS_SUCCESS = "QUOTE_DETAILS_SUCCESS";
export const QUOTE_DETAILS_FAIL = "QUOTE_DETAILS_FAIL";


