import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  productPackageListRequest,
} from "../../../actions/productActions";
import {
  Loader,
  ErrorMessage,
  Tag,
  VariantSkuTag,
} from "../../../components/viewComponents";
import {
  ScreenTitle,
  AddLinkButton,
  ViewButtonLink,
  EditButtonLink
} from "../../../components/editComponents";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import Pagination from "../../../components/Pagination";

export default function ProductPackageScreen() {
  useDocumentTitle("Product Package");
  const dispatch = useDispatch();

   const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const { loading, packageList, error, success } = useSelector(
    (state) => state.productPackageList
  );

  //////////////////////////////////////////////////////////////////////////////
  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    dispatch(productPackageListRequest(pageNum, pageSize));
  }, [dispatch, pageNum]);

  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  const PreviewLink = ({ itemId }) => {
    return userDetailsSuccess ? (
      userDetails.data.permissions.includes("manage_product_package") ? (
        <ViewButtonLink path={`/inventory/product-package/preview/${itemId}`} />
      ) : (
        <></>
      )
    ) : (
      <></>
    );
  };

  const EditLink = ({itemId}) => {
    return (
      userDetailsSuccess ? (
        userDetails.data.permissions.includes(
          "manage_product_package"
        ) ? (
          <EditButtonLink
            path={`/inventory/product-package/edit/${itemId}`}
          />
        ) : (
          <></>
        )
      ) : (
        <></>
      )
    )
  }

  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Product Package" />
      <ScreenTitle title="Product Package" />

     <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting list, please wait a moment..." />
        ) : success ? (
          <div className={commonStyle.longDataTable}>
            <AddLinkButton
              name="New Product Package"
              path="/inventory/product-package/new"
            />

            {/* hold the product list table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Package Code</th>
                  <th>Package Name</th>
                  <th>Price</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {packageList.packages.length > 0 ? (
                  packageList.packages.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <VariantSkuTag name={item.sku} />
                      </td>
                      <td>
                        {item.name}
                      </td>
                      <td>
                      {item.price}
                      </td>
                      <td>
                        {" "}
                        {item.status === "Active" ? (
                          <Tag name={item.status} color="green" />
                        ) : item.status === "Draft" ? (
                          <Tag name={item.status} color="yellow" />
                        ) : item.status === "Completed" ? (
                          <Tag name={item.status} color="blue" />
                        ) : (
                          <></>
                        )}{" "}
                      </td>

                      {/* according to different status show different actions */}
                      <td>
                      <div className={commonStyle.actionsGrop}>
                      <PreviewLink itemId={item.id} />
                        <EditLink itemId={item.id} />
                          </div>
                      
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      <p>
                        There are no product pacakge available, create a new
                        product pacakge now.
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {packageList.packages.length > 0 ? (
              <Pagination
                totalPageNum={packageList.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
        
    </div>
  );
}
