import React, { useState, useEffect } from "react";
import { saveAs } from "file-saver";
import axios from "axios";
import { pdf } from "@react-pdf/renderer";
import { useDispatch, useSelector } from "react-redux";
import { emailTemplatesDetailsRequest } from "../../../actions/orgSettingsActions";
import {
  LoadingButton,
  SimpleBlueButton,
  SingleRatioButton,
} from "../../../components/editComponents";
import {
  StyledEmailInput,
  StyledTextarea,
  StyledInput,
} from "../../../components/inputFields";
import {
  SuccessDialog,
  ErrorMessage,
  Loader,
  ViewText,
  ErrorText,
  TipsText,
} from "../../../components/viewComponents";
import {
  generateQuoteRequest,
} from "../../../api/generatePDFServices";
import { validateEmail } from "../../../functions/validate";
import { updateQuoteStatusRequest } from "../../../api/quoteServices";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function QuoteEmailForm({quoteId, customerEmail, orderInfo, closeModal }) {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: detailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const { loading, templateDetails, error, success } = useSelector(
    (state) => state.emailTemplateDetails
  );

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const pdf_config = {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [emailValues, setEmailValues] = useState({
    recipient: "",
    senderName: "",
    replyto: "",
    template: "Quote",
    subject: "",
    content: "",
    attachedQuote: false,
    file: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    dispatch(emailTemplatesDetailsRequest("quote"));
  }, [dispatch]);

  useEffect(() => {
    if (success && detailsSuccess) {
      let values = {
        recipient: customerEmail,
        senderName: userDetails.data.last_name
          ? userDetails.data.first_name + " " + userDetails.data.last_name
          : userDetails.data.first_name,
        replyto: userDetails.data.email,
        template: "Quote",
        subject: templateDetails ? templateDetails.subject : "",
        content: templateDetails ? templateDetails.content : "",
        attachedQuote: false,
        file: "",
      };
      setEmailValues(values);
    }
  }, [success, detailsSuccess]);

  // handle change when input
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmailValues({ ...emailValues, [name]: value });
  };

  const handleAttachedFileChange = (e) => {
    const { name, value } = e.target;
    setEmailValues({
      ...emailValues,
      name: value,
    });

    if (name === "attachedQuote" && value) {
      setEmailValues({
        ...emailValues,
        ["attachedQuote"]: true,
      });
    } else {
      setEmailValues({
        ...emailValues,
        [name]: value,
      });
    }
  };

  // check input validation
  const checkValidation = () => {
    const hasErrors = validate(emailValues);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      sendEmailRequest(emailValues);
    } else {
      setFormErrors(hasErrors);
    }
  };

  const validate = (values) => {
    let re = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    const errors = {};
    if (!values.recipient) {
      errors.recipient = "Recipient is required!";
    }
    if (!values.subject) {
      errors.subject = "Subject is required!";
    }
    if (!values.content) {
      errors.content = "Content is required!";
    }
    if (!validateEmail(values.recipient)) {
      errors.recipient = "Please enter a valid email address";
    }

    return errors;
  };

  //////////////////////////////////////////////////////////////////////////////
  const sendEmailRequest = async (values) => {
    try {
      setRequestStatus({ ...requestStatus, loading: true });
      let responseData = "";
      let has_blob = false;
      let file_name = "";

      if (emailValues.attachedQuote) {
        responseData = await generateQuoteRequest(
          orderInfo.basicInfo.id,
          pdf_config
        );
        file_name = "Quote_" + orderInfo.basicInfo.orderNumber + ".pdf";
      }
      if (responseData && responseData.status === 200) {
        has_blob = true;
      }

      const formData = new FormData();
      if (has_blob) {
        const blob = new Blob([responseData.data]);
        formData.append("pdf", blob, file_name);
      }

      formData.append("recipientName", orderInfo.basicInfo.supplier);
      formData.append("quoteId", quoteId);
      formData.append("recipientAddress", values.recipient);
      formData.append("attachedQuote", values.attachedQuote);
      formData.append("attachedFile", has_blob);
      formData.append("replyto", values.replyto);
      formData.append("senderName", values.senderName);
      formData.append("replytoName", values.senderName);
      formData.append("subject", values.subject);
      formData.append("content", values.content);

      axios
        .post(SERVER_URL + `/quote/send_email`, formData, config)
        .then(async (response) => {
          if (response.data.success) {
            setRequestStatus({
              ...requestStatus,
              loading: false,
              success: true,
            });
            setDialog(true);
          } else {
            throw "Email sent failed.";
          }
        })
        .catch((err) => {
          setRequestStatus({
            ...requestStatus,
            loading: false,
            error: err,
          });
        });
    } catch (error) {
      setRequestStatus({
        ...requestStatus,
        loading: false,
        error: error,
      });
    }
  };

  const closeSuccessDialog = () => {
    setDialog(false);
    closeModal();
    setEmailValues({
      recipient: "",
      senderName: "",
      replyto: "",
      template: "Sale Order",
      subject: "",
      content: "",
      attachedQuote: false,
      file: "",
    });
  };

  return (
    <>
      {loading ? (
        <Loader mess="Requesting email template content..." />
      ) : success ? (
        detailsSuccess ? (
          <div>
            {requestStatus.success && (
              <SuccessDialog
                title="Send Email"
                successMessage="Email sent successfully!"
                isDialogOpen={dialog}
                closeDialog={closeSuccessDialog}
                btnValue="Confirm"
                confirmAction={closeSuccessDialog}
              />
            )}

            <div>
              <StyledEmailInput
                label="To"
                type="email"
                name="recipient"
                value={emailValues.recipient}
                onChange={(recipient) =>
                  handleChange({
                    target: { value: recipient, name: "recipient" },
                  })
                }
                error={formErrors.recipient}
              />
              <ViewText title="From" value={emailValues.senderName} />
              <ViewText title="Reply to" value={emailValues.replyto} />
              <StyledInput
                label="Subject"
                type="text"
                name="subject"
                value={emailValues.subject}
                onChange={(subject) =>
                  handleChange({
                    target: { value: subject, name: "subject" },
                  })
                }
                error={formErrors.subject}
              />

              <StyledTextarea
                label="Email Message"
                type="text"
                name="notes"
                presetRows="12"
                value={emailValues.content}
                onChange={(content) =>
                  handleChange({
                    target: { value: content, name: "content" },
                  })
                }
                error={formErrors.content}
              />

              <SingleRatioButton
                label="Include quote as attachment"
                name="attachedQuote"
                isOn={emailValues.attachedQuote}
                onChange={(attachedQuote) =>
                  handleAttachedFileChange({
                    target: {
                      value: attachedQuote,
                      name: "attachedQuote",
                    },
                  })
                }
              />

              {requestStatus.error ? (
                <ErrorText mess={requestStatus.error} />
              ) : (
                <></>
              )}
              <div className="w-full mt-6">
                {requestStatus.loading ? (
                  <LoadingButton name="Proceeding..." />
                ) : (
                  <SimpleBlueButton name="Send" action={checkValidation} />
                )}
              </div>
            </div>
          </div>
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </>
  );
}
