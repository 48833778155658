import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ScreenTitle } from "../../../components/editComponents";
import {
  SimpleDialog,
  ErrorMessage,
  Loader,
  MappingXeroAccountDialog,
} from "../../../components/viewComponents";
import inventoryStyle from "../inventoryStyle.module.css";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  productOptionsRequest,
  editProductRequest,
  productDetailsRequest,
} from "../../../actions/productActions";
import ProductDetailsForm from "./ProductDetailsForm";
import { productTypeOptions } from "../../../constantsData/productType";
import { autoGenerateVariantSku } from "../../../functions/functions";

export default function EditProductScreen() {
  useDocumentTitle("Edit Product");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  //////////////////////////////////////////////////////////////////////////////
  const productStatus = location.state;
  const { id: productId } = useParams();
  //////////////////////////////////////////////////////////////////////////////
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const { loading, options, error, success } = useSelector(
    (state) => state.productOptions
  );
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  // edit product dialog
  const [dialog, setDialog] = useState(false);

  const [xeroDialog, setXeroDialog] = useState(false);

  const [productData, setProductData] = useState("");
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    dispatch(productOptionsRequest());
    dispatch(productDetailsRequest(productId, "edit"));
  }, [dispatch, productId]);
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  const {
    loading: detailsLoading,
    productInfo,
    error: detailsError,
    success: detailsSuccess,
  } = useSelector((state) => state.productDetails);

  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
  } = useSelector((state) => state.updateProduct);
  // get edit product details

  const editProductValues = () => {
    let data = {
      type: productTypeOptions.filter(
        (x) => x.value === productInfo.basicInfo.type
      )[0],
      category: options.category.filter(
        (x) => Number(x.id) === Number(productInfo.basicInfo.categoryId)
      )[0],
      productName: productInfo.basicInfo.name,
      unit: options.units.filter(
        (x) => Number(x.id) === Number(productInfo.basicInfo.unitId)
      )[0],
      supplier: options.suppliers.filter(
        (x) => Number(x.id) === Number(productInfo.basicInfo.supplierId)
      )[0],

      description: productInfo.basicInfo.description,
      image: productInfo.basicInfo.image,
      originalManaged: productInfo.basicInfo.managed,
      managed: productInfo.basicInfo.managed,
      serialized: productInfo.basicInfo.serialized,
      batchTracked: productInfo.basicInfo.batchTracked,
      sku: productInfo.basicInfo.variantSku,
      barcode: productInfo.basicInfo.barcode,
      reorderLevel: productInfo.basicInfo.reorderLevel,
      salePrice: productInfo.basicInfo.salePrice,
      latestCost: productInfo.basicInfo.latestCost,
      hasStockOnHand: productInfo.basicInfo.hasStockOnHand,
      hasBatches: productInfo.basicInfo.hasBatches,
      reference: productInfo.basicInfo.reference,
      internalNotes: productInfo.basicInfo.internalNotes,
    };

    return data;
  };

  //////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////

  const saveProductInfo = (values, isDraft) => {
    let productValues = {
      draft: isDraft,
      type: values.type.value,
      categoryId: values.category ? values.category.id : "",
      productName: values.productName,
      unitId: values.unit ? values.unit.id : "",
      supplierId: values.supplier ? values.supplier.id : "",
      description: values.description,
      image: values.image,
      managed: values.managed,
      serialized: values.serialized,
      batchTracked: values.batchTracked,

      sku: values.sku ? values.sku : autoGenerateVariantSku(),
      barcode: values.barcode,
      reorderLevel: values.reorderLevel,
      salePrice: values.salePrice,
      latestCost: values.latestCost,
      reference: values.reference,
      internalNotes: values.internalNotes,
    };

    setProductData(productValues);

    if (
      userDetailsSuccess &&
      userDetails.data.orgInfo.xeroConnected &&
      !userDetails.data.orgInfo.xeroConfigured
    ) {
      setXeroDialog(true);
    } else {
      setDialog(true);
      dispatch(editProductRequest(productValues, productId));
    }
  };

  const closeDialogWithSuccess = () => {
    navigate(-1, { replace: true, from: location });
  };

  const proceedWithoutUpdateToXero = () => {
    setXeroDialog(false);
    setDialog(true);
    dispatch(editProductRequest(productData, productId));
  };

  return (
    <div className={commonStyle.pageContainer}>
      <MappingXeroAccountDialog
        title="Update Product"
        isDialogOpen={xeroDialog}
        closeDialog={() => setXeroDialog(false)}
        proceedAction={proceedWithoutUpdateToXero}
      />
      <SimpleDialog
        title="Update Product"
        successMessage="Product updated successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={updateLoading}
        loadingMessage="Processing request..."
        success={updateSuccess}
        error={updateError}
        confirmAction={closeDialogWithSuccess}
      />

      <Breadcrumbs screenName="Edit Product" />
      <ScreenTitle title="Edit Product" status="Edit" />

      {loading ? (
        <Loader mess="Requesting data, please wait a moment..." />
      ) : success ? (
        detailsLoading ? (
          <Loader mess="Requesting product details, please wait a moment..." />
        ) : detailsSuccess ? (
          <div>
            <ProductDetailsForm
              type={productStatus === "Draft" ? "new" : "editActive"}
              initialBasicInfo={editProductValues}
              onSave={saveProductInfo}
              btnName="Create New Product"
            />
          </div>
        ) : (
          <ErrorMessage mess={detailsError} />
        )
      ) : (
        <ErrorMessage mess={error} />
      )}
    </div>
  );
}
