import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  productsByProjectReportRequest,
  productsByProjectReportFilterOptionRequest,
} from "../../api/reportingServices.js";
import Breadcrumbs from "../../components/Breadcrumbs.js";
import Pagination from "../../components/Pagination.js";
import {
  LoadingButton,
  ScreenTitle,
  SimpleBlueButton,
  SimpleOutlineButton,
} from "../../components/editComponents.js";
import {
  DateInput,
  StyledMultiSelect,
  StyledSelect,
} from "../../components/inputFields.js";
import {
  ErrorMessage,
  Loader,
  QuestionMark,
  Tag,
  TextErrorMessage,
  VariantSkuTag,
  VariantTag,
} from "../../components/viewComponents.js";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle.js";
import { CsvWriter } from "./CsvWriter.js";
import { userlogout } from "../../actions/authAction.js";

export default function ProductsSummaryByProjectScreen() {
  useDocumentTitle("Products Summary By Project Report");
  const dispatch = useDispatch();
  const optionsRef = useRef(true);

  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  // pagination
  const [options, setOptions] = useState();

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);

  const [reportData, setReportData] = useState([]);
  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [downloadStatus, setDownloadStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  const [filterProject, setFilterProject] = useState();
  const [filterError, setFilterError] = useState("");

  useEffect(() => {
    const getOptions = async () => {
      try {
        const responseData = await productsByProjectReportFilterOptionRequest(
          config
        );
        if (responseData.success) {
          optionsRef.current = false;
          if (responseData.data.projects.length) {
            setOptions(responseData.data);
            setFilterProject(responseData.data.projects[0]);
          }
        }else if(responseData.error === -3){
          dispatch(userlogout());
        }
      } catch (error) {}
    };

    if (optionsRef) {
      getOptions();
    }
  }, [optionsRef]);

  const handleProjectChange = (value) => {
    setFilterProject(value);
    setPageNum(0);
  };

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  const downloadFile = async () => {
    // get full stocktake list
    if (!filterProject && !filterProject.id) {
      setFilterError("Please select a project.");
      return;
    }

    let products = [];
    try {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: true,
      }));

      const responseData = await productsByProjectReportRequest(
        0,
        99999999999,
        filterProject.id,
        config
      );

      if (responseData.success) {
        products = responseData.data.products;
        setDownloadStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      }else if(responseData.error === -3){
        dispatch(userlogout());
      }
    } catch (error) {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
    //get csv file
    let csvWriter = new CsvWriter([
      "Project",
      "Code",
      "ProductName",
      "Location",
      "Ordered Qty",
      "Sold Qty",
      "Adjust Qty(Received)",
      "Adjust Qty(Deducted)",
      "Transferred In Qty",
      "Transferred Out Qty",
      "ProjectStockOnHand",
      "TotalStockOnHand",
    ]);
    for (let line of products) {
      csvWriter.addLine([
        line.projectCode + "-" + line.projectName,
        line.variantSku,
        line.productName,
        line.locationName,
        line.receivedQty,
        -line.saleQty,
        line.adjustReceivedQty,
        line.adjustDeductedQty,
        line.transferInQty,
        -line.transferOutQty,
        Number(line.receivedQty) - Number(line.saleQty) + Number(line.adjustReceivedQty) + Number(line.adjustDeductedQty) + Number(line.transferInQty) - Number(line.transferOutQty),
        line.stockLevel,
      ]);
    }
    const csvString = csvWriter.content;

    // create a download link
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "products-summary-by-project-report.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  /////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const getProducts = async () => {
      if (!filterProject) {
        setFilterError("Please select a project.");
        return;
      }
      try {
        setRequestStatus((preState) => ({
          ...preState,
          loading: true,
        }));
  
        const responseData = await productsByProjectReportRequest(
          pageNum,
          pageSize,
          filterProject.id,
          config
        );
        if (responseData.success) {
          setReportData(responseData.data);
          setFilterError("");
          setRequestStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
        }else if(responseData.error === -3){
          dispatch(userlogout());
        }
      } catch (error) {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };

    getProducts();
  }, [pageNum, filterProject]);



  const DownloadBtn = () => {
    return (
      <div style={{ width: "auto" }}>
        {downloadStatus.loading ? (
          <LoadingButton name="Downloading.." />
        ) : (
          <SimpleBlueButton name="Download" action={downloadFile} />
        )}
      </div>
    );
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Products Summary By Project Report" />

      <ScreenTitle
        title="Products Summary By Project Report"
        buttonComponent={<DownloadBtn />}
      />
      <div className={commonStyle.pageContentContainer}>
        <div style={{ width: "100%", display: "flex", alignItems: "flex-end" }}>
          <div
            style={{
              width: "80%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <StyledSelect
              clearable={false}
              label="Project"
              selectOptions={
                options && options.projects.length ? options.projects : []
              }
              value={filterProject}
              onChange={handleProjectChange}
            />
          </div>
        </div>

        {requestStatus.loading ? (
          <Loader mess="Requesting report, please wait a moment..." />
        ) : requestStatus.success && userDetailsSuccess ? (
          <div className={commonStyle.longDataTable}>
            {filterError ? (
              <div style={{ marginTop: "20px" }}>
                <TextErrorMessage mess={filterError} />
              </div>
            ) : (
              <></>
            )}
            {/* hold the product list table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Project</th>
                  <th>Code</th>
                  <th>Product Name</th>
                  <th>Location</th>
                  <th>Ordered Qty</th>
                  <th>Sold Qty</th>
                  <th>Adjust Qty(Received)</th>
                  <th>Adjust Qty(Deducted)</th>
                  <th>Transferred In Qty</th>
                  <th>Transferred Out Qty</th>
                  <th>Project Stock on Hand</th>
                  <th>
                    <QuestionMark
                      title="Total Stock on Hand"
                      text="The stock on hand quantity represents the total quantity in the system and include stock levels from other projects"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {reportData.products.length > 0 ? (
                  reportData.products.map((item, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td>{item.projectName}</td>
                        <td>
                          <VariantSkuTag name={item.variantSku} />
                        </td>
                        <td>{item.productName}</td>

                        <td>
                          <Tag name={item.locationName} color="gray" />
                        </td>
                        <td>{item.receivedQty}</td>
                        <td>{Number(item.saleQty) > 0 ? `-${item.saleQty}` : 0}</td>
                        <td>{item.adjustReceivedQty}</td>
                        <td>{item.adjustDeductedQty}</td>
                        <td>{item.transferInQty}</td>
                        <td>{Number(item.transferOutQty) > 0 ? `-${item.transferOutQty}` : 0}</td>
                        <td>
                          {Number(item.receivedQty) 
                          + Number(item.adjustReceivedQty) 
                          - Number(item.saleQty) 
                          + Number(item.adjustDeductedQty) 
                          + Number(item.transferInQty) 
                          - Number(item.transferOutQty)}
                        </td>
                        <td>{item.stockLevel}</td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td>
                      <p>There are no data available.</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {reportData.products.length > 0 ? (
              <Pagination
                totalPageNum={reportData.total_pages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : requestStatus.error ? (
          <ErrorMessage mess="Requesting report data failed, please try again later." />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
