const K_COUNTRIES = [
    {id: 2, value: "Australia", label: "Australia"},
    {id: 1, value: "New Zealand", label: "New Zealand"},
    {id: 3, value: "United Kingdom", label: "United Kingdom"},
    {id: 8, value: "South Africa", label: "South Africa"},
    {id: 18, value: "Singapore", label: "Singapore"},
    {id: 4, value: "United States", label: "United States"},
    {id: 13, value: "Hong Kong", label: "Hong Kong"},
    {id: 5, value: "Canada", label: "Canada"},
    { id: 40, value: "Afghanistan", label: "Afghanistan" },
    { id: 41, value: "Åland Islands", label: "Åland Islands" },
    { id: 42, value: "Albania", label: "Albania" },
    { id: 43, value: "Algeria", label: "Algeria" },
    { id: 44, value: "American Samoa", label: "American Samoa" },
    { id: 45, value: "Andorra", label: "Andorra" },
    { id: 46, value: "Angola", label: "Angola" },
    { id: 47, value: "Anguilla", label: "Anguilla" },
    { id: 48, value: "Antarctica", label: "Antarctica" },
    { id: 49, value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
    { id: 50, value: "Argentina", label: "Argentina" },
    { id: 51, value: "Armenia", label: "Armenia" },
    { id: 52, value: "Aruba", label: "Aruba" },
    { id: 53, value: "Australia", label: "Australia" },
    { id: 54, value: "Austria", label: "Austria" },
    { id: 55, value: "Azerbaijan", label: "Azerbaijan" },
    { id: 56, value: "Bahamas", label: "Bahamas" },
    { id: 57, value: "Bahrain", label: "Bahrain" },
    { id: 58, value: "Bangladesh", label: "Bangladesh" },
    { id: 59, value: "Barbados", label: "Barbados" },
    { id: 60, value: "Belarus", label: "Belarus" },
    { id: 61, value: "Belgium", label: "Belgium" },
    { id: 62, value: "Belize", label: "Belize" },
    { id: 63, value: "Benin", label: "Benin" },
    { id: 64, value: "Bermuda", label: "Bermuda" },
    { id: 65, value: "Bhutan", label: "Bhutan" },
    { id: 66, value: "Bolivia", label: "Bolivia" },
    { id: 67, value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
    { id: 68, value: "Botswana", label: "Botswana" },
    { id: 69, value: "Bouvet Island", label: "Bouvet Island" },
    { id: 70, value: "Brazil", label: "Brazil" },
    { id: 71, value: "British Indian Ocean Territory", label: "British Indian Ocean Territory" },
    { id: 72, value: "Brunei Darussalam", label: "Brunei Darussalam" },
    { id: 73, value: "Bulgaria", label: "Bulgaria" },
    { id: 74, value: "Burkina Faso", label: "Burkina Faso" },
    { id: 75, value: "Burundi", label: "Burundi" },
    { id: 76, value: "Cambodia", label: "Cambodia" },
    { id: 77, value: "Cameroon", label: "Cameroon" },
    { id: 78, value: "Canada", label: "Canada" },
    { id: 79, value: "Cape Verde", label: "Cape Verde" },
    { id: 80, value: "Cayman Islands", label: "Cayman Islands" },
    { id: 81, value: "Central African Republic", label: "Central African Republic" },
    { id: 82, value: "Chad", label: "Chad" },
    { id: 83, value: "Chile", label: "Chile" },
    { id: 84, value: "China", label: "China" },
    { id: 85, value: "Christmas Island", label: "Christmas Island" },
    { id: 86, value: "Cocos (Keeling) Islands", label: "Cocos (Keeling) Islands" },
    { id: 87, value: "Colombia", label: "Colombia" },
    { id: 88, value: "Comoros", label: "Comoros" },
    { id: 89, value: "Congo", label: "Congo" },
    { id: 90, value: "Congo, Democratic Republic of the", label: "Congo, Democratic Republic of the" },
    { id: 91, value: "Cook Islands", label: "Cook Islands" },
    { id: 92, value: "Costa Rica", label: "Costa Rica" },
    { id: 93, value: "Côte d'Ivoire", label: "Côte d'Ivoire" },
    { id: 94, value: "Croatia", label: "Croatia" },
    { id: 95, value: "Curaçao", label: "Curaçao" },
    { id: 96, value: "Cyprus", label: "Cyprus" },
    { id: 97, value: "Czech Republic", label: "Czech Republic" },
    { id: 98, value: "Denmark", label: "Denmark" },
    { id: 99, value: "Djibouti", label: "Djibouti" },
    { id: 100, value: "Dominica", label: "Dominica" },
    { id: 101, value: "Dominican Republic", label: "Dominican Republic" },
    { id: 102, value: "Ecuador", label: "Ecuador" },
    { id: 103, value: "Egypt", label: "Egypt" },
    { id: 104, value: "El Salvador", label: "El Salvador" },
    { id: 105, value: "Equatorial Guinea", label: "Equatorial Guinea" },
    { id: 106, value: "Eritrea", label: "Eritrea" },
    { id: 107, value: "Estonia", label: "Estonia" },
    { id: 108, value: "Eswatini", label: "Eswatini" },
    { id: 109, value: "Ethiopia", label: "Ethiopia" },
    { id: 110, value: "Falkland Islands (Malvinas)", label: "Falkland Islands (Malvinas)" },
    { id: 111, value: "Faroe Islands", label: "Faroe Islands" },
    { id: 112, value: "Fiji", label: "Fiji" },
    { id: 113, value: "Finland", label: "Finland" },
    { id: 114, value: "France", label: "France" },
    { id: 115, value: "French Guiana", label: "French Guiana" },
    { id: 116, value: "French Polynesia", label: "French Polynesia" },
    { id: 117, value: "French Southern Territories", label: "French Southern Territories" },
    { id: 118, value: "Gabon", label: "Gabon" },
    { id: 119, value: "Gambia", label: "Gambia" },
    { id: 120, value: "Georgia", label: "Georgia" },
    { id: 121, value: "Germany", label: "Germany" },
    { id: 122, value: "Ghana", label: "Ghana" },
    { id: 123, value: "Gibraltar", label: "Gibraltar" },
    { id: 124, value: "Greece", label: "Greece" },
    { id: 125, value: "Greenland", label: "Greenland" },
    { id: 126, value: "Grenada", label: "Grenada" },
    { id: 127, value: "Guadeloupe", label: "Guadeloupe" },
    { id: 128, value: "Guam", label: "Guam" },
    { id: 129, value: "Guatemala", label: "Guatemala" },
    { id: 130, value: "Guernsey", label: "Guernsey" },
    { id: 131, value: "Guinea", label: "Guinea" },
    { id: 132, value: "Guinea-Bissau", label: "Guinea-Bissau" },
    { id: 133, value: "Guyana", label: "Guyana" },
    { id: 134, value: "Haiti", label: "Haiti" },
    { id: 135, value: "Heard Island and McDonald Islands", label: "Heard Island and McDonald Islands" },
    { id: 136, value: "Holy See (Vatican City State)", label: "Holy See (Vatican City State)" },
    { id: 137, value: "Honduras", label: "Honduras" },
    { id: 138, value: "Hong Kong", label: "Hong Kong" },
    { id: 139, value: "Hungary", label: "Hungary" },
    { id: 140, value: "Iceland", label: "Iceland" },
    { id: 141, value: "India", label: "India" },
    { id: 142, value: "Indonesia", label: "Indonesia" },
    { id: 143, value: "Iraq", label: "Iraq" },
    { id: 144, value: "Ireland", label: "Ireland" },
    { id: 145, value: "Isle of Man", label: "Isle of Man" },
    { id: 146, value: "Israel", label: "Israel" },
    { id: 147, value: "Italy", label: "Italy" },
    { id: 148, value: "Jamaica", label: "Jamaica" },
    { id: 149, value: "Japan", label: "Japan" },
    { id: 150, value: "Jersey", label: "Jersey" },
    { id: 151, value: "Jordan", label: "Jordan" },
    { id: 152, value: "Kazakhstan", label: "Kazakhstan" },
    { id: 153, value: "Kenya", label: "Kenya" },
    { id: 154, value: "Kiribati", label: "Kiribati" },
    { id: 155, value: "Korea, South", label: "Korea, South" },
    { id: 156, value: "Kuwait", label: "Kuwait" },
    { id: 157, value: "Kyrgyzstan", label: "Kyrgyzstan" },
    { id: 158, value: "Laos", label: "Laos" },
    { id: 159, value: "Latvia", label: "Latvia" },
    { id: 160, value: "Lebanon", label: "Lebanon" },
    { id: 161, value: "Lesotho", label: "Lesotho" },
    { id: 162, value: "Liberia", label: "Liberia" },
    { id: 163, value: "Libya", label: "Libya" },
    { id: 164, value: "Liechtenstein", label: "Liechtenstein" },
    { id: 165, value: "Lithuania", label: "Lithuania" },
    { id: 166, value: "Luxembourg", label: "Luxembourg" },
    { id: 167, value: "Macau", label: "Macau" },
    { id: 168, value: "Macedonia", label: "Macedonia" },
    { id: 169, value: "Madagascar", label: "Madagascar" },
    { id: 170, value: "Malawi", label: "Malawi" },
    { id: 171, value: "Malaysia", label: "Malaysia" },
    { id: 172, value: "Maldives", label: "Maldives" },
    { id: 173, value: "Mali", label: "Mali" },
    { id: 174, value: "Malta", label: "Malta" },
    { id: 175, value: "Marshall Islands", label: "Marshall Islands" },
    { id: 176, value: "Martinique", label: "Martinique" },
    { id: 177, value: "Mauritania", label: "Mauritania" },
    { id: 178, value: "Mauritius", label: "Mauritius" },
    { id: 179, value: "Mayotte", label: "Mayotte" },
    { id: 180, value: "Mexico", label: "Mexico" },
    { id: 181, value: "Micronesia", label: "Micronesia" },
    { id: 182, value: "Moldova", label: "Moldova" },
    { id: 183, value: "Monaco", label: "Monaco" },
    { id: 184, value: "Mongolia", label: "Mongolia" },
    { id: 185, value: "Montenegro", label: "Montenegro" },
    { id: 186, value: "Montserrat", label: "Montserrat" },
    { id: 187, value: "Morocco", label: "Morocco" },
    { id: 188, value: "Mozambique", label: "Mozambique" },
    { id: 189, value: "Myanmar", label: "Myanmar" },
    { id: 190, value: "Namibia", label: "Namibia" },
    { id: 191, value: "Nauru", label: "Nauru" },
    { id: 192, value: "Nepal", label: "Nepal" },
    { id: 193, value: "Netherlands", label: "Netherlands" },
    { id: 194, value: "Netherlands Antilles", label: "Netherlands Antilles" },
    { id: 195, value: "New Caledonia", label: "New Caledonia" },
    { id: 196, value: "New Zealand", label: "New Zealand" },
    { id: 197, value: "Nicaragua", label: "Nicaragua" },
    { id: 198, value: "Niger", label: "Niger" },
    { id: 199, value: "Nigeria", label: "Nigeria" },
    { id: 200, value: "Niue", label: "Niue" },
    { id: 201, value: "Norfolk Island", label: "Norfolk Island" },
    { id: 202, value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
    { id: 203, value: "Norway", label: "Norway" },
    { id: 204, value: "Oman", label: "Oman" },
    { id: 205, value: "Pakistan", label: "Pakistan" },
    { id: 206, value: "Palau", label: "Palau" },
    { id: 207, value: "Palestinian Territory, Occupied", label: "Palestinian Territory, Occupied" },
    { id: 208, value: "Panama", label: "Panama" },
    { id: 209, value: "Papua New Guinea", label: "Papua New Guinea" },
    { id: 210, value: "Paraguay", label: "Paraguay" },
    { id: 211, value: "Peru", label: "Peru" },
    { id: 212, value: "Philippines", label: "Philippines" },
    { id: 213, value: "Pitcairn Islands", label: "Pitcairn Islands" },
    { id: 214, value: "Poland", label: "Poland" },
    { id: 215, value: "Portugal", label: "Portugal" },
    { id: 216, value: "Puerto Rico", label: "Puerto Rico" },
    { id: 217, value: "Qatar", label: "Qatar" },
    { id: 218, value: "Réunion", label: "Réunion" },
    { id: 219, value: "Romania", label: "Romania" },
    { id: 220, value: "Russian Federation", label: "Russian Federation" },
    { id: 221, value: "Rwanda", label: "Rwanda" },
    { id: 222, value: "Saint Barthélemy", label: "Saint Barthélemy" },
    { id: 223, value: "Saint Helena", label: "Saint Helena" },
    { id: 224, value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
    { id: 225, value: "Saint Lucia", label: "Saint Lucia" },
    { id: 226, value: "Saint Martin (French part)", label: "Saint Martin (French part)" },
    { id: 227, value: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon" },
    { id: 228, value: "Saint Vincent and the Grenadines", label: "Saint Vincent and the Grenadines" },
    { id: 229, value: "Samoa", label: "Samoa" },
    { id: 230, value: "San Marino", label: "San Marino" },
    { id: 231, value: "São Tomé and Príncipe", label: "São Tomé and Príncipe" },
    { id: 232, value: "Saudi Arabia", label: "Saudi Arabia" },
    { id: 233, value: "Senegal", label: "Senegal" },
    { id: 234, value: "Serbia", label: "Serbia" },
    { id: 235, value: "Seychelles", label: "Seychelles" },
    { id: 236, value: "Sierra Leone", label: "Sierra Leone" },
    { id: 237, value: "Singapore", label: "Singapore" },
    { id: 238, value: "Slovakia", label: "Slovakia" },
    { id: 239, value: "Slovenia", label: "Slovenia" },
    { id: 240, value: "Solomon Islands", label: "Solomon Islands" },
    { id: 241, value: "Somalia", label: "Somalia" },
    { id: 242, value: "South Africa", label: "South Africa" },
    { id: 243, value: "South Georgia and the South Sandwich Islands", label: "South Georgia and the South Sandwich Islands" },
    { id: 244, value: "Spain", label: "Spain" },
    { id: 245, value: "Sri Lanka", label: "Sri Lanka" },
    { id: 246, value: "Suriname", label: "Suriname" },
    { id: 247, value: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen" },
    { id: 248, value: "Sweden", label: "Sweden" },
    { id: 249, value: "Switzerland", label: "Switzerland" },
    { id: 250, value: "Taiwan", label: "Taiwan" },
    { id: 251, value: "Tajikistan", label: "Tajikistan" },
    { id: 252, value: "Tanzania", label: "Tanzania" },
    { id: 253, value: "Thailand", label: "Thailand" },
    { id: 254, value: "Timor-Leste", label: "Timor-Leste" },
    { id: 255, value: "Togo", label: "Togo" },
    { id: 256, value: "Tokelau", label: "Tokelau" },
    { id: 257, value: "Tonga", label: "Tonga" },
    { id: 258, value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
    { id: 259, value: "Tunisia", label: "Tunisia" },
    { id: 260, value: "Turkey", label: "Turkey" },
    { id: 261, value: "Turkmenistan", label: "Turkmenistan" },
    { id: 262, value: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
    { id: 263, value: "Tuvalu", label: "Tuvalu" },
    { id: 264, value: "Uganda", label: "Uganda" },
    { id: 265, value: "Ukraine", label: "Ukraine" },
    { id: 266, value: "United Arab Emirates", label: "United Arab Emirates" },
    { id: 267, value: "United Kingdom", label: "United Kingdom" },
    { id: 268, value: "United States", label: "United States" },
    { id: 269, value: "United States Minor Outlying Islands", label: "United States Minor Outlying Islands" },
    { id: 270, value: "Uruguay", label: "Uruguay" },
    { id: 271, value: "Uzbekistan", label: "Uzbekistan" },
    { id: 272, value: "Vanuatu", label: "Vanuatu" },
    { id: 273, value: "Venezuela", label: "Venezuela" },
    { id: 274, value: "Vietnam", label: "Vietnam" },
    { id: 275, value: "Virgin Islands, British", label: "Virgin Islands, British" },
    { id: 276, value: "Virgin Islands, U.S.", label: "Virgin Islands, U.S." },
    { id: 277, value: "Wallis and Futuna", label: "Wallis and Futuna" },
    { id: 278, value: "Western Sahara", label: "Western Sahara" },
    { id: 279, value: "Yemen", label: "Yemen" },
    { id: 280, value: "Zambia", label: "Zambia" },
    { id: 281, value: "Zimbabwe", label: "Zimbabwe" },
    {id: 17, value: "Other", label: "Other"},
  ];

  export {
    K_COUNTRIES
  };