import React, {useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  updateProductPackageRequest,
} from "../../../actions/productActions";
import {
  SimpleDialog,
} from "../../../components/viewComponents";
import {
  ScreenTitle,
} from "../../../components/editComponents";
import commonStyle from "../../../style/commonStyle.module.css";
import inventoryStyle from "../inventoryStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import ProductPackageForm from "./ProductPackageForm";

export default function NewProductPackageScreen() {
  useDocumentTitle("New Product Package");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  // add new product dialog
  const [dialog, setDialog] = useState(false);

  const autoGenerateSku = () => {
    let skuValue = "";
    let min = 1;
    let max = 10000000;
    let rand = (min + Math.random() * (max - min)).toFixed(0);

    let dateObj = new Date();
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    let year = dateObj.getUTCFullYear();
    skuValue = "PK" + year + month + rand;
    return skuValue;
  };

  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  const {
    loading: createLoading,
    error: createError,
    success: createSuccess,
  } = useSelector((state) => state.updateProductPackage);

  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  const newPackageDefaultValues = () => {
    let data = {
      packageName: "",
      type: "package",
      sku: "",
      description: "",
      image: "",
      price: "",
    };
    return data;
  };

  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////


  //////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////

  const savePackageInfo = (values) => {
   
    setDialog(true);
    let packageValues = {
      basicInfo: {
        packageName: values.basicInfo.packageName,
        sku: values.basicInfo.sku ? values.basicInfo.sku : autoGenerateSku(),
        price: values.basicInfo.price,
        description: values.basicInfo.description,
        image: values.basicInfo.image,
      },
      packageProducts: values.packageProducts,
    };

    dispatch(updateProductPackageRequest(packageValues));
  };

  const closeDialogWithSuccess = () => {
    navigate("/inventory/product-package", { replace: true, from: location });
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Create New Product Package"
        successMessage="New product package added successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={createLoading}
        loadingMessage="Processing request..."
        success={createSuccess}
        error={createError}
        confirmAction={closeDialogWithSuccess}
      />

      <Breadcrumbs screenName="New Product Package" />
      <ScreenTitle title="New Product Package" status="New" />

      <div className={inventoryStyle.formCon}>
        <ProductPackageForm
          type="new"
          initialBasicInfo={newPackageDefaultValues}
          initialProducts={[]}
          onSave={savePackageInfo}
          btnName="Create New Product Package"
        />
      </div>
    </div>
  );
}
