import React, { useState, useEffect, useRef } from "react";
import { userlogout } from "../actions/authAction.js";
import { useSelector, useDispatch } from "react-redux";
import filterStyle from "../style/filterStyle.module.css";
import { MdKeyboardArrowDown, MdFilterList } from "react-icons/md";
import { SimpleBlueButton, SingleRatioButton } from "./editComponents";
import { DateInput, StyledMultiSelect } from "./inputFields";
import { ErrorText, Loader, ErrorMessage, SubTitle } from "./viewComponents";
import { productOptionsRequest } from "../actions/productActions";
import { getLocationOptionsRequest } from "../api/reportingServices";
import { FaRegCheckSquare } from "react-icons/fa";
import { HiOutlineViewColumns } from "react-icons/hi2";





function DateFilter({ onSelect }) {
  const { userDetails } = useSelector((state) => state.userDetail);
  const userTimeZone = userDetails.data.orgInfo.time_zone;

  let localToday = new Date();
  let userTimeZoneTodayStamp = localToday.toLocaleDateString("en-US", {
    timeZone: userTimeZone,
  });

  const dateOptions = [
    {
      startDate: new Date(
        new Date(userTimeZoneTodayStamp) - 7 * 24 * 60 * 60 * 1000
      ),
      endDate: new Date(
        new Date(userTimeZoneTodayStamp) - 1 * 24 * 60 * 60 * 1000
      ),
      label: "Last 7 days",
    },
    {
      startDate: new Date(
        new Date(userTimeZoneTodayStamp) - 15 * 24 * 60 * 60 * 1000
      ),
      endDate: new Date(
        new Date(userTimeZoneTodayStamp) - 1 * 24 * 60 * 60 * 1000
      ),
      label: "Last 15 days",
    },
    {
      startDate: new Date(
        new Date(userTimeZoneTodayStamp) - 30 * 24 * 60 * 60 * 1000
      ),
      endDate: new Date(
        new Date(userTimeZoneTodayStamp) - 1 * 24 * 60 * 60 * 1000
      ),
      label: "Last 30 days",
    },
  ];

  const [filterOpen, setFilterOpen] = useState(false);
  const [optionSelected, setOptionSelected] = useState(dateOptions[0]);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const [formErrors, setFormErrors] = useState({});

  const selectOption = (index) => {
    setOptionSelected(dateOptions[index]);
    setFilterOpen(false);
    setDatePickerOpen(false);

    let startTimestamp = Math.floor(
      dateOptions[index].startDate.setHours(0, 0, 0, 0) / 1000
    );
    let endTimestamp = Math.floor(
      dateOptions[index].endDate.setHours(23, 59, 59, 999) / 1000
    );

    // onSelect({startTime: new Date(startTimestamp * 1000), endTime: new Date(endTimestamp * 1000)});
    onSelect({ startTime: startTimestamp, endTime: endTimestamp });
  };

  const openDataPicker = () => {
    setDatePickerOpen(true);
    setOptionSelected({
      startDate: "",
      endDate: "",
      label: "Custom date range",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOptionSelected({ ...optionSelected, [name]: value });
  };

  const checkValidation = () => {
    const hasErrors = validate(optionSelected);

    if (hasErrors && Object.keys(hasErrors).length === 0) {
      setFilterOpen(false);
      setDatePickerOpen(false);
      setFormErrors({});

      let startTimestamp;
      let endTimestamp;

      startTimestamp = Math.floor(
        optionSelected.startDate.setHours(0, 0, 0, 0) / 1000
      );
      endTimestamp = Math.floor(
        optionSelected.endDate.setHours(23, 59, 59, 999) / 1000
      );

      //onSelect({startTime: new Date(startTimestamp * 1000), endTime: new Date(endTimestamp * 1000)});
      onSelect({ startTime: startTimestamp, endTime: endTimestamp });
    } else {
      setFormErrors(hasErrors);
    }
  };

  // check input validation
  const validate = (values) => {
    const errors = {};

    if (!values.startDate) {
      errors.startDate = "Please select a start date.";
    }
    if (!values.endDate) {
      errors.endDate = "Please select a end date.";
    }
    if (values.endDate) {
      if (values.endDate < values.startDate) {
        errors.dateError = "End date is less than start date.";
      }
    }

    return errors;
  };

  return (
    <div>
      <div
        className={
          filterOpen
            ? `${filterStyle.dateFilterCon} ${filterStyle.open}`
            : filterStyle.dateFilterCon
        }
      >
        <div
          className={filterStyle.selectedOptionCon}
          onClick={() => setFilterOpen(!filterOpen)}
        >
          <span>{optionSelected.label}</span>
          <MdKeyboardArrowDown className={filterStyle.toggleBtn} />
        </div>

        <div className={filterStyle.filterOptionsCon}>
          {dateOptions.map((item, index) => (
            <div
              key={index}
              className={
                optionSelected.label === item.label
                  ? `${filterStyle.dateOption} ${filterStyle.selected}`
                  : filterStyle.dateOption
              }
              onClick={() => selectOption(index)}
            >
              {item.label}
            </div>
          ))}
          <div
            className={
              datePickerOpen
                ? `${filterStyle.dateOption} ${filterStyle.selected}`
                : filterStyle.dateOption
            }
            onClick={openDataPicker}
          >
            Custom date range
          </div>
          <div
            className={
              datePickerOpen
                ? `${filterStyle.datePickerCon} ${filterStyle.datePickerOpen}`
                : filterStyle.datePickerCon
            }
          >
            <DateInput
              label="Start Date"
              error={formErrors.startDate}
              value={optionSelected.startDate}
              disableFuturedate
              onChange={(startDate) =>
                handleChange({
                  target: {
                    value: startDate,
                    name: "startDate",
                  },
                })
              }
            />
            <DateInput
              label="End Date"
              error={formErrors.endDate}
              value={optionSelected.endDate}
              disableFuturedate
              onChange={(endDate) =>
                handleChange({
                  target: {
                    value: endDate,
                    name: "endDate",
                  },
                })
              }
            />
            {formErrors.dateError ? (
              <ErrorText mess={formErrors.dateError} />
            ) : (
              <></>
            )}
            <div className="w-full my-4">
              <SimpleBlueButton
                name="Apply Filter"
                action={() => checkValidation()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ProductListFilter({
  onSelect,
  initialFilter,
  filterOptions,
  onDelete,
}) {
  const dispatch = useDispatch();

  const statusOptions = [
    { id: "1", label: "Draft", value: "Draft" },
    { id: "2", label: "Active", value: "Active" },
  ];

  const {
    loading: optionsLoading,
    options,
    error: optionsError,
    success: optionsSuccess,
  } = useSelector((state) => state.productOptions);

  const [filterData, setFilterData] = useState(initialFilter);
  const [filterOpen, setFilterOpen] = useState(false);

  useEffect(() => {
    dispatch(productOptionsRequest());
  }, [dispatch, onDelete]);

  useEffect(() => {
    setFilterData(initialFilter);
  }, [initialFilter]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterData((prev) => ({ ...prev, [name]: value }));
  };

  const checkValidation = () => {
    setFilterOpen(false);
    onSelect(filterData);
  };

  return (
    <div>
      <div className={`relative`}>
        <div
          className="w-[44px] h-[44px] ml-3 flex items-center justify-center rounded border border-solid border-brandColor hover:bg-brandColor hover:text-white hover:cursor-pointer"
          onClick={() => setFilterOpen(!filterOpen)}
        >
          <MdFilterList className="w-4 h-4" />
        </div>

        <div
          className={`${
            filterOpen ? "absolute block top-14 right-0 z-10 " : "hidden"
          }  min-w-[280px] bg-white shadow-cardShadow p-4 rounded`}
        >
          {optionsLoading ? (
            <Loader mess="Requesting..." />
          ) : optionsSuccess ? (
            <div>
              <SubTitle name={"Product Filter"} />
              {filterOptions.includes("category") ? (
                <StyledMultiSelect
                  label="Product Category"
                  selectOptions={options.category}
                  value={filterData.category}
                  onChange={(category) =>
                    handleChange({
                      target: { value: category, name: "category" },
                    })
                  }
                />
              ) : (
                <></>
              )}

              {filterOptions.includes("location") ? (
                <StyledMultiSelect
                  label="Location"
                  selectOptions={options.location}
                  value={filterData.location}
                  onChange={(location) =>
                    handleChange({
                      target: { value: location, name: "location" },
                    })
                  }
                />
              ) : (
                <></>
              )}

              {filterOptions.includes("supplier") ? (
                <StyledMultiSelect
                  label="Supplier"
                  selectOptions={options.suppliers}
                  value={filterData.supplier}
                  onChange={(supplier) =>
                    handleChange({
                      target: { value: supplier, name: "supplier" },
                    })
                  }
                />
              ) : (
                <></>
              )}

              {filterOptions.includes("status") ? (
                <StyledMultiSelect
                  label="Status"
                  selectOptions={statusOptions}
                  value={filterData.status}
                  onChange={(status) =>
                    handleChange({
                      target: { value: status, name: "status" },
                    })
                  }
                />
              ) : (
                <></>
              )}
              <div className="w-full my-4">
                <SimpleBlueButton
                  name="Apply Filter"
                  action={() => checkValidation()}
                />
              </div>
            </div>
          ) : (
            <ErrorMessage mess={optionsError} />
          )}
        </div>
      </div>
    </div>
  );
}

function SaleOrderListFilter({ onSelect, initialFilter, onDelete }) {
  const optionsRef = useRef(true);
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const statusOptions = [
    { id: 1, label: "Draft", value: "Draft" },
    { id: 2, label: "Back Order", value: "Back Order" },
    { id: 2, label: "Approved", value: "Approved" },
    { id: 2, label: "Void", value: "Void" },
  ];
  const [refresh, setRefresh] = useState(0); 
  const [optionStatus, setOptionStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [locationOptions, setLocationOptions] = useState([]);

  const [filterData, setFilterData] = useState(initialFilter);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterError, setFilterError] = useState("");

  useEffect(() => {
    const getOptions = async () => {
      try {
        setOptionStatus({loading: true, success: false,error: ""});

        const responseData = await getLocationOptionsRequest(config);
        if (responseData.success) {
          optionsRef.current = false;
          let org_locations = responseData.data;
          let option_locations = [];

          org_locations.forEach((element) => {
            option_locations.push({
              id: element.id,
              label: element.name,
              value: element.name,
              is_default: element.is_default,
            });
          });

          if (option_locations.length) {
            setLocationOptions(option_locations);
          }
          setOptionStatus({ loading: false, success: true, error: "" });
        } else if (responseData.error === -3) {
          dispatch(userlogout());
        }
      } catch (error) {
        setOptionStatus({ loading: false, success: false, error: error })
      }
    };

    if (optionsRef) {
      getOptions();
    }
  }, [optionsRef]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterData((prev) => ({ ...prev, [name]: value }));
  };

  const checkValidation = () => {
    if (filterData.startDate && filterData.endDate) {
      if(new Date(filterData.startDate) > new Date(filterData.endDate)){
        setFilterError("TStart date cannot be greater than end date");
        return;
      }
    }
    setFilterError("");
    setFilterOpen(false);
    onSelect(filterData);
  };


  useEffect(() => {
    setFilterData(initialFilter);
    setRefresh((prev) => prev + 1); 
  }, [initialFilter, onDelete]);

  return (
    <div key={refresh}>
      <div className={`relative`}>
        <div
          className="w-[80px] h-[44px] ml-3 flex items-center justify-center rounded border border-solid border-brandColor hover:bg-brandColor hover:text-white hover:cursor-pointer"
          onClick={() => setFilterOpen(!filterOpen)}
        >
          <MdFilterList className="w-5 h-5 mr-1" />
          <span className="text-base">Filter</span>
        </div>

        <div
          className={`${
            filterOpen ? "absolute block top-14 right-0 z-10 " : "hidden"
          }  min-w-[280px] bg-white shadow-cardShadow p-4 rounded`}
        >
          <div>
            <SubTitle name={"Filter"} />
            <StyledMultiSelect
              label="Status"
              selectOptions={statusOptions}
              value={filterData.status}
              onChange={(status) =>
                handleChange({
                  target: { value: status, name: "status" },
                })
              }
            />

            <StyledMultiSelect
              label="Location"
              selectOptions={optionStatus.success ? locationOptions : []}
              value={filterData.location}
              onChange={(location) =>
                handleChange({
                  target: { value: location, name: "location" },
                })
              }
            />

            <DateInput
              label="Start Date"
              value={filterData.startDate}
              disableFuturedate
              onChange={(startDate) =>
                handleChange({
                  target: { value: startDate, name: "startDate" },
                })
              }
            />
            <DateInput
              label="End Date"
              value={filterData.endDate}
              disableFuturedate={false}
              onChange={(endDate) =>
                handleChange({
                  target: { value: endDate, name: "endDate" },
                })
              }
            />
            {filterError && <p className="text-red-500 mt-4">{filterError}</p>}

            <div className="w-full my-4">
              <SimpleBlueButton
                name="Apply Filter"
                action={() => checkValidation()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function PurchaseOrderListFilter({ onSelect, initialFilter, onDelete }) {
  const optionsRef = useRef(true);
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const statusOptions = [
    { id: 1, label: "Draft", value: "Draft" },
    { id: 2, label: "Approved", value: "Approved" },
    { id: 2, label: "Completed", value: "Completed" },
  ];
  const [refresh, setRefresh] = useState(0); 
  const [optionStatus, setOptionStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [locationOptions, setLocationOptions] = useState([]);

  const [filterData, setFilterData] = useState(initialFilter);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterError, setFilterError] = useState("");

  useEffect(() => {
    const getOptions = async () => {
      try {
        setOptionStatus({loading: true, success: false,error: ""});

        const responseData = await getLocationOptionsRequest(config);
        if (responseData.success) {
          optionsRef.current = false;
          let org_locations = responseData.data;
          let option_locations = [];

          org_locations.forEach((element) => {
            option_locations.push({
              id: element.id,
              label: element.name,
              value: element.name,
              is_default: element.is_default,
            });
          });

          if (option_locations.length) {
            setLocationOptions(option_locations);
          }
          setOptionStatus({ loading: false, success: true, error: "" });
        } else if (responseData.error === -3) {
          dispatch(userlogout());
        }
      } catch (error) {
        setOptionStatus({ loading: false, success: false, error: error })
      }
    };

    if (optionsRef) {
      getOptions();
    }
  }, [optionsRef]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterData((prev) => ({ ...prev, [name]: value }));
  };

  const checkValidation = () => {
    if (filterData.startDate && filterData.endDate) {
      if(new Date(filterData.startDate) > new Date(filterData.endDate)){
        setFilterError("Start date cannot be greater than end date");
        return;
      }
    }
    setFilterError("");
    setFilterOpen(false);
    onSelect(filterData);
  };


  useEffect(() => {
    setFilterData(initialFilter);
    setRefresh((prev) => prev + 1); 
  }, [initialFilter, onDelete]);

  return (
    <div key={refresh}>
      <div className={`relative`}>
        <div
          className="w-[80px] h-[44px] ml-3 flex items-center justify-center rounded border border-solid border-brandColor hover:bg-brandColor hover:text-white hover:cursor-pointer"
          onClick={() => setFilterOpen(!filterOpen)}
        >
          <MdFilterList className="w-5 h-5 mr-1" />
          <span className="text-base">Filter</span>
        </div>

        <div
          className={`${
            filterOpen ? "absolute block top-14 right-0 z-10 " : "hidden"
          }  min-w-[280px] bg-white shadow-cardShadow p-4 rounded`}
        >
          <div>
            <SubTitle name={"Filter"} />
            <StyledMultiSelect
              label="Status"
              selectOptions={statusOptions}
              value={filterData.status}
              onChange={(status) =>
                handleChange({
                  target: { value: status, name: "status" },
                })
              }
            />

            <StyledMultiSelect
              label="Location"
              selectOptions={optionStatus.success ? locationOptions : []}
              value={filterData.location}
              onChange={(location) =>
                handleChange({
                  target: { value: location, name: "location" },
                })
              }
            />

            <DateInput
              label="Start Date"
              value={filterData.startDate}
              disableFuturedate
              onChange={(startDate) =>
                handleChange({
                  target: { value: startDate, name: "startDate" },
                })
              }
            />
            <DateInput
              label="End Date"
              value={filterData.endDate}
              disableFuturedate={false}
              onChange={(endDate) =>
                handleChange({
                  target: { value: endDate, name: "endDate" },
                })
              }
            />
            {filterError && <p className="text-red-500 mt-4">{filterError}</p>}

            <div className="w-full my-4">
              <SimpleBlueButton
                name="Apply Filter"
                action={() => checkValidation()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function AdjustTableColumns({ staticColumns, dynamicColumns, selectedColumns, onSave }) {

  const [filterOpen, setFilterOpen] = useState(false);
  const [columns, setColumns] = useState([]);

  useEffect(()=>{
    if(selectedColumns.length){
      setColumns(selectedColumns);
    }
  }, [selectedColumns])

  const checkValidation = () => {
    onSave(columns);
    setFilterOpen(false);
  }
  const handleChange = (columnName) => {
    setColumns((prevColumns) => {
      if (prevColumns.includes(columnName)) {
        return prevColumns.filter((item) => item !== columnName);
      } else {
        return [...prevColumns, columnName];
      }
    });
  }

  return (
    <div>
      <div className={`relative`}>
        <div
          className="w-fit h-[44px] px-3 flex items-center justify-center rounded border border-solid border-black hover:border-brandColor hover:bg-brandColor hover:text-white hover:cursor-pointer"
          onClick={() => setFilterOpen(!filterOpen)}
        >
          <HiOutlineViewColumns className="w-5 h-5 mr-1" />
          <span className="text-base">Columns</span>
        </div>

        <div
          className={`${
            filterOpen ? "absolute block top-14 right-0 z-10 " : "hidden"
          }  min-w-[280px] bg-white shadow-cardShadow p-4 rounded`}
        >
          <div>
            <SubTitle name={"Columns"} />
            {staticColumns.map((item, index) => (
              <div key={index} className="mt-2 flex items-center">
                <FaRegCheckSquare className="text-gray-500 w-4 h-4 mr-1" />
                <span>
                  {item}
                </span>
            </div>
            ))
            }
            {dynamicColumns.length ? <div> {
              dynamicColumns.map((item, index)=>(
                <SingleRatioButton
                key={index}
                label={item}
                name={`column name : ${item}`}
                isOn={columns.includes(item)}
                onChange={()=>handleChange(item)}
              />

              ))
            }

            <div className="w-full my-4">
              <SimpleBlueButton
                name="Save"
                action={() => checkValidation()}
              />
            </div>
            </div> 
             : 
             <></>
             }
          </div>
        </div>
      </div>
    </div>
  );
}


function QuoteListFilter({ onSelect, initialFilter, onDelete }) {
  const optionsRef = useRef(true);
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const statusOptions = [
    { id: 1, label: "Draft", value: "Draft" },
    { id: 2, label: "Sent", value: "Sent" },
    { id: 3, label: "Accepted", value: "Accepted" },
    { id: 4, label: "Declined", value: "Declined" },
    { id: 5, label: "Completed", value: "Completed" },
  ];
  const [refresh, setRefresh] = useState(0); 
  const [optionStatus, setOptionStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [locationOptions, setLocationOptions] = useState([]);

  const [filterData, setFilterData] = useState(initialFilter);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterError, setFilterError] = useState("");

  useEffect(() => {
    const getOptions = async () => {
      try {
        setOptionStatus({loading: true, success: false,error: ""});

        const responseData = await getLocationOptionsRequest(config);
        if (responseData.success) {
          optionsRef.current = false;
          let org_locations = responseData.data;
          let option_locations = [];

          org_locations.forEach((element) => {
            option_locations.push({
              id: element.id,
              label: element.name,
              value: element.name,
              is_default: element.is_default,
            });
          });

          if (option_locations.length) {
            setLocationOptions(option_locations);
          }
          setOptionStatus({ loading: false, success: true, error: "" });
        } else if (responseData.error === -3) {
          dispatch(userlogout());
        }
      } catch (error) {
        setOptionStatus({ loading: false, success: false, error: error })
      }
    };

    if (optionsRef) {
      getOptions();
    }
  }, [optionsRef]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterData((prev) => ({ ...prev, [name]: value }));
  };

  const checkValidation = () => {
    if (filterData.startDate && filterData.endDate) {
      if(new Date(filterData.startDate) > new Date(filterData.endDate)){
        setFilterError("TStart date cannot be greater than end date");
        return;
      }
    }
    setFilterError("");
    setFilterOpen(false);
    onSelect(filterData);
  };


  useEffect(() => {
    setFilterData(initialFilter);
    setRefresh((prev) => prev + 1); 
  }, [initialFilter, onDelete]);

  return (
    <div key={refresh}>
      <div className={`relative`}>
        <div
          className="w-[80px] h-[44px] ml-3 flex items-center justify-center rounded border border-solid border-brandColor hover:bg-brandColor hover:text-white hover:cursor-pointer"
          onClick={() => setFilterOpen(!filterOpen)}
        >
          <MdFilterList className="w-5 h-5 mr-1" />
          <span className="text-base">Filter</span>
        </div>

        <div
          className={`${
            filterOpen ? "absolute block top-14 right-0 z-10 " : "hidden"
          }  min-w-[280px] bg-white shadow-cardShadow p-4 rounded`}
        >
          <div>
            <SubTitle name={"Filter"} />
            <StyledMultiSelect
              label="Status"
              selectOptions={statusOptions}
              value={filterData.status}
              onChange={(status) =>
                handleChange({
                  target: { value: status, name: "status" },
                })
              }
            />

            <StyledMultiSelect
              label="Location"
              selectOptions={optionStatus.success ? locationOptions : []}
              value={filterData.location}
              onChange={(location) =>
                handleChange({
                  target: { value: location, name: "location" },
                })
              }
            />

            <DateInput
              label="Start Date"
              value={filterData.startDate}
              disableFuturedate
              onChange={(startDate) =>
                handleChange({
                  target: { value: startDate, name: "startDate" },
                })
              }
            />
            <DateInput
              label="End Date"
              value={filterData.endDate}
              disableFuturedate={false}
              onChange={(endDate) =>
                handleChange({
                  target: { value: endDate, name: "endDate" },
                })
              }
            />
            {filterError && <p className="text-red-500 mt-4">{filterError}</p>}

            <div className="w-full my-4">
              <SimpleBlueButton
                name="Apply Filter"
                action={() => checkValidation()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { DateFilter, ProductListFilter, SaleOrderListFilter, PurchaseOrderListFilter, QuoteListFilter, AdjustTableColumns };
