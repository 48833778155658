import React, { useEffect, useState } from "react";
import { AiOutlineMinus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { bulkStockAdjustRequest } from "../../../actions/productActions";
import {
  getProductStockLevelByLocationRequest,
  stockAdjustOptionsRequest,
} from "../../../api/productServices";
import Breadcrumbs from "../../../components/Breadcrumbs";
import ProductPicker from "../../../components/ProductPicker";
import {
  BarcodeScanButton,
  ScreenTitle,
  SimpleBlueButton,
  XDeleteButton,
} from "../../../components/editComponents";
import {
  CreateableSelectField,
  DateInput,
  NoBorderInput,
  StyledInput,
  StyledSelect,
} from "../../../components/inputFields";
import {
  BatchNumberCollect,
  BatchNumberSelect,
  SerialNumberCollect,
  SerialNumberSelect,
} from "../../../components/trackingNumbersComponents";
import {
  Modal,
  ContentModal,
  ErrorMessage,
  FadeErrorMessage,
  Loader,
  ProceedStockAdjustDialog,
  SubTitle,
  TextErrorMessage,

  XeroAccountMappingTip,
  ProductCodeLinkToNewTab,
} from "../../../components/viewComponents";
import { convtPrice } from "../../../functions/functions";
import { validatePrice, validateQuantity } from "../../../functions/validate";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import ProductScanForm from "../ProductScanForm";
import inventoryStyle from "../inventoryStyle.module.css";
import { adjustTypeOptions } from "../../../constantsData/adjustType";
import ProjectForm from "../../settings/ProjectForm";

export default function NewStockBulkAdjustmentScreen() {
  useDocumentTitle("Stock Adjustment");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const {
    loading: saveLoading,
    error: saveError,
    adjustId,
    success: saveSuccess,
  } = useSelector((state) => state.directlyReveiveStock);

  const [options, setOptions] = useState();
  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [newProjectModal, setNewProjectModal] = useState(false);
  //define the error message
  const [formErrors, setFormErrors] = useState({});

  ///
  const [adjustLocation, setAdjustLocation] = useState();
  const [adjustType, setAdjustType] = useState(adjustTypeOptions[0]);
  const [adjustReason, setAdjustReason] = useState();
  const [adjustNotes, setAdjustNotes] = useState("");
  const [adjustProject, setAdjustProject] = useState("");
  const [issueDate, setIssueDate] = useState(new Date());
  const [refNumber, setRefNumber] = useState("");

  const [adjustProducts, setAdjustProducts] = useState([
    {
      famid: "",
      productName: "",
      variantCode: "",
      variantSku: "",
      variant: "",
      unitName: "",
      stockLevel: "",
      latestCost: "",
      adjustQty: "",
      adjustCost: "",
      managed: "",
      serialized: 0,
      batchTracked: 0,
      serialNumbers: [],
      batchNumbers: [],
      consumeSerialNumbers: [],
      consumeBatchNumbers: [],
    },
  ]);

  const [scanModal, setScanModal] = useState(false);

  const [hasXeroManagedProducts, setHasXeroManagedProducts] = useState(false);

  useEffect(() => {
    const getAdjustOptions = async () => {
      try {
        setRequestStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await stockAdjustOptionsRequest(config);
        if (responseData.data.success) {
          setOptions(responseData.data.data);
          let locations = responseData.data.data.locations;
          if (userDetailsSuccess) {
            let find_user_location = locations.find(
              (x) => x.id === userDetails.data.locationId
            );
            if (find_user_location) {
              setAdjustLocation(find_user_location);
            }
          }
          setRequestStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
            error: "",
          }));
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    getAdjustOptions();
  }, []);

  const openScanModal = () => {
    // check if has location selected
    if (!adjustLocation) {
      setFormErrors({
        adjustProducts: "Please select a location before scan product.",
      });
      return;
    }

    setFormErrors({ orderItemsError: "" });
    setScanModal(true);
  };

  const handleChange = (e) => {
    const { type, name, value, index } = e.target;
    if (type === "quantity") {
      const validated = validateQuantity(value);
      if (!validated) return;
    }

    if (type === "price") {
      const validated = validatePrice(value);
      if (!validated) return;
    }

    const variantsCopy = [...adjustProducts];
    variantsCopy[index][name] = value;
    setAdjustProducts(variantsCopy);
  };

  const handleLocationChange = (location) => {
    setAdjustLocation(location);
    updateStockLevel(location.id);
  };

  const updateStockLevel = async (locationId) => {
    let copy = [...adjustProducts];
    if(copy.length){
      for (const pro of copy) {
        if(!pro.famid) return;
        try {
          const responseData = await getProductStockLevelByLocationRequest(
            pro.famid,
            locationId,
            pro.variantCode,
            config
          );
  
          if (responseData.success) {
            pro.stockLevel = responseData.data;
          }
        } catch (error) {}
      }
    }
    setAdjustProducts(copy);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////

  const deleteVariantItem = (index) => {
    let variantsCopy = [...adjustProducts];
    variantsCopy.splice(index, 1);

    setAdjustProducts(variantsCopy);
  };

  ////////////////////////////Submit and check values/////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////

  const checkValidation = () => {
    if (!adjustLocation) {
      setFormErrors({ location: "Please select a location." });
      return;
    }
    if (!adjustType) {
      setFormErrors({ adjustType: "Please select an adjust type." });
      return;
    }

    if (!adjustProducts.some((item) => item.productName)) {
      setFormErrors({
        adjustProducts: "Please add at least one product to this order.",
      });
      return;
    }

    if (adjustProducts.some((item) => Number(item.adjustQty) <= 0)) {
      setFormErrors({ adjustProducts: "Adjust quantity is required." });
      return;
    }

    if (adjustType.value === "STOCKDEDUCTION") {
      if (
        adjustProducts.some(
          (item) => Number(item.adjustQty) > Number(item.stockLevel)
        )
      ) {
        setFormErrors({
          adjustProducts: "Deducted quantity is greater than stock on hand.",
        });
        return;
      }
    }

    if (adjustType.value === "RECEIVESTOCK") {
      // add stock level check if has serial numbers and batch tracking numbers
      if (
        adjustProducts.some(
          (item) =>
            item.serialized &&
            Number(item.serialNumbers.length) !== Number(item.adjustQty)
        )
      ) {
        setFormErrors({
          adjustProducts: "Please enter correct serial numbers.",
        });
        return;
      }

      if (
        adjustProducts.some(
          (item) =>
            item.batchTracked &&
            parseFloat(
              item.batchNumbers
                .reduce((accumulator, currentValue) => {
                  return accumulator + Number(currentValue.quantity);
                }, 0)
                .toFixed(2)
            ) !== Number(item.adjustQty)
        )
      ) {
        setFormErrors({
          adjustProducts: `Please enter correct batch numbers.`
        });
        return;
      }

    }

    if (adjustType.value === "STOCKDEDUCTION") {
      // add stock level check if has serial numbers and batch tracking numbers
      if (
        adjustProducts.some(
          (item) =>
            item.serialized &&
            Number(item.consumeSerialNumbers.length) !== Number(item.adjustQty)
        )
      ) {
        setFormErrors({
          adjustProducts:
            "The quantity of selected serial numbers is not equal to the adjust quantity.",
        });
        return;
      }
      if (
        adjustProducts.some(
          (item) =>
            item.batchTracked &&
          parseFloat(
              item.consumeBatchNumbers.reduce((accumulator, currentValue) => {
                return accumulator + Number(currentValue.consumeQuantity);
              }, 0).toFixed(2)
            ) !== Number(item.adjustQty)
        )      
      ) {
        setFormErrors({
          adjustProducts:
            "The quantity of selected batchs is not equal to the adjust quantity.",
        });
        return;
      }
    }
    // reset error message
    setFormErrors({
      adjustProducts: "",
    });
    // check if has item is xero-managed
    if (adjustProducts.some((item) => Number(item.managed) === 1)) {
      setHasXeroManagedProducts(true);
    }else{
      setHasXeroManagedProducts(false);
    }
    // for xero connected company
    setCreateDialog(true);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////
  const getProductFromPicker = (result) => {
    if (result.productType !== "package") {
      addNewItem(result.data);
    }
  };

  const addNewItem = (proDetails) => {
    let adjustProductsCopy = [...adjustProducts];
    // remove empty row in order items
    adjustProductsCopy = adjustProductsCopy.filter((x) => x.productName);
    // check exist products, error
    let exists = adjustProductsCopy.some(
      (item) =>
        item.famid === proDetails.familyId &&
        item.variantCode === proDetails.variantCode
    );
    if (exists) {
      let index = adjustProductsCopy.findIndex(
        (item) =>
          item.famid === proDetails.familyId &&
          item.variantCode === proDetails.variantCode
      );
      adjustProductsCopy[index].adjustQty =
        Number(adjustProductsCopy[index].adjustQty) + 1;
    } else {
      adjustProductsCopy.push({
        famid: proDetails.familyId,
        productName: proDetails.name,
        variant: proDetails.variant ? proDetails.variant : "",
        variantCode: proDetails.variantCode,
        variantSku: proDetails.variantSku,
        unitName: proDetails.unit,
        managed: proDetails.managed,
        stockLevel: proDetails.stockLevel,
        latestCost: proDetails.latestCost,
        adjustQty: "",
        adjustCost: proDetails.latestCost,
        serialized: proDetails.serialized,
        batchTracked: proDetails.batchTracked,
        serialNumbers: [],
        batchNumbers: [],
        consumeSerialNumbers: [],
        consumeBatchNumbers: [],
      });
    }

    setAdjustProducts(adjustProductsCopy);
  };

  const getScanedProducts = (products) => {
    let adjustProductsCopy = [...adjustProducts];
    // remove empty row in order items
    adjustProductsCopy = adjustProductsCopy.filter((x) => x.productName);

    for (let i = 0; i < products.length; i++) {
      let proDetails = products[i];
      // check exist products, error
      let exists = adjustProductsCopy.some(
        (item) =>
          item.famid === proDetails.familyId &&
          item.variantCode === proDetails.variantCode
      );
      if (exists) {
        let index = adjustProductsCopy.findIndex(
          (item) =>
            item.famid === proDetails.familyId &&
            item.variantCode === proDetails.variantCode
        );
        adjustProductsCopy[index].adjustQty =
          Number(adjustProductsCopy[index].adjustQty) + 1;
      } else {
        adjustProductsCopy.push({
          famid: proDetails.familyId,
          productName: proDetails.productName,
          variant: proDetails.variant ? proDetails.variant : "",
          variantCode: proDetails.variantCode,
          variantSku: proDetails.variantSku,
          unitName: proDetails.unitName,
          managed: proDetails.managed,
          stockLevel: proDetails.stockOnHand,
          latestCost: proDetails.latestCost,
          adjustQty: "",
          adjustCost: proDetails.latestCost,
          serialized: proDetails.serialized,
          batchTracked: proDetails.batchTracked,
          serialNumbers: [],
          batchNumbers: [],
          consumeSerialNumbers: [],
          consumeBatchNumbers: [],
        });
      }
    }
    setAdjustProducts(adjustProductsCopy);
    setScanModal(false);
  };

  /////////////////////////////////Batch and Serial tracking//////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////

  const getSerialNumbersStockReveive = (serialNumbers, index) => {
    let adjustProductsCopy = [...adjustProducts];
    adjustProductsCopy[index].serialNumbers = serialNumbers;
    setAdjustProducts(adjustProductsCopy);
  };

  const getSerialNumbersStockReduce = (serialNumbers, index) => {
    let adjustProductsCopy = [...adjustProducts];
    adjustProductsCopy[index].consumeSerialNumbers = serialNumbers;
    setAdjustProducts(adjustProductsCopy);
  };

  const getBatchNumbersStockReveive = (batchNumbers, index) => {
    let adjustProductsCopy = [...adjustProducts];
    adjustProductsCopy[index].batchNumbers = batchNumbers;
    setAdjustProducts(adjustProductsCopy);
  };

  const getBatchNumbersStockReduce = (batchNumbers, index) => {
    let adjustProductsCopy = [...adjustProducts];
    adjustProductsCopy[index].consumeBatchNumbers = batchNumbers;
    setAdjustProducts(adjustProductsCopy);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////
  // mange dialog content, new and edit
  const [createDialog, setCreateDialog] = useState(false);

  const closeDialogWithSuccess = (pageName) => {

    if(pageName === "details" && adjustId){
      navigate(`/inventory/bulk-stock-adjustment/preview/${adjustId}`, {
        replace: true,
        from: location,
      });
    }else{
      navigate("/inventory/bulk-stock-adjustment", {
        replace: true,
        from: location,
      });
    }
    
  };

  const saveStockAdjustment = (syncXero) => {
    let adjustProductsCopy = [...adjustProducts];
    adjustProductsCopy = adjustProductsCopy.filter(
      (x) => Number(x.adjustQty) > 0
    );

    let productsSubmitedValues = {};
    let adjustProductItems = [];
    adjustProductsCopy.forEach((elem) => {
      let batchNumbersCollected = [];
      if (elem.batchTracked && adjustType.value === "RECEIVESTOCK") {
        elem.batchNumbers.forEach((ele) => {
          batchNumbersCollected.push({
            batchNumber: ele.batchNumber,
            expireDate: ele.expireDate
              ? Math.floor(ele.expireDate.getTime() / 1000)
              : "",
            quantity: ele.quantity,
          });
        });
      } else {
        batchNumbersCollected = elem.batchNumbers;
      }

      adjustProductItems.push({
        famid: Number(elem.famid),
        productName: elem.productName,
        variant: elem.variant,
        variantCode: Number(elem.variantCode),
        variantSku: elem.variantSku,
        adjustQty: Number(elem.adjustQty),
        adjustCost: Number(elem.adjustCost),
        adjustTotalCost: convtPrice(
          Number(elem.adjustQty) * Number(elem.adjustCost)
        ),
        serialized: elem.serialized,
        batchTracked: elem.batchTracked,
        serialNumbers: elem.serialNumbers,
        batchNumbers: batchNumbersCollected,
        consumeSerialNumbers: elem.consumeSerialNumbers,
        consumeBatchNumbers: elem.consumeBatchNumbers,
      });
    });

    productsSubmitedValues.adjustProducts = adjustProductItems;
    productsSubmitedValues.locationId = adjustLocation.id;
    productsSubmitedValues.type = adjustType.value;
    productsSubmitedValues.notes = adjustNotes;
    productsSubmitedValues.reasonId = adjustReason ? adjustReason.id : "";
    productsSubmitedValues.projectId = adjustProject ? adjustProject.id : "";

    productsSubmitedValues.issueDate = issueDate ? Math.floor(issueDate.getTime() / 1000) : "";
    productsSubmitedValues.refNumber = refNumber;

    productsSubmitedValues.syncXero = syncXero;

    dispatch(bulkStockAdjustRequest(productsSubmitedValues));
  };

  const addNewProjectSuccess = (newPro) => {
    let optionCopy = { ...options };
    let projectCopy = optionCopy.projects;
    projectCopy.unshift(newPro);
    setOptions(optionCopy);
    setAdjustProject(newPro);
    setNewProjectModal(false);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Modal
        title="New Project"
        isModalOpen={newProjectModal}
        closeModal={() => setNewProjectModal(false)}
        content={
          <ProjectForm
            projectId=""
            type="new"
            btnName="Add New Project"
            values={{ name: "", code: "" }}
            onSuccess={addNewProjectSuccess}
          />
        }
      />

      <ContentModal
        title="Scan Barcode"
        isModalOpen={scanModal}
        closeOnOverlayClick={false}
        closeModal={() => setScanModal(false)}
        content={
          <ProductScanForm
            locationId={adjustLocation ? adjustLocation.id : ""}
            completeScan={getScanedProducts}
          />
        }
      />

      <ProceedStockAdjustDialog
        title={"Stock Adjustment"}
        successMessage="Stock adjusted successfully."
        isDialogOpen={createDialog}
        closeDialog={() => setCreateDialog(false)}
        closeDialogWithSuccess={closeDialogWithSuccess}
        loading={saveLoading}
        loadingMessage="Processing request..."
        success={saveSuccess}
        error={saveError}
        confirmAction={saveStockAdjustment}
        adjustType={adjustType ? adjustType.value : ""}
        hasXeroManagedProducts={hasXeroManagedProducts}

      />

      <Breadcrumbs screenName="New Stock Adjustment" />
      <ScreenTitle title="New Stock Adjustment" />

      <div className={commonStyle.pageContentContainer}>
        <XeroAccountMappingTip pageName="stockAdjust" />
        {requestStatus.loading ? (
          <Loader mess="Requesting locations..." />
        ) : requestStatus.success ? (
          <div className="w-full shadow-cardShadow p-4">
          <SubTitle name="Order Basic Information" />
            <div className="w-full grid grid-cols-3 gap-6">
              <StyledSelect
                label="Location*"
                selectOptions={options.locations}
                clearable={false}
                value={adjustLocation}
                placeHolder="Select location"
                onChange={(location) => handleLocationChange(location)}
                error={formErrors.location}
              />        
              <StyledSelect
                label="Adjust Type*"
                selectOptions={adjustTypeOptions}
                value={adjustType}
                placeHolder="Select adjust type"
                clearable={false}
                onChange={(adjustType) => setAdjustType(adjustType)}
                error={formErrors.adjustType}
              />
              <StyledSelect
                label="Adjust Reason"
                selectOptions={
                  adjustType && adjustType.value
                    ? options.reasons.filter((x) => x.type === adjustType.value)
                    : options.reasons
                }
                value={adjustReason}
                placeHolder="Select adjust reason"
                clearable={false}
                onChange={(adjustReason) => setAdjustReason(adjustReason)}
                error={formErrors.adjustReason}
              />
            </div>
            <div className="w-full grid grid-cols-3 gap-6">
              <DateInput
                label="Issue Date*"
                disabledPreDate={false}
                value={issueDate}
                onChange={(issueDate) =>
                  setIssueDate(issueDate)
                }
                error={""}
              />
              {options.projects && options.projects.length > 0 ? (
                <>
                  <CreateableSelectField
                    label="Project"
                    extractKey="id"
                    options={options.projects}
                    name={"year"}
                    value={adjustProject}
                    onChange={(project) => setAdjustProject(project)}
                    placeholder="Select project"
                    autoFocus={false}
                    readOnly={false}
                    clearable={true}
                    noResultText="No options"
                    addBtnName="Add a new project"
                    openAddNewModal={() => setNewProjectModal(true)}
                    error={formErrors.project}
                  />
                </>
              ) : (
                <></>
              )}
               <StyledInput
                label="Reference"
                type="text"
                name="reference"
                value={refNumber}
                onChange={(ref) => setRefNumber(ref)}
              />
            </div>
            <div style={{ width: "100%" }}>
              <StyledInput
                label="Notes"
                type="text"
                name="notes"
                value={adjustNotes}
                onChange={(notes) => setAdjustNotes(notes)}
              />
            </div>
          </div>
        ) : requestStatus.error ? (
          <ErrorMessage mess={requestStatus.error} />
        ) : (
          <></>
        )}

        <div className="mt-8 shadow-cardShadow p-4">
          <SubTitle name="Adjust Products" />

          <div
            style={{
              marginTop: "8px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "50%" }}>
              <ProductPicker
                priceTierId=""
                onSelect={getProductFromPicker}
                requiredType="productAndService"
                locationRequired={true}
                locationId={adjustLocation ? adjustLocation.id : ""}
                showCreateBtn={true}
              />
            </div>
            <BarcodeScanButton name="Scan Barcode" action={openScanModal} />
          </div>

          <div className={commonStyle.longDataTable}>
            {formErrors.repeatProduct ? (
              <div style={{ marginTop: "20px" }}>
                <FadeErrorMessage mess={formErrors.repeatProduct} />
              </div>
            ) : (
              <></>
            )}
            {formErrors.adjustProducts ? (
              <div style={{ marginTop: "20px" }}>
                <TextErrorMessage mess={formErrors.adjustProducts} />
              </div>
            ) : (
              <></>
            )}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Product Name</th>
                  <th>Unit</th>
                  <th>Stock on Hand</th>
                  <th>Adjust Quantity</th>
                  <th>Adjust Cost</th>
                  <th></th>
                  <th
                    style={{
                      margin: "auto",
                      borderLeft: "1px solid var(--border-color)",
                    }}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {adjustProducts.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {item.variantSku && Number(item.famid) > 0 && (
                        <ProductCodeLinkToNewTab productId={item.famid} name={item.variantSku} />
                      )}
                    </td>
                    <td>{item.productName}</td>
                    <td>{item.unitName}</td>
                    <td>{item.stockLevel}</td>
                    <td style={{ width: "15%" }}>
                      <div className={inventoryStyle.adjustQtyCon}>
                        {adjustType ? (
                          adjustType.value === "STOCKDEDUCTION" ? (
                            <AiOutlineMinus />
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                        <NoBorderInput
                          type="text"
                          name="adjustQty"
                          value={item.adjustQty}
                          onChange={(adjustQty) =>
                            handleChange({
                              target: {
                                type: "quantity",
                                value: adjustQty,
                                name: "adjustQty",
                                index: index,
                              },
                            })
                          }
                        />
                      </div>
                    </td>
                    <td style={{ width: "10%" }}>
                      {" "}
                      <NoBorderInput
                        type="text"
                        name="adjustCost"
                        value={item.adjustCost}
                        onChange={(adjustCost) =>
                          handleChange({
                            target: {
                              type: "price",
                              value: adjustCost,
                              name: "adjustCost",
                              index: index,
                            },
                          })
                        }
                      />
                    </td>
                    <td>
                      {adjustType && item.adjustQty ? (
                        item.serialized === 1 ? (
                          adjustType.value === "RECEIVESTOCK" ? (
                            <SerialNumberCollect
                              btnName="Add Serial Number"
                              prodName={item.productName}
                              requiredQty={item.adjustQty}
                              onSave={getSerialNumbersStockReveive}
                              index={index}
                              initialNumbers={
                                item.serialNumbers ? item.serialNumbers : []
                              }
                            />
                          ) : adjustType.value === "STOCKDEDUCTION" ? (
                            <SerialNumberSelect
                              btnName="Select Serial Number"
                              famid={item.famid}
                              variantCode={item.variantCode}
                              locationId={adjustLocation.id}
                              prodName={item.productName}
                              requiredQty={item.adjustQty}
                              index={index}
                              onSave={getSerialNumbersStockReduce}
                              initialNumbers={
                                item.consumeSerialNumbers
                                  ? item.consumeSerialNumbers
                                  : []
                              }
                            />
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}

                      {adjustType && item.adjustQty ? (
                        item.batchTracked === 1 ? (
                          adjustType.value === "RECEIVESTOCK" ? (
                            <BatchNumberCollect
                              btnName="Add Batch"
                              prodName={item.productName}
                              requiredQty={item.adjustQty}
                              onSave={getBatchNumbersStockReveive}
                              index={index}
                              initialNumbers={
                                item.batchNumbers ? item.batchNumbers : []
                              }
                            />
                          ) : adjustType.value === "STOCKDEDUCTION" ? (
                            <BatchNumberSelect
                              btnName="Select Batch"
                              prodName={item.productName}
                              famid={item.famid}
                              variantCode={item.variantCode}
                              locationId={adjustLocation.id}
                              requiredQty={item.adjustQty}
                              index={index}
                              onSave={getBatchNumbersStockReduce}
                              initialNumbers={
                                item.consumeBatchNumbers
                                  ? item.consumeBatchNumbers
                                  : []
                              }
                            />
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                    </td>
                    <td
                      style={{
                        borderLeft: "1px solid var(--border-color)",
                      }}
                    >
                      <XDeleteButton action={() => deleteVariantItem(index)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="w-full mt-4">
              <SimpleBlueButton action={checkValidation} name="Save" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
