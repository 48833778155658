import React, { useState } from "react";
import { AiFillEdit, AiOutlineDelete } from "react-icons/ai";
import { IoIosMore } from "react-icons/io";
import {
  MdFileCopy,
  MdOutlineCheckCircle
} from "react-icons/md";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  updateQuoteStatusRequest
} from "../../../api/quoteServices";
import {
  CloneButtonLink,
  EditButtonLink,
  ViewButtonLink
} from "../../../components/editComponents";
import {
  Modal,
  SimpleDialog
} from "../../../components/viewComponents";
import CreateSaleOrderFromQuoteForm from "./CreateSaleOrderFromQuoteForm";

function EditLink({ quoteId, buttonView = false, marginRight = false }) {
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  return userDetailsSuccess &&
    userDetails.data.permissions.includes("manage_quotes") ? (
    <>
      {buttonView ? (
        <NavLink
          to={`/sale/quote/edit/${quoteId}`}
          className={`h-11 flex flex-row items-center justify-center py-2 px-3 text-brandGreen rounded border border-solid border-brandGreen hover:bg-brandGreen hover:text-white ${
            marginRight ? "mr-2" : ""
          }`}
        >
          <AiFillEdit className="w-4 h-4 mr-2" /> Edit
        </NavLink>
      ) : (
        <EditButtonLink path={`/sale/quote/edit/${quoteId}`} />
      )}
    </>
  ) : (
    <></>
  );
}

function CloneLink({ quoteId, buttonView = false, marginRight = false }) {
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  return userDetailsSuccess &&
    userDetails.data.permissions.includes("manage_quotes") ? (
    <>
      {buttonView ? (
        <NavLink
          to={`/sale/quote/new`}
          state={quoteId}
          className={`h-11 flex flex-row items-center justify-center py-2 px-3 text-brandYellow rounded border border-solid border-brandYellow hover:bg-brandYellow hover:text-white ${
            marginRight ? "mr-2" : ""
          }`}
        >
          <MdFileCopy className="w-4 h-4 mr-2" /> Clone
        </NavLink>
      ) : (
        <CloneButtonLink path={`/sale/quote/new`} data={quoteId} />
      )}
    </>
  ) : (
    <></>
  );
}

function PreviewLink({ quoteId, buttonView = false, marginRight = false }) {
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  return userDetailsSuccess &&
    userDetails.data.permissions.includes("manage_quotes") ? (
    <ViewButtonLink path={`/sale/quote/preview/${quoteId}`} />
  ) : (
    <></>
  );
}

function CreateSaleOrderButton({
  quoteId,
  quoteNumber,
  actionSuccess,
  marginRight = false,
}) {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  const [createModal, setCreateModal] = useState(false);
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  const [actionStatus, setActionStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const sendUpdateStatusRequest = async (newStatusValue) => {
    try {
      setActionStatus({ loading: true, success: false, error: "" });
      let update_values = {
        newStatus: newStatusValue,
      };
      const responseData = await updateQuoteStatusRequest(
        quoteId,
        update_values,
        config
      );
      if (responseData.data.success) {
        actionSuccess();
        setActionStatus({ loading: false, success: true, error: "" });
      } else {
        throw "Update quote failed.";
      }
    } catch (error) {
      setActionStatus({ loading: false, success: false, error: error });
    }
  };

  const openCreateModal = () => {
    setCreateModal(true);
  };

  const createSaleOrderAction = (markAsCompleted) => {
    // update quote status to "Completed"
    if(markAsCompleted){
      sendUpdateStatusRequest("Completed")
    }
    setCreateModal(false);
    // create sale order
    navigate("/sale/order/new", { state: {quoteId: quoteId} });
  }

  return (
    <div>
      <Modal
        title="Create Sale Order"
        isModalOpen={createModal}
        closeModal={() => setCreateModal(false)}
        content={
          <CreateSaleOrderFromQuoteForm
            quoteNumber={quoteNumber}
            onSubmit={createSaleOrderAction}
          />
        }
      />

      {userDetailsSuccess &&
      userDetails.data.permissions.includes("manage_quotes") ? (
        <button
          onClick={openCreateModal}
          className={`h-11 flex flex-row items-center justify-center py-2 px-3 text-brandColor rounded border border-solid border-brandColor hover:bg-brandColor hover:text-white ${
            marginRight ? "mr-2" : ""
          }`}
        >
          <MdOutlineCheckCircle className="w-4 h-4 mr-2" /> Create Sale Order
        </button>
      ) : (
        <></>
      )}
    </div>
  );
}

function UpdateStatusButton({
  quoteId,
  actionSuccess,
  marginRight = false,
  btnName,
  btnIcon,
  newStatusValue,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [actionDialog, setActionDialog] = useState(false);
  const [actionStatus, setActionStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const sendUpdateStatusRequest = async () => {
    setActionDialog(true);
    try {
      setActionStatus({ loading: true, success: false, error: "" });
      let update_values = {
        newStatus: newStatusValue,
      };
      const responseData = await updateQuoteStatusRequest(
        quoteId,
        update_values,
        config
      );
      if (responseData.data.success) {
        setActionStatus({ loading: false, success: true, error: "" });
      } else {
        throw "Update quote failed.";
      }
    } catch (error) {
      setActionStatus({ loading: false, success: false, error: error });
    }
  };

  const closeDialogWithSuccess = () => {
    setActionDialog(false);
    actionSuccess();
  };

  return (
    <div>
      <SimpleDialog
        title={"Update Quote"}
        successMessage={`Quote updated to "${newStatusValue}" successfully.`}
        isDialogOpen={actionDialog}
        closeDialog={() => setActionDialog(false)}
        loading={actionStatus.loading}
        loadingMessage="Update quote..."
        success={actionStatus.success}
        error={actionStatus.error}
        confirmAction={closeDialogWithSuccess}
      />

      {userDetailsSuccess &&
      userDetails.data.permissions.includes("manage_quotes") ? (
        <>
          <button
            onClick={sendUpdateStatusRequest}
            className={`h-11 flex flex-row items-center justify-center py-2 px-3 text-brandColor rounded border border-solid border-brandColor hover:bg-brandColor hover:text-white ${
              marginRight ? "mr-2" : ""
            }`}
          >
            {btnIcon} {btnName}
          </button>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

function MoreActionLink({ quoteId, currStatus, actionSuccess }) {
  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [filterOpen, setFilterOpen] = useState(false);
  const [newStatus, setNewStatus] = useState(false);

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [actionDialog, setActionDialog] = useState(false);
  const [actionStatus, setActionStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const sendUpdateStatusRequest = async (newValue) => {
    setActionDialog(true);
    setFilterOpen(false);

    try {
      setActionStatus({ loading: true, success: false, error: "" });
      let update_values = {
        newStatus: newValue,
      };
      const responseData = await updateQuoteStatusRequest(
        quoteId,
        update_values,
        config
      );
      if (responseData.data.success) {
        setNewStatus(newValue);
        setActionStatus({ loading: false, success: true, error: "" });
      } else {
        throw "Update quote failed.";
      }
    } catch (error) {
      setActionStatus({ loading: false, success: false, error: error });
    }
  };

  const closeDialogWithSuccess = () => {
    setActionDialog(false);
    actionSuccess();
  };

  const DeleteActionButton = () => {
    return (
      <button
        to={`/sale/quote/edit/${quoteId}`}
        onClick={() => sendUpdateStatusRequest("Deleted")}
        className={`w-full h-12 flex flex-row items-center justify-start hover:underline text-red-500`}
      >
        <AiOutlineDelete className="w-4 h-4 mr-2" /> Delete
      </button>
    );
  };

  const EditActionButton = () => {
    return (
      <NavLink
        to={`/sale/quote/edit/${quoteId}`}
        className={`w-full h-12 flex flex-row items-center justify-start hover:underline text-brandGreen`}
      >
        <AiFillEdit className="w-4 h-4 mr-2" />
        Edit
      </NavLink>
    );
  };

  const DeclinedActionButton = () => {
    return (
      <button
        to={`/sale/quote/edit/${quoteId}`}
        onClick={() => sendUpdateStatusRequest("Declined")}
        className={`w-full h-12 flex flex-row items-center justify-start hover:underline text-red-500`}
      >
        <AiOutlineDelete className="w-4 h-4 mr-2" /> Mark As Declined
      </button>
    );
  };

  return (
    <div className="relative">
      <SimpleDialog
        title={"Update Quote"}
        successMessage={`Quote updated to "${newStatus}" successfully.`}
        isDialogOpen={actionDialog}
        closeDialog={() => setActionDialog(false)}
        loading={actionStatus.loading}
        loadingMessage="Update quote..."
        success={actionStatus.success}
        error={actionStatus.error}
        confirmAction={closeDialogWithSuccess}
      />

      <div
        className="w-[44px] h-[44px] rounded flex items-center justify-center rounded border border-solid border-darkGray hover:bg-brandColor hover:text-white hover:cursor-pointer hover:border-brandColor"
        onClick={() => setFilterOpen(!filterOpen)}
      >
        <IoIosMore className="w-4 h-4" />
      </div>

      <div
        className={`${
          filterOpen ? "absolute block top-14 right-0 z-10 " : "hidden"
        }  min-w-[200px] bg-white shadow-lg p-4 rounded`}
      >
        <div className="w-full">
          {(currStatus === "Draft" || currStatus === "Declined") && (
            <DeleteActionButton />
          )}
          {(currStatus === "Sent" ||
            currStatus === "Accepted" ||
            currStatus === "Completed") && <EditActionButton />}
          {(currStatus === "Sent" ||
            currStatus === "Accepted" ||
            currStatus === "Completed") && <DeclinedActionButton />}
        </div>
      </div>
    </div>
  );
}

export {
  CloneLink, CreateSaleOrderButton, EditLink, MoreActionLink, PreviewLink, UpdateStatusButton
};
