import axios from "axios";
import { authMiddleware } from "./authMiddleware";
import { QUOTE_DETAILS_FAIL, QUOTE_DETAILS_REQUEST, QUOTE_DETAILS_SUCCESS, QUOTE_LIST_FAIL, QUOTE_LIST_REQUEST, QUOTE_LIST_SUCCESS } from "../constants/quoteConstants";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// get purchase order list
export const quoteListRequest =
  (keywords, pageNum, pageSize, range, status = '', location = '', startTime = '', endTime = '') => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
     
      dispatch({ type: QUOTE_LIST_REQUEST });

      axios
        .get(
          SERVER_URL +
            `/quote/list?keyword=${keywords}&page_num=${pageNum}&page_size=${pageSize}&range=${range}&status=${status}&location=${location}&startTime=${startTime}&endTime=${endTime}`,
          config
        )
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: QUOTE_LIST_SUCCESS,
              payload: response.data.data,
            });
          } else if (response.data.error === 6){
            throw Object.assign(
              new Error("Start date cannot be greater than end date"),
              { code: 16051 }
            );
          } else {
            throw Object.assign(
              new Error("Request quote list failed, please try again later."),
              { code: 16052 }
            );
          }
        })
        .catch((err) => {
          dispatch({
            type: QUOTE_LIST_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: QUOTE_LIST_FAIL,
        payload: "Connection error, please try again later",
      });
    }
};

export const quoteDetailsRequest =
  (quoteId) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      
      dispatch({ type: QUOTE_DETAILS_REQUEST });

      axios
        .get(SERVER_URL + `/quote/details/${quoteId}`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: QUOTE_DETAILS_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw Object.assign(
              new Error(
                "Request quote details failed, please try again later."
              ),
              { code: 16053 }
            );
          }
        })
        .catch((err) => {
          dispatch({
            type: QUOTE_DETAILS_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: QUOTE_DETAILS_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };


