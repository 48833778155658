import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { MdKeyboardArrowDown, MdOutlineCheckCircle } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  generateQuoteRequest
} from "../../../api/generatePDFServices";
import {
  quoteOrderActionsRequest,
} from "../../../api/quoteServices";
import { quoteDetailsRequest } from "../../../actions/quoteActions";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { OrderAttachmentsPreview } from "../../../components/attachmentComponents";
import {
  DownloadPDFButton,
  EmailButton,
  ScreenTitle,
} from "../../../components/editComponents";
import {
  ErrorMessage,
  FromTag,
  Loader,
  Modal,
  PriceText,
  ProductCodeLinkToNewTab,
  SubTitle,
  Tag,
  VariantSkuTag,
  ViewDateWithTime,
  ViewDateWithTitle,
  ViewLabel,
  ViewText,
} from "../../../components/viewComponents";
import { convtQuantity } from "../../../functions/functions";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import purchaseStyle from "../../purchase/purchaseStyle.module.css";
import salesStyle from "../salesStyle.module.css";
import QuoteEmailForm from "./QuoteEmailForm";
import {
  UpdateStatusButton,
  CloneLink,
  CreateSaleOrderButton,
  EditLink,
  MoreActionLink,
} from "./quoteActionButtons";

export default function PreviewQuoteScreen() {
  useDocumentTitle("Preview Quote");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id: quoteId } = useParams();

  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: detailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const config = {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  const standard_config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const {
    loading,
    quoteInfo,
    error,
    success,
  } = useSelector((state) => state.quoteDetails);

  const [actions, setActions] = useState([]);
  const [emailModalOpen, setEmaiModalOpen] = useState(false);
  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [actionsStatus, setActionsStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [refreshCnt, setRefreshCnt] = useState(0);
  const [showActions, setShowActions] = useState(false);

  /////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const getQuoteActions = async () => {
      try {
        setActionsStatus({ loading: true, success: false, error: "" });
        const responseData = await quoteOrderActionsRequest(
          quoteId,
          standard_config
        );
        if (responseData.data.success) {
          setActions(responseData.data.data);
          setActionsStatus({ loading: false, success: true, error: "" });
        } else {
          throw "get quote order actions failed.";
        }
      } catch (error) {
        setActionsStatus({ loading: false, success: false, error: error });
      }
    };

    if (quoteId) {
      dispatch(quoteDetailsRequest(quoteId))
      getQuoteActions(quoteId);
    }
  }, [quoteId, refreshCnt]);

  /////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////
  const downloadQuoteAction = async () => {
    try {
      setReqStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await generateQuoteRequest(quoteId, config);
      if (responseData.status === 200) {
        // Create a Blob from the response data
        const blob = new Blob([responseData.data]);
        saveAs(
          blob,
          `Quote_${quoteInfo.basicInfo.orderNumber}.pdf`
        );

        setReqStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };


  const sendEmailAction = () => {
    setEmaiModalOpen(true);
  };
  ////////////////////////////////////////////////////////////////////////////////////////

  const actionSuccessAction = () => {
    setRefreshCnt(refreshCnt + 1);
  };
  const emailSendSuccess = () => {
    setRefreshCnt(refreshCnt + 1);
    setEmaiModalOpen(false);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Modal
        title="Send Email"
        isModalOpen={emailModalOpen}
        closeModal={() => setEmaiModalOpen(false)}
        content={
          <QuoteEmailForm
            quoteId={quoteId}
            customerEmail={success ? quoteInfo.basicInfo.email : ""}
            orderInfo={quoteInfo}
            closeModal={emailSendSuccess}
          />
        }
      />

      <Breadcrumbs screenName={"Preview Quote"} />
      <ScreenTitle title={"Preview Quote"} />
      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting quote details, please wait a moment..." />
        ) : success ? (
          <div>
            <div className={purchaseStyle.orderNumberRow}>
              <div className={purchaseStyle.orderNumberCon}>
                <div>
                  <ViewText title="Quote No." />
                  <p> {quoteInfo.basicInfo.orderNumber}</p>
                </div>
                {quoteInfo.basicInfo.status === "Draft" ? (
                  <Tag name={quoteInfo.basicInfo.status} color="yellow" />
                ) : quoteInfo.basicInfo.status === "Sent" ? (
                  <Tag name={quoteInfo.basicInfo.status} color="brandBlue" />
                ) : quoteInfo.basicInfo.status === "Accepted" ? (
                  <Tag name={quoteInfo.basicInfo.status} color="green" />
                ) : quoteInfo.basicInfo.status === "Completed" ? (
                  <Tag name={quoteInfo.basicInfo.status} color="blue" />
                ) : quoteInfo.basicInfo.status === "Deleted" ? (
                  <Tag name={quoteInfo.basicInfo.status} color="gray" />
                ) : quoteInfo.basicInfo.status === "Declined" ? (
                  <Tag name={quoteInfo.basicInfo.status} color="red" />
                ) : (
                  <></>
                )}
              </div>
              <div>
                <div className={commonStyle.actionsGrop}>
                  <CloneLink
                    quoteId={quoteId}
                    buttonView={true}
                    marginRight={true}
                  />
                  {quoteInfo.basicInfo.status === "Draft" && (
                    <>
                      <EditLink
                        quoteId={quoteId}
                        buttonView={true}
                        marginRight={true}
                      />
                      <UpdateStatusButton
                        quoteId={quoteId}
                        actionSuccess={actionSuccessAction}
                        marginRight={true}
                        btnName={"Mark as Sent"}
                        btnIcon={
                          <MdOutlineCheckCircle className="w-4 h-4 mr-2" />
                        }
                        newStatusValue={"Sent"}
                      />
                    </>
                  )}
                  {quoteInfo.basicInfo.status === "Sent" && (
                    <>
                      <UpdateStatusButton
                        quoteId={quoteId}
                        actionSuccess={actionSuccessAction}
                        marginRight={true}
                        btnName={"Mark as Accepted"}
                        btnIcon={
                          <MdOutlineCheckCircle className="w-4 h-4 mr-2" />
                        }
                        newStatusValue={"Accepted"}
                      />
                    </>
                  )}
                  {quoteInfo.basicInfo.status === "Accepted" && (
                    <>
                    <CreateSaleOrderButton 
                        quoteId={quoteId}
                        quoteNumber={quoteInfo.basicInfo.orderNumber}
                        actionSuccess={actionSuccessAction}
                        marginRight={true}
                      />
                    </>
                  )}
                  {quoteInfo.basicInfo.status !== "Deleted" && (
                    <MoreActionLink
                       quoteId={quoteId}
                       currStatus={quoteInfo.basicInfo.status}
                       actionSuccess={actionSuccessAction}
                     />
                  )}


              
                </div>
              </div>
            </div>

            {/*  order infomation */}
            <div className="mt-4">
              <SubTitle name="Order Information" />
              <div className={purchaseStyle.orderInfoWrapper}>
                <div className={purchaseStyle.basicInfoWrapper}>
                  <div className={commonStyle.col1}>
                    <ViewDateWithTitle
                      title="Issue Date"
                      value={quoteInfo.basicInfo.issueDate}
                    />

                    <ViewText
                      title="Customer"
                      value={quoteInfo.basicInfo.customerName}
                    />

                    <ViewText
                      title="Customer Contact"
                      value={
                        quoteInfo.basicInfo.firstName +
                        " " +
                        quoteInfo.basicInfo.lastName
                      }
                    />
                    <ViewText title="Email" value={quoteInfo.basicInfo.email} />
                    <ViewText title="Phone" value={quoteInfo.basicInfo.phone} />

                    <ViewLabel title="Billing Address" />
                    {quoteInfo.basicInfo.billingAddress.country ? (
                      <p>{quoteInfo.basicInfo.billingAddress.country.value}</p>
                    ) : (
                      <></>
                    )}
                    {quoteInfo.basicInfo.billingAddress.region ? (
                      <p>{quoteInfo.basicInfo.billingAddress.region}</p>
                    ) : (
                      <></>
                    )}
                    {quoteInfo.basicInfo.billingAddress.city ? (
                      <p>{quoteInfo.basicInfo.billingAddress.city}</p>
                    ) : (
                      <></>
                    )}
                    {quoteInfo.basicInfo.billingAddress.addressLine ? (
                      <p>{quoteInfo.basicInfo.billingAddress.addressLine}</p>
                    ) : (
                      <></>
                    )}
                    {quoteInfo.basicInfo.billingAddress.postalCode ? (
                      <p>{quoteInfo.basicInfo.billingAddress.postalCode}</p>
                    ) : (
                      <></>
                    )}

                    <ViewText
                      title="Salesperson"
                      value={
                        quoteInfo.basicInfo.salespersonName
                          ? quoteInfo.basicInfo.salespersonName
                          : "Null"
                      }
                    />
                  </div>
                  <div className={commonStyle.col2}>
                    <ViewDateWithTitle
                      title="Expire Date"
                      value={quoteInfo.basicInfo.expireDate}
                    />
                    <ViewText
                      title="Location"
                      value={quoteInfo.basicInfo.locationName}
                    />
                    {quoteInfo.basicInfo.priceTierName && (
                      <ViewText
                        title="Price Tier"
                        value={quoteInfo.basicInfo.priceTierName}
                      />
                    )}

                    <ViewText
                      title="Tax Rate"
                      value={quoteInfo.basicInfo.taxrateName}
                    />
                    <ViewText
                      title="Currecy"
                      value={quoteInfo.basicInfo.currency}
                    />

                    {quoteInfo.basicInfo.projectName && (
                      <ViewText
                        title="Project"
                        value={
                          quoteInfo.basicInfo.projectCode +
                          "-" +
                          quoteInfo.basicInfo.projectName
                        }
                      />
                    )}
                    <ViewText title="Notes" value={quoteInfo.basicInfo.notes} />
                  </div>
                </div>

                {quoteInfo.basicInfo.status !== "Deleted" && (
                  <div className={purchaseStyle.invoiceConPreview}>
                    <DownloadPDFButton
                      name="Download PDF"
                      action={downloadQuoteAction}
                    />
                    <EmailButton name="Send Email" action={sendEmailAction} />
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-row items-end justify-between mt-4">
              <SubTitle name="Quote Products" />
              <p className="text-gray ">
                {quoteInfo.basicInfo.taxIncluded
                  ? "* Amounts are tax inclusive"
                  : "* Amounts are tax exclusive"}
              </p>
            </div>

            <div className={commonStyle.longDataTable}>
              <table className={commonStyle.pageTable}>
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Product Name</th>
                    <th>Unit</th>
                    <th>Unit Price</th>
                    <th>Discount</th>
                    <th>Net Price</th>
                    <th>Quantity</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {quoteInfo.orderItems.map((item, index1) => {
                    return (
                      <tr key={index1}>
                        <td>
                          {item.variantSku && Number(item.famid) > 0 && (
                            <ProductCodeLinkToNewTab productId={item.famid} name={item.variantSku} />
                          )}
                        </td>
                        <td>
                          {item.type === "package" ? (
                            <div className={salesStyle.productPackageName}>
                              {item.packageName}
                            </div>
                          ) : (
                            <div style={{ display: "flex" }}>
                              {item.type === "packageProduct" ? (
                                <div className={salesStyle.packageProductTag}>
                                  Package
                                </div>
                              ) : (
                                <></>
                              )}
                              {item.productName}
                            </div>
                          )}
                        </td>

                        <td>{item.unitName ? item.unitName : ""}</td>
                        <td
                          style={{
                            width: "10%",
                          }}
                        >
                          {item.type !== "packageProduct" ? (
                            <PriceText num={item.unitPrice} />
                          ) : (
                            <></>
                          )}
                        </td>
                        <td
                          style={{
                            width: "10%",
                          }}
                        >
                          {item.type === "packageProduct" ? (
                            <></>
                          ) : (
                            <>
                              {item.discount}% <FromTag name="OFF" />
                            </>
                          )}
                        </td>
                        <td>
                          {item.type === "packageProduct" ? (
                            <></>
                          ) : (
                            <>
                              {item.discount ? (
                                <PriceText
                                  num={
                                    (1 - Number(item.discount) / 100) *
                                    Number(item.unitPrice)
                                  }
                                />
                              ) : (
                                <PriceText num={item.unitPrice} />
                              )}
                            </>
                          )}
                        </td>
                        <td>{convtQuantity(item.quantity)}</td>

                        <td>
                          {item.type !== "packageProduct" ? (
                            <PriceText num={item.totalPrice} />
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="7" className={commonStyle.tableFootName}>
                      Total
                    </td>

                    <td>
                      <PriceText num={quoteInfo.orderSummary.productsTotal} />
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className="mt-8">
              <div className={purchaseStyle.summaryWrapper}>
                <div className={purchaseStyle.costCon}>
                  {/* additional cost */}
                  <SubTitle name="Additional Charge" />
                  <table className={commonStyle.pageTable}>
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Price</th>
                      </tr>
                    </thead>

                    <tbody>
                      {quoteInfo.orderCosts.map((c, index2) => {
                        return (
                          <tr key={index2}>
                            <td
                              className={purchaseStyle.productNameCol}
                              style={{
                                width: "60%",
                              }}
                            >
                              {c.costName}
                            </td>

                            <td
                              style={{
                                width: "36%",
                              }}
                            >
                              <PriceText num={c.cost} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className={commonStyle.tableFootName}>Total</td>

                        <td>
                          <PriceText
                            num={quoteInfo.orderSummary.additionalCostTotal}
                          />
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  <div className="mt-4">
                    <OrderAttachmentsPreview files={quoteInfo.orderFiles} />
                  </div>
                </div>

                <div className={purchaseStyle.summaryCon}>
                  {/* summary total price */}
                  <SubTitle name="Order Summary" />
                  <table
                    className={commonStyle.summaryTable}
                    style={{ marginTop: "12px" }}
                  >
                    {quoteInfo.basicInfo.taxIncluded ? (
                      <tbody>
                        <tr>
                          <td className={commonStyle.tableFootName}>
                            Products Total
                          </td>

                          <td style={{ width: "30%" }}>
                            <PriceText
                              num={quoteInfo.orderSummary.productsTotal}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className={commonStyle.tableFootName}>
                            Additional Charge Total
                          </td>
                          <td style={{ width: "30%" }}>
                            <PriceText
                              num={quoteInfo.orderSummary.additionalCostTotal}
                            />
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.summaryTableTotalName}>
                            Order Total{" "}
                            <span className={commonStyle.taxText}>
                              Tax Incl.
                            </span>
                          </td>

                          <td className={commonStyle.summaryTableTotalValue}>
                            <PriceText
                              num={quoteInfo.basicInfo.orderTotalInclTax}
                            />
                            {quoteInfo.basicInfo.currency}
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.tableFootName}>
                            Total{" "}
                            <span className={commonStyle.taxText}>
                              Tax Excl.
                            </span>
                          </td>

                          <td style={{ width: "30%" }}>
                            <PriceText
                              num={quoteInfo.basicInfo.orderTotalExclTax}
                            />
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.tableFootName}>
                            Tax
                            <span className={commonStyle.taxText}>
                              {quoteInfo.taxRate
                                ? "-" + quoteInfo.taxRate.value
                                : ""}
                            </span>
                          </td>

                          <td style={{ width: "30%" }}>
                            <PriceText
                              num={quoteInfo.basicInfo.orderTotalTax}
                            />
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td className={commonStyle.tableFootName}>
                            Products Total
                          </td>

                          <td style={{ width: "30%" }}>
                            <PriceText
                              num={quoteInfo.orderSummary.productsTotal}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className={commonStyle.tableFootName}>
                            Additional Charge Total
                          </td>
                          <td style={{ width: "30%" }}>
                            <PriceText
                              num={quoteInfo.orderSummary.additionalCostTotal}
                            />
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.tableFootName}>
                            Total{" "}
                            <span className={commonStyle.taxText}>
                              Tax Excl.
                            </span>
                          </td>

                          <td style={{ width: "30%" }}>
                            <PriceText
                              num={quoteInfo.basicInfo.orderTotalExclTax}
                            />
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.tableFootName}>
                            Tax
                            <span className={commonStyle.taxText}>
                              {quoteInfo.taxRate
                                ? "-" + quoteInfo.basicInfo.taxRate.value
                                : ""}
                            </span>
                          </td>

                          <td style={{ width: "30%" }}>
                            <PriceText
                              num={quoteInfo.basicInfo.orderTotalTax}
                            />
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.summaryTableTotalName}>
                            Order Total{" "}
                            <span className={commonStyle.taxText}>
                              Tax Incl.
                            </span>
                          </td>

                          <td className={commonStyle.summaryTableTotalValue}>
                            <PriceText
                              num={quoteInfo.basicInfo.orderTotalInclTax}
                            />
                            {quoteInfo.basicInfo.currency}
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
            {/*  order actions */}
            <div className="mb-20 mt-4">
              <div
                className="w-full flex flex-row items-center justify-between h-12 bg-lightbg p-4 border-borderGray border border-solid hover:cursor-pointer"
                onClick={() => setShowActions(!showActions)}
              >
                <p className="font-medium">Order History</p>
                <MdKeyboardArrowDown
                  className={`w-5 h-5 transition-transform duration-300 ${
                    showActions ? "rotate-180" : "rotate-0"
                  }`}
                />
              </div>
              {showActions && actionsStatus.success && (
                <div className="w-full border-borderGray border border-solid px-4">
                  <table className={commonStyle.logsTable}>
                    <thead>
                      <tr>
                        {/* <th></th> */}
                        <th>Time</th>
                        <th>Action</th>
                        <th>Detail</th>
                        <th>User</th>
                      </tr>
                    </thead>
                    <tbody>
                      {actions.map((item, index3) => (
                        <tr key={index3}>
                          <td>
                            <ViewDateWithTime value={item.atime} />
                          </td>
                          <td>{item.action}</td>
                          <td className="w-1/2">{item.notes}</td>
                          <td>
                            {item.firstName}{" "}
                            {item.lastName ? item.lastName : ""}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
