import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import commonStyle from "../../../style/commonStyle.module.css";
import { ScreenTitle } from "../../../components/editComponents";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { ErrorMessage, Loader } from "../../../components/viewComponents";
import { SimpleDialog } from "../../../components/viewComponents";
import {
  purchaseOrderOptionsRequest,
  createNewPurchaseOrderRequest,
  purchaseOrderDetailsRequest,
} from "../../../actions/purchaseOrderActions";
import PurchaseOrderForm from "./PurchaseOrderForm";
import useDocumentTitle from "../../../useDocumentTitle";
import {convtPrice, generateOrderNumber } from "../../../functions/functions";
import { saleOrderItemsForPurchaseRequest } from "../../../api/saleOrderServices";

export default function NewPurchaseOrderScreen() {
  useDocumentTitle("New Purchase Order");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );
  const { loading, purchaseOrderOptions, error, success } = useSelector(
    (state) => state.purchaseOrderOptions
  );

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  // from clone
  const cloneOrderId = location.state ? location.state.orderId : "";

  // from sale order
  const saleOrderId = location.state ? location.state.saleOrderId : "";

  // clone product
  const [cloneDialog, setCloneDialog] = useState(false);
  // clone products from sale order
  const [cloneSaleDialog, setCloneSaleDialog] = useState(false);

  // add new po dialog
  const [createDialog, setCreateDialog] = useState(false);

  const [saleItemsStatus, setSaleItemStatus] = useState({
    loading: false,
    success: false,
    error: ""
  })

  const [cloneFromSaleOrderItems, setCloneFromSaleOrderItems] = useState([]);
  const [cloneFromSaleOrderSummary, setCloneFromSaleOrderSummary] = useState({
    productsTotal: 0,
    additionalCostTotal: 0,
  });

  useEffect(() => {
    dispatch(purchaseOrderOptionsRequest());
    if (cloneOrderId) {
      setCloneDialog(true);
      dispatch(purchaseOrderDetailsRequest(cloneOrderId, "clone"));
    }
  }, [dispatch, cloneOrderId]);

  useEffect(() => {
    const getSaleOrderItems = async () => {
      try {
        setSaleItemStatus({loading: true, success: false, error: ""});
        const responseData = await saleOrderItemsForPurchaseRequest(saleOrderId, config);
        if (responseData.data.success) {
          let so_items = responseData.data.data;
          let new_po_items = [];
          let products_total = 0;
          for(const ele of so_items){
            new_po_items.push({
              discount: "",
              famid: ele.familyId,
              productName: ele.productName,
              productType: ele.productType,
              quantity: ele.quantity,
              sku: ele.sku,
              totalCost: ele.totalCost,
              unitCost: ele.latestCost,
              unitLatestCost: ele.latestCost,
              unitName: ele.unitName,
              variant: "",
              variantCode: ele.variantCode,
              variantSku: ele.variantSku,
            })
            products_total = products_total + Number(ele.totalCost);
            products_total = Number(products_total);
            products_total = products_total.toFixed(2);
            products_total = Number(products_total);
          }

          setCloneFromSaleOrderItems(new_po_items);
          setCloneFromSaleOrderSummary({
            productsTotal: products_total,
            additionalCostTotal: 0,
          });
          setSaleItemStatus({loading: false, success: true, error: ""});
        } else {
          throw "get sale order items failed";
        }
      } catch (error) {
        setSaleItemStatus({loading: false, success: false, error: error});
      }
    };

    if(saleOrderId){
      setCloneSaleDialog(true);
      getSaleOrderItems();
    }

  }, [saleOrderId]);


  const {
    loading: cloneLoading,
    orderInfo,
    error: cloneError,
    success: cloneSuccess,
  } = useSelector((state) => state.purchaseOrderDetails);


  const {
    loading: createLoading,
    orderId,
    error: createError,
    success: createSuccess,
  } = useSelector((state) => state.createPurchaseOrder);

  const newOrderInfo = () => {
    let order_num = "";
    let order_pattern = purchaseOrderOptions.poSettings.find((x) => x.settingName === "purchase_order_number_rule");
    order_num = order_pattern ? generateOrderNumber(order_pattern.settingValue) : "";

    let tax_included = false;
    let tax_rule = purchaseOrderOptions.poSettings.find((x) => x.settingName === "purchase_order_default_tax_rule");
    if(tax_rule){
      tax_included = tax_rule.settingValue === "inclusive" ? true : false;
    }

    let data = {
      issueDate: new Date(),
      orderNumber: order_num,
      supplier: "",
      taxRate: "",
      currency: "",
      supplierName: "",
      supplierBillingName: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      postalAddress: "",
      billingAddress: "",
      taxIncluded: tax_included,
      expectedDate: "",
      billTerm: 7,
      project: "",
      deliveryAddress:
        purchaseOrderOptions.locationOptions.length > 0
          ? purchaseOrderOptions.locationOptions.filter(
              (x) => x.id === userDetails.data.locationId
            ).length
            ? purchaseOrderOptions.locationOptions.filter(
                (x) => x.id === userDetails.data.locationId
              )[0].address
            : ""
          : "",
      location: purchaseOrderOptions.locationOptions.filter(
        (x) => x.id === userDetails.data.locationId
      )[0],
      notes: "",
    };
    return data;
  };


  const newOrderSummary = () => {
    return {
      productsTotal: 0,
      additionalCostTotal: 0,
    };
  };

  const cloneOrderBasicInfo = () => {
    let order_num = "";
    let order_pattern = purchaseOrderOptions.poSettings.find((x) => x.settingName === "purchase_order_number_rule");
    order_num = order_pattern ? generateOrderNumber(order_pattern.settingValue) : "";

    let data = {
      id: orderInfo.basicInfo.id,
      issueDate: new Date(),
      orderNumber: order_num,
      supplier: purchaseOrderOptions.supplierOptions.filter(
        (x) => x.value === orderInfo.basicInfo.supplier
      )[0],
      supplierName: orderInfo.basicInfo.supplier
      ? orderInfo.basicInfo.supplier
      : "",
      supplierBillingName: orderInfo.basicInfo.supplierBillingName
      ? orderInfo.basicInfo.supplierBillingName
      : "",
      firstName: orderInfo.basicInfo.firstName
        ? orderInfo.basicInfo.firstName
        : "",
      lastName: orderInfo.basicInfo.lastName
        ? orderInfo.basicInfo.lastName
        : "",
      email: orderInfo.basicInfo.email ? orderInfo.basicInfo.email : "",
      phone: orderInfo.basicInfo.phone ? orderInfo.basicInfo.phone : "",
      postalAddress: orderInfo.basicInfo.postalAddress
        ? orderInfo.basicInfo.postalAddress
        : {},
      billingAddress: orderInfo.basicInfo.billingAddress
        ? orderInfo.basicInfo.billingAddress
        : {},
      taxRate: purchaseOrderOptions.taxrateOptions.filter(
        (x) => x.rate === orderInfo.basicInfo.taxrate
      )[0],
      taxIncluded: orderInfo.basicInfo.taxIncluded,
      currency: orderInfo.basicInfo.currency,
      expectedDate: "",
      billTerm: 7,
      project: "",
      deliveryAddress: orderInfo.basicInfo.deliveryAddress
        ? orderInfo.basicInfo.deliveryAddress
        : "",
      location: purchaseOrderOptions.locationOptions.filter(
        (x) => x.value === orderInfo.basicInfo.location
      )[0],
      notes: orderInfo.basicInfo.notes ? orderInfo.basicInfo.notes : "",
    };
    return data;
  };

  ////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////// submit //////////////////////////////////////////////
  const addNewPurchaseOrder = (values, status) => {
    setCreateDialog(true);
    let isApproved = false;
    if (status === "Approved") {
      isApproved = true;
    }

    let orderItemValues = [];

    values.orderItems.forEach((prod) => {
      let costExclTax;
      if (values.orderInfo.taxIncluded) {
        costExclTax = convtPrice(
          prod.unitCost / (1 + values.orderInfo.taxRate.rate / 100)
        );
      } else {
        costExclTax = prod.unitCost;
      }

      let eachItem = {
        famid: prod.famid,
        variantCode: prod.variantCode,
        variantSku: prod.variantSku,
        quantity: prod.quantity,
        cost: prod.unitCost,
        costExclTax: costExclTax,
        discount: prod.discount,
        totalCost: prod.totalCost,
      };

      orderItemValues.push(eachItem);
    });

    let orderTotalExcltax;
    let orderTotalIncltax;
    let orderTax;

    if (values.orderInfo.taxIncluded) {
      orderTotalIncltax =
        Number(values.orderSummary.productsTotal) +
        Number(values.orderSummary.additionalCostTotal);

      orderTotalExcltax = convtPrice(
        (Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)) /
          (1 + values.orderInfo.taxRate.rate / 100)
      );

      orderTax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal) -
          (Number(values.orderSummary.productsTotal) +
            Number(values.orderSummary.additionalCostTotal)) /
            (1 + values.orderInfo.taxRate.rate / 100)
      );
    } else {
      orderTotalExcltax =
        Number(values.orderSummary.productsTotal) +
        Number(values.orderSummary.additionalCostTotal);

      orderTax = convtPrice(
        (Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)) *
          (values.orderInfo.taxRate.rate / 100)
      );

      orderTotalIncltax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal) +
          (Number(values.orderSummary.productsTotal) +
            Number(values.orderSummary.additionalCostTotal)) *
            (values.orderInfo.taxRate.rate / 100)
      );
    }

    values.costs.forEach((c) => {
      let addCostExclTax;
      if (values.orderInfo.taxIncluded) {
        addCostExclTax = convtPrice(
          c.cost / (1 + values.orderInfo.taxRate.rate / 100)
        );
      } else {
        addCostExclTax = c.cost;
      }
      c["costExclTax"] = addCostExclTax;
    });

    if (typeof values.orderInfo.postalAddress === "object") {
      values.orderInfo.postalAddress = JSON.stringify(
        values.orderInfo.postalAddress
      );
    }
    if (typeof values.orderInfo.billingAddress === "object") {
      values.orderInfo.billingAddress = JSON.stringify(
        values.orderInfo.billingAddress
      );
    }

    let purchaseOrderValues = {
      approved: isApproved,
      status: status,
      orderInfo: {
        issueDate: values.orderInfo.issueDate
          ? Math.floor(values.orderInfo.issueDate.getTime() / 1000)
          : "",
        orderNumber: values.orderInfo.orderNumber,
        supplierId: values.orderInfo.supplier.id,
        taxrateId: values.orderInfo.taxRate.id,
        taxrate: values.orderInfo.taxRate.rate,
        taxIncluded: values.orderInfo.taxIncluded,
        currency: values.orderInfo.currency,
        supplierName: values.orderInfo.supplierName,
        supplierBillingName: values.orderInfo.supplierBillingName,
        firstName: values.orderInfo.firstName,
        lastName: values.orderInfo.lastName,
        email: values.orderInfo.email,
        phone: values.orderInfo.phone,
        postalAddress: values.orderInfo.postalAddress,
        billingAddress: values.orderInfo.billingAddress,
        locationId: values.orderInfo.location.id,
        deliveryAddress: values.orderInfo.deliveryAddress,
        project: values.orderInfo.project ? values.orderInfo.project.id : "",
        expectedDate: values.orderInfo.expectedDate
          ? Math.floor(values.orderInfo.expectedDate.getTime() / 1000)
          : "",
        billTerm: values.orderInfo.billTerm,
        notes: values.orderInfo.notes,
        // calcute order total amount
        orderTotalExcltax: orderTotalExcltax,
        orderTotalIncltax: orderTotalIncltax,
        orderTax: orderTax,
      },
      orderItems: orderItemValues,
      costs: values.costs,
      files: values.files,
    };

    dispatch(createNewPurchaseOrderRequest(purchaseOrderValues));
  };

  const closeCreateDialogWithSuccess = () => {
    navigate(`/purchase/order/preview/${orderId}`, {
      replace: true,
    });
  };

  return (
    <div className={commonStyle.pageContainer}>
      {cloneOrderId ? (
        <SimpleDialog
          title="Clone Purchase Order"
          successMessage="Purchase order cloning was successful."
          isDialogOpen={cloneDialog}
          closeDialog={() => setCloneDialog(false)}
          loading={cloneLoading}
          loadingMessage="Cloning purchase order details..."
          success={cloneSuccess}
          error={cloneError}
          confirmAction={() => setCloneDialog(false)}
        />
      ) : (
        <></>
      )}
      {saleOrderId ? (
        <SimpleDialog
          title="Clone Products From Sale Order"
          successMessage="Clone order products from sale order successfully."
          isDialogOpen={cloneSaleDialog}
          closeDialog={() => setCloneSaleDialog(false)}
          loading={saleItemsStatus.loading}
          loadingMessage="Cloning order products from sale order ..."
          success={saleItemsStatus.success}
          error={saleItemsStatus.error}
          confirmAction={() => setCloneSaleDialog(false)}
        />
      ) : (
        <></>
      )}

      <SimpleDialog
        title="Create New Purchase Order"
        successMessage="New purchase order added successfully."
        isDialogOpen={createDialog}
        closeDialog={() => setCreateDialog(false)}
        loading={createLoading}
        loadingMessage="Processing request..."
        success={createSuccess}
        error={createError}
        confirmAction={closeCreateDialogWithSuccess}
      />

      <Breadcrumbs screenName="New Purchase Order" />
      <ScreenTitle title="New Purchase Order" status="New" />
      <div className={commonStyle.pageContentContainer}>
        {userDetailsSuccess ? (
          loading ? (
            <Loader mess="Requesting data, please wait a moment..." />
          ) : success ? (
            cloneOrderId ? (
              cloneLoading ? (
                <Loader mess="Cloning purchase order details, please wait a moment..." />
              ) : cloneSuccess ? (
                <PurchaseOrderForm
                  initialOrderInfo={cloneOrderBasicInfo}
                  initialOrderItems={orderInfo.orderItems}
                  initialCosts={orderInfo.orderCosts}
                  initialOrderSummary={orderInfo.orderSummary}
                  initialFiles={[]}
                  submit={addNewPurchaseOrder}
                  type="clone"
                  showDraftBtn={true}
                />
              ) : cloneError ? (
                <ErrorMessage mess={cloneError} />
              ) : (
                <></>
              )
            ) : saleOrderId ? (
              saleItemsStatus.loading ? (
                <Loader mess="Cloning sale order products, please wait a moment..." />
              ) : saleItemsStatus.success ? (
                <PurchaseOrderForm
                  initialOrderInfo={newOrderInfo}
                  initialOrderItems={cloneFromSaleOrderItems}
                  initialOrderSummary={cloneFromSaleOrderSummary}
                  initialCosts={[]}
                  initialFiles={[]}
                  submit={addNewPurchaseOrder}
                  type="new"
                  showDraftBtn={true}
                />
              ) : saleItemsStatus.error ? (
                <ErrorMessage mess={saleItemsStatus.error} />
              ) : (
                <></>
              )
            ) : (
              <PurchaseOrderForm
                initialOrderInfo={newOrderInfo}
                initialOrderItems={[]}
                initialOrderSummary={newOrderSummary}
                initialCosts={[]}
                initialFiles={[]}
                submit={addNewPurchaseOrder}
                type="new"
                showDraftBtn={true}
              />
            )
          ) : (
            <ErrorMessage mess={error} />
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
