import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { OrderAttachments } from "../../../components/attachmentComponents";
import {
  AddItem,
  BarcodeScanButton,
  GreenButton,
  SimpleBlueButton,
  SingleRatioButton,
  XDeleteButton,
} from "../../../components/editComponents";
import {
  CreateableSelectField,
  DateInput,
  NoBorderInput,
  NoBorderPercentInput,
  ReadOnlyField,
  StyledInput,
  StyledSelect,
  StyledTextarea,
} from "../../../components/inputFields";
import ProductPicker from "../../../components/ProductPicker";
import {
  ContactCard,
  ContentModal,
  Loader,
  PriceText,
  SubTitle,
  ProductCodeLinkToNewTab,
} from "../../../components/viewComponents";
import { convtPrice, convtQuantity } from "../../../functions/functions";
import { validatePrice, validateQuantity } from "../../../functions/validate";
import commonStyle from "../../../style/commonStyle.module.css";
import NewCustomerModal from "../../customers/NewCustomerModal";
import purchaseStyle from "../../purchase/purchaseStyle.module.css";
import salesStyle from "../salesStyle.module.css";
import SaleOrderScanForm from "../saleOrder/SaleOrderScanForm";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

/* 
must select location and customer before add product to order
when change location, refrsh the orderitems to check id has sufficient stocfk last
*/

export default function QuoteForm({
  initialOrderInfo,
  initialOrderItems,
  initialOrderSummary,
  initialCosts,
  initialFiles,
  submit,
  type,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  // access check for the actions
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
      "Content-type": "application/json",
    },
  };

  // product values
  const { options, success } = useSelector((state) => state.saleOrderOptions);

  /////////////////////////////initial states//////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  const [customersOptions, setCustomerOptions] = useState([]);
  const [orderInfo, setOrderInfo] = useState(initialOrderInfo);
  const [orderItems, setOrderItems] = useState(initialOrderItems);

  const [addtionalCosts, setAddtionalCosts] = useState(initialCosts);
  const [orderFiles, setOrderFiles] = useState(initialFiles);
  // store the order items total cost and extra cost total. the tax amount and order amount calculate when display
  const [orderSummary, setOrderSummary] = useState(initialOrderSummary);

  const [updateStockLoadingOpen, setUpdateStockLoadingOpen] = useState(false);

  //define the error message
  const [formErrors, setFormErrors] = useState({});
  // scan dialog
  const [scanModal, setScanModal] = useState(false);
  const [newCustomerModal, setNewCustomerModal] = useState(false);

  useEffect(() => {
    if (Object.keys(options).length) {
      let customer_options_copy = [...options.customerOptions];
      setCustomerOptions(customer_options_copy);
    }
  }, [success]);

  /////////////////////////////Handle change functions////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrderInfo({ ...orderInfo, [name]: value });
  };
  const handleCustomerChange = (e) => {
    const { value } = e.target;

    if (value) {
      getCustomerDetails(value);
    } else {
      setOrderInfo({
        ...orderInfo,
        customer: "",
      });
    }
  };

  const getCustomerDetails = (value) => {
    try {
      axios
        .get(SERVER_URL + `/customers/${value.id}`, config)
        .then(async (response) => {
          if (response.data.success) {
            let customerDetails = response.data.data;
            let find_c_tax = options.taxrateOptions.find((x)=>Number(x.id) === Number(customerDetails.taxRateId));
            let find_c_price_tier = options.priceTierOptions.find((x)=>Number(x.id) === Number(customerDetails.priceTierId));
            setOrderInfo({
              ...orderInfo,
              customer: value,
              customerName: customerDetails.name,
              firstName: customerDetails.firstName,
              lastName: customerDetails.lastName,
              email: customerDetails.email,
              phone: customerDetails.phone,
              postalAddress: customerDetails.postalAddress,
              billingAddress: customerDetails.billingAddress,
              taxRate: find_c_tax ? find_c_tax : "",
              currency: customerDetails.currency
                ? customerDetails.currency
                : userDetails.data.orgInfo.base_currency,
              priceTier: find_c_price_tier ? find_c_price_tier : "",
            });
          } else {
            setFormErrors({ customer: "Get customer details failed!" });
          }
        })
        .catch((err) => {
          setFormErrors({ customer: "Get customer details failed!" });
        });
    } catch (error) {
      setFormErrors({ customer: "Get customer details failed!" });
    }
  };

  ////////////////////  //////////handle location change//////////////  //////////////
  ////////////////////  ///////refresh stock level//////////////  //////////////

  const handleLocationChange = (e) => {
    const { name, value } = e.target;
    setOrderInfo({ ...orderInfo, [name]: value });
    if (value) {
      if (orderItems.filter((x) => x.productName).length > 0) {
        updateProductsStock(value.id);
      }
    }
  };

  const updateProductsStock = (locationId) => {
    if (!locationId) {
      setFormErrors({
        orderItemsError: "Please select a location.",
      });
      return;
    }

    const variantsCopy = [...orderItems];
    if (variantsCopy.filter((x) => x.productName || x.packageName).length > 0) {
      setUpdateStockLoadingOpen(true);
      let variantsParams = [];

      variantsCopy.forEach((elem) => {
        if (elem.productName) {
          variantsParams.push({
            famid: elem.famid,
            variantCode: elem.variantCode,
          });
        }
      });

      let variantOptions = encodeURI(JSON.stringify(variantsParams));

      axios
        .get(
          SERVER_URL +
            `/sale_order/variant/details?variantOptions=${variantOptions}&locationId=${locationId}`,
          config
        )
        .then(async (response) => {
          if (response.data.success) {
            setFormErrors({});
            setUpdateStockLoadingOpen(false);
            let varaiantStock = response.data.data;

            variantsCopy.forEach((elem) => {
              if (elem.productName) {
                elem.stockOnHand = varaiantStock.filter(
                  (x) =>
                    x.famid === elem.famid && x.variantCode === elem.variantCode
                )[0].stockOnHand;
              }
            });

            setOrderItems(variantsCopy);
          } else {
            setUpdateStockLoadingOpen(false);
            setFormErrors({
              orderItemsError:
                "Update product stock failed, please try again later.",
            });
          }
        })
        .catch((err) => {
          setUpdateStockLoadingOpen(false);
          setFormErrors({
            orderItemsError: "Connection error, please try again later",
          });
        });
    } else {
      setFormErrors({
        orderItemsError:
          "Please add a product firstly before check stock level.",
      });
    }
  };

  const sumProductsTotal = (items) => {
    let orderSummaryCopy = { ...orderSummary };
    let newProductTotal = items.reduce(
      (preTotal, item) => Number(preTotal) + Number(item.totalPrice),
      0
    );

    orderSummaryCopy.productsTotal = newProductTotal;

    setOrderSummary(orderSummaryCopy);
  };

  const handleOrderItemChange = (e) => {
    const { key, value, index } = e.target;
    const type = e.type;

    // only allow int inout
    if (type === "quantity") {
      const validated = validateQuantity(value);
      if (!validated) return;
    }

    if (type === "price") {
      const validated = validatePrice(value);
      if (!validated) return;
    }

    let newItems = [...orderItems];

    newItems[index][key] = value;

    if (newItems[index].unitPrice && newItems[index].quantity) {
      if (newItems[index].discount) {
        let discountedUnitPrice = convtPrice(
          newItems[index].unitPrice * (1 - newItems[index].discount / 100)
        );
        // has discount value
        newItems[index]["totalPrice"] =
          discountedUnitPrice * Number(newItems[index].quantity);
      } else {
        // no discount value
        newItems[index]["totalPrice"] = convtPrice(
          Number(newItems[index].unitPrice) * Number(newItems[index].quantity)
        );
      }
    } else {
      newItems[index]["totalPrice"] = "";
    }

    // caculate order total price before tax
    sumProductsTotal(newItems);
    // update order items
    setOrderItems(newItems);
  };

  const handleCostChange = (e) => {
    const { key, value, index } = e.target;
    const type = e.type;

    if (type === "price") {
      const validated = validatePrice(value);
      if (!validated) return;
    }

    let costsCopy = [...addtionalCosts];
    costsCopy[index][key] = value;
    setAddtionalCosts(costsCopy);

    // calculate cost total
    let orderSummaryCopy = { ...orderSummary };

    let costTotal = costsCopy.reduce(
      (preTotal, item) => convtPrice(Number(preTotal) + Number(item.cost)),
      0
    );

    orderSummaryCopy.additionalCostTotal = costTotal;
    setOrderSummary(orderSummaryCopy);
  };

  const handlePackageQuantityChange = (e) => {
    const { key, value, index, packageId } = e.target;
    const type = e.type;

    // only allow int inout
    if (type === "quantity") {
      const validated = validateQuantity(value);
      if (!validated) return;
    }
    //
    let newItems = [...orderItems];

    newItems[index][key] = value;

    if (newItems[index].unitPrice && newItems[index].quantity) {
      if (newItems[index].discount) {
        let discountedUnitPrice = convtPrice(
          newItems[index].unitPrice * (1 - newItems[index].discount / 100)
        );
        // has discount value
        newItems[index]["totalPrice"] =
          discountedUnitPrice * Number(newItems[index].quantity);
      } else {
        // no discount value
        newItems[index]["totalPrice"] = convtPrice(
          Number(newItems[index].unitPrice) * Number(newItems[index].quantity)
        );
      }
    } else {
      newItems[index]["totalPrice"] = "";
    }

    for (const item of newItems) {
      if (
        item.type === "packageProduct" &&
        item.packageId === packageId &&
        Number(value) !== 0
      ) {
        item.quantity = Number(item.packageQuantity) * Number(value);
      }
      if (
        item.type === "packageProduct" &&
        item.packageId === packageId &&
        Number(value) === 0
      ) {
        item.quantity = Number(item.packageQuantity);
      }
    }

    // caculate order total price before tax
    sumProductsTotal(newItems);
    // update order items
    setOrderItems(newItems);
  };

  ///////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////

  const addAdditionalCost = () => {
    let costCopy = [...addtionalCosts];
    costCopy.push({
      costName: "",
      cost: "",
    });

    setAddtionalCosts(costCopy);
  };

  const deleteOrderItem = (index, itemType, package_id) => {
    let orderItemsCopy = [...orderItems];
    if (itemType === "package") {
      orderItemsCopy = orderItemsCopy.filter(
        (x) => Number(x.packageId) !== Number(package_id)
      );
    } else {
      orderItemsCopy.splice(index, 1);
    }
    // caculate order total price before tax
    sumProductsTotal(orderItemsCopy);
    setOrderItems(orderItemsCopy);
  };

  const deleteCostItem = (index) => {
    let costCopy = [...addtionalCosts];
    costCopy.splice(index, 1);

    // re calculate cost total
    let orderSummaryCopy = { ...orderSummary };
    let costTotal = costCopy.reduce(
      (preTotal, item) => Number(preTotal) + Number(item.cost),
      0
    );
    orderSummaryCopy.additionalCostTotal = costTotal;

    setAddtionalCosts(costCopy);
    setOrderSummary(orderSummaryCopy);
  };

  ////////////////////  ////////////////////  ////////////////////  ////////////////////
  ////////////////////  ////////////////////  ////////////////////  ////////////////////
  ////////////////////  ////////////////////  ////////////////////  ////////////////////
  const getProductFromPicker = (result) => {
    if (result.productType === "package") {
      addNewProductPackage(result.data);
    } else {
      addNewOrderItem(result.data);
    }
  };

  const addNewOrderItem = (proDetails) => {
    let orderItemsCopy = [...orderItems];

    // remove empty row in order items
    orderItemsCopy = orderItemsCopy.filter(
      (x) => x.productName || x.packageName
    );
    // check exist products, error
    let exists = orderItemsCopy.some(
      (item) =>
        item.famid === proDetails.familyId &&
        item.variantCode === proDetails.variantCode &&
        item.type === proDetails.type
    );

    if (exists) {
      let index = orderItemsCopy.findIndex(
        (item) =>
          item.famid === proDetails.familyId &&
          item.variantCode === proDetails.variantCode
      );
      orderItemsCopy[index].quantity =
        Number(orderItemsCopy[index].quantity) + 1;

      orderItemsCopy[index].totalPrice =
        Number(orderItemsCopy[index].totalPrice) +
        1 * Number(orderItemsCopy[index].unitPrice);
    } else {
      orderItemsCopy.push({
        type: "product",
        productType: proDetails.productType,
        famid: proDetails.familyId,
        sku: proDetails.sku,
        productName: proDetails.name,
        variant: proDetails.variant ? proDetails.variant : "",
        variantCode: proDetails.variantCode,
        variantSku: proDetails.variantSku,
        serialized: proDetails.serialized,
        batchTracked: proDetails.batchTracked,
        consumeSerialNumbers: [],
        consumeBatchNumbers: [],
        unitName: proDetails.unit,
        unitPrice: proDetails.tierPrice
          ? convtPrice(proDetails.tierPrice)
          : convtPrice(proDetails.salePrice),
        stockOnHand: proDetails.stockLevel,
        discount: "",
        quantity: "",
        totalPrice: "",
      });
    }
    sumProductsTotal(orderItemsCopy);
    setOrderItems(orderItemsCopy);
  };

  // get orderitems from scan
  const openScanModal = () => {
    // check if has location selected
    if (!orderInfo.location) {
      setFormErrors({
        orderItemsError: "Please select a location before scan product.",
      });
      return;
    }

    setFormErrors({ orderItemsError: "" });
    setScanModal(true);
  };
  const getScanedProducts = (products) => {
    let orderItemsCopy = [...orderItems];
    // remove empty row in order items
    orderItemsCopy = orderItemsCopy.filter(
      (x) => x.productName || x.packageName
    );

    for (let i = 0; i < products.length; i++) {
      let pro = products[i];

      let exists = orderItemsCopy.some(
        (item) =>
          item.famid === pro.familyId && item.variantCode === pro.variantCode
      );
      if (exists) {
        let index = orderItemsCopy.findIndex(
          (item) =>
            item.famid === pro.familyId && item.variantCode === pro.variantCode
        );
        orderItemsCopy[index].quantity =
          Number(orderItemsCopy[index].quantity) + Number(pro.quantity);
        orderItemsCopy[index].totalPrice =
          Number(orderItemsCopy[index].totalPrice) +
          Number(pro.quantity) * Number(orderItemsCopy[index].unitPrice);
      } else {
        orderItemsCopy.push({
          type: "product",
          productType: pro.productType,
          famid: pro.familyId,
          productName: pro.productName,
          variant: pro.variant,
          variantCode: pro.variantCode,
          variantSku: pro.variantSku,
          serialized: pro.serialized,
          batchTracked: pro.batchTracked,
          consumeSerialNumbers: [],
          consumeBatchNumbers: [],
          sku: pro.sku,
          stockOnHand: pro.stockOnHand,
          discount: "",
          unitName: pro.unitName,
          unitPrice: pro.unitPrice,
          quantity: pro.quantity,
          totalPrice: pro.totalPrice,
        });
      }
    }

    // re calculate order total
    sumProductsTotal(orderItemsCopy);
    setOrderItems(orderItemsCopy);
    setScanModal(false);
  };

  ////////////////////  ////////////////////  ////////////////////  ////////////////////
  ////////////////////  ////////////////////  ////////////////////  ////////////////////
  ////////////////////  ////////////////////  ////////////////////  ////////////////////

  const addNewProductPackage = (packageDetails) => {
    let packageProducts = packageDetails.packageProducts;
    let packageInfo = packageDetails.basicInfo;

    let orderItemsCopy = [...orderItems];

    let exists = orderItemsCopy.some(
      (item) => item.packageId === packageInfo.id
    );

    if (exists) {
      return;
    }

    orderItemsCopy.push({
      productType: "",
      type: "package",
      packageId: packageInfo.id,
      famid: "",
      productName: "",
      packageName: packageInfo.packageName,
      variant: "",
      variantCode: "",
      variantSku: "",
      quantity: 1,
      unitName: "",
      unitPrice: convtPrice(packageInfo.price),
      discount: "",
      totalPrice: 1 * convtPrice(packageInfo.price),
    });

    packageProducts.forEach((elem) => {
      orderItemsCopy.push({
        productType: elem.productType,
        type: "packageProduct",
        packageId: packageInfo.id,
        packageName: packageInfo.packageName,
        famid: elem.famid,
        productName: elem.productName,
        variant: elem.variant,
        variantCode: elem.variantCode,
        variantSku: elem.variantSku,
        serialized: elem.serialized,
        batchTracked: elem.batchTracked,
        consumeSerialNumbers: [],
        consumeBatchNumbers: [],
        stockOnHand: elem.stockLevel,
        packageQuantity: Number(elem.quantity),
        quantity: Number(elem.quantity),
        unitName: elem.unitName,
        unitPrice: 0,
        discount: 0,
        totalPrice: 0,
      });
    });

    sumProductsTotal(orderItemsCopy);
    setOrderItems(orderItemsCopy);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////Submit and check values/////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////

  const checkValidation = () => {
    let sumitValues = {
      orderInfo: orderInfo,
      orderItems: orderItems,
      costs: addtionalCosts.filter((x) => x.costName && x.cost),
      files: orderFiles,
      orderSummary: orderSummary,
    };

    const hasErrors = validate(sumitValues);

    if (Object.keys(hasErrors).length === 0) {
      submit(sumitValues);
      setFormErrors({});
    } else {
      setFormErrors(hasErrors);
    }
  };

  // check input validation
  const validate = (values) => {
    const errors = {};

    if (!values.orderInfo.orderNumber) {
      errors.orderNumber = "Order number is required!";
    }
    if (!values.orderInfo.issueDate) {
      errors.issueDate = "Issue date is required!";
    }
    if (!values.orderInfo.expireDate) {
      errors.expireDate = "Expire date is required!";
    }
    if (!values.orderInfo.customer) {
      errors.customer = "Customer is required!";
    }
    if (!values.orderInfo.salesperson) {
      errors.salesperson = "Salesperson is required!";
    }
    if (!values.orderInfo.taxRate) {
      errors.taxRate = "Tax rate is required!";
    }
    if (!values.orderInfo.currency) {
      errors.currency = "Currency is required!";
    }

    if (!values.orderInfo.location) {
      errors.location = "Sale location is required!";
    }

    // check if the order items is filled
    if (values.orderItems.length <= 0) {
      errors.orderItemsError = "Please add at least one product to the quote.";
    } else {
      values.orderItems.forEach((item) => {
        if (item.type !== "packageProduct" && !item.unitPrice) {
          errors.orderItemsError = "Product price is required.";
        }

        if (!item.quantity) {
          errors.orderItemsError = "Product quantity is required.";
        }
        if (!Number(item.quantity) > 0) {
          errors.orderItemsError = "Product quantity must be greater than 0.";
        }
      });
    }

    return errors;
  };

  //////////////////////////////////////calculate margin//////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const getAttachments = (files) => {
    let new_files = [];
    files.forEach((x) => {
      new_files.push({
        fileName: x.fileName,
        uri: x.uri,
      });
    });

    setOrderFiles(new_files);
  };
  const getNewCustomerSuccess = (newCustomer) => {
    let optionCopy = [...customersOptions];
    optionCopy.unshift(newCustomer);
    setCustomerOptions(optionCopy);
    getCustomerDetails(newCustomer);
    setNewCustomerModal(false);
  };

  return (
    <div>
      <ContentModal
        title="Scan Barcode"
        isModalOpen={scanModal}
        closeOnOverlayClick={false}
        closeModal={() => setScanModal(false)}
        content={
          <SaleOrderScanForm
            locationId={orderInfo.location ? orderInfo.location.id : ""}
            priceTierId={orderInfo.priceTier ? orderInfo.priceTier.id : ""}
            completeScan={getScanedProducts}
          />
        }
      />
      <NewCustomerModal
        title="Create New Customer"
        isModalOpen={newCustomerModal}
        closeModal={() => setNewCustomerModal(false)}
        onCreateSuccess={getNewCustomerSuccess}
      />

      <div className={salesStyle.sectionCard}>
        <div className="grid grid-cols-4 gap-8">
          {userDetailsSuccess ? (
            userDetails.data.permissions.includes(
              "cross_location_sale_order"
            ) ? (
              <StyledSelect
                label="Sale Location*"
                placeHolder="Select sale location"
                selectOptions={options.locationOptions}
                value={orderInfo.location}
                clearable={false}
                onChange={(location) =>
                  handleLocationChange({
                    target: { value: location, name: "location" },
                  })
                }
                error={formErrors.location}
              />
            ) : (
              <ReadOnlyField
                label="Sale Location*"
                value={orderInfo.location.label}
              />
            )
          ) : (
            <></>
          )}
          <DateInput
            label="Issue Date*"
            disabledPreDate={false}
            error={formErrors.issueDate}
            value={orderInfo.issueDate}
            onChange={(issueDate) =>
              handleChange({
                target: {
                  value: issueDate,
                  name: "issueDate",
                },
              })
            }
          />
          <DateInput
            label="Expire Date*"
            disabledPreDate={false}
            error={formErrors.expireDate}
            value={orderInfo.expireDate}
            onChange={(expireDate) =>
              handleChange({
                target: {
                  value: expireDate,
                  name: "expireDate",
                },
              })
            }
          />

          <StyledInput
            label="Order Number"
            type="text"
            name="orderNumber"
            value={orderInfo.orderNumber}
            onChange={(orderNumber) =>
              handleChange({
                target: { value: orderNumber, name: "orderNumber" },
              })
            }
            error={formErrors.orderNumber}
          />
        </div>
      </div>

      <div className={salesStyle.sectionCard}>
        <SubTitle name="Customer Information" />
        <div className={purchaseStyle.orderInfoCon}>
          <div className={purchaseStyle.supplierCon}>
            <div>
              <CreateableSelectField
                label="Customer*"
                options={customersOptions}
                name={"customer"}
                value={orderInfo.customer}
                onChange={(customer) =>
                  handleCustomerChange({
                    target: { value: customer },
                  })
                }
                placeholder="Select customer"
                autoFocus={false}
                readOnly={false}
                clearable={true}
                noResultText="No options"
                addBtnName="Add a new customer"
                openAddNewModal={() => setNewCustomerModal(true)}
                error={formErrors.customer}
              />
            </div>

            {orderInfo.customer ? (
              <div className="bg-lightbg p-4">
                <ContactCard
                  contactName={orderInfo.customer.value}
                  billingName={orderInfo.customerBillingName}
                  firstName={orderInfo.firstName}
                  lastName={orderInfo.lastName}
                  phone={orderInfo.phone}
                  email={orderInfo.email}
                  postalAddress={orderInfo.postalAddress}
                  billingAddress={orderInfo.billingAddress}
                  contactLink={`/customer/preview/${orderInfo.customer.id}`}
                />
              </div>
            ) : (
              <></>
            )}
            <StyledSelect
              label="Salesperson"
              placeHolder="Select salesperson"
              value={orderInfo.salesperson}
              selectOptions={options.userOptions}
              onChange={(salesperson) =>
                handleChange({
                  target: { value: salesperson, name: "salesperson" },
                })
              }
              clearable={false}
              error={formErrors.salesperson}
            />
          </div>

          <div className={purchaseStyle.shippingCon}>
            <StyledSelect
              label="Price Tier"
              placeHolder="Select price tier"
              value={orderInfo.priceTier}
              selectOptions={options.priceTierOptions}
              onChange={(priceTier) =>
                handleChange({
                  target: { value: priceTier, name: "priceTier" },
                })
              }
              error={formErrors.priceTier}
            />
            <StyledSelect
              label="Tax Rate*"
              placeHolder="Select tax rate"
              clearable={false}
              value={orderInfo.taxRate}
              selectOptions={options.taxrateOptions}
              onChange={(taxRate) =>
                handleChange({
                  target: { value: taxRate, name: "taxRate" },
                })
              }
              error={formErrors.taxRate}
            />
            <StyledInput
              label="Currency*"
              value={orderInfo.currency}
              type="text"
              name="currency"
              isReadOnly
            />
            {options.projectsOptions.length > 0 ? (
              <StyledSelect
                label="Project"
                placeHolder="Select project"
                value={orderInfo.project}
                selectOptions={options.projectsOptions}
                onChange={(project) =>
                  handleChange({
                    target: { value: project, name: "project" },
                  })
                }
              />
            ) : (
              <></>
            )}

            <StyledTextarea
              label="Notes"
              type="text"
              name="notes"
              value={orderInfo.notes}
              onChange={(notes) =>
                handleChange({
                  target: { value: notes, name: "notes" },
                })
              }
              error={formErrors.notes}
            />
          </div>
        </div>
      </div>

      {/************ order items ***********************/}
      <div className={salesStyle.sectionCard}>
        <div className={purchaseStyle.orderItemsCon}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <SubTitle name="Quote Products" />
            <SingleRatioButton
              label="Amounts are tax inclusive"
              name="taxIncluded"
              isOn={orderInfo.taxIncluded}
              onChange={(taxIncluded) =>
                handleChange({
                  target: { value: taxIncluded, name: "taxIncluded" },
                })
              }
            />
          </div>

          {formErrors.orderItemsError ? (
            <p className={commonStyle.tableError}>
              {formErrors.orderItemsError}
            </p>
          ) : (
            <></>
          )}

          {updateStockLoadingOpen ? (
            <Loader mess="Updating stock level, please wait a moment..." />
          ) : (
            <></>
          )}

          <div className={salesStyle.orderActionsCon}>
            <div style={{ width: "60%" }}>
              <ProductPicker
                locationId={orderInfo.location ? orderInfo.location.id : ""}
                priceTierId={orderInfo.priceTier ? orderInfo.priceTier.id : ""}
                onSelect={getProductFromPicker}
                requiredType={"saleProduct"}
                locationRequired={false}
                showCreateBtn={false}
              />
            </div>

            <div className={salesStyle.orderItemsActionCon}>
              <BarcodeScanButton name="Scan Barcode" action={openScanModal} />

              <div
                onClick={() =>
                  updateProductsStock(
                    orderInfo.location ? orderInfo.location.id : ""
                  )
                }
                className={salesStyle.updateStockBtn}
              >
                Check Stock Level
              </div>
            </div>
          </div>

          <div className={commonStyle.longDataTable}>
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Product Name</th>
                  <th>Unit</th>
                  <th>Unit Price</th>
                  <th>Discount</th>
                  <th>Net Price</th>
                  <th>Quantity</th>
                  <th>Total Price</th>
                  <th
                    style={{
                      margin: "auto",
                      borderLeft: "1px solid var(--border-color)",
                    }}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {orderItems.map((item, index1) => {
                  return (
                    <tr key={index1}>
                      <td>
                        {item.variantSku && Number(item.famid) > 0 && (
                          <ProductCodeLinkToNewTab productId={item.famid} name={item.variantSku} />
                        )}
                      </td>

                      {item.type !== "package" ? (
                        <td className={purchaseStyle.productNameCol}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {item.type === "packageProduct" ? (
                              <div className={salesStyle.packageProductTag}>
                                Package
                              </div>
                            ) : (
                              <></>
                            )}
                            <div>
                              <div>
                                {item.productName}
                              </div>
                              {item.productType === "product" &&
                                <div style={{ marginTop: "4px" }}>
                                  {Number(item.stockOnHand) > 0 ? (
                                    <span className={salesStyle.stockTag}>
                                      {convtQuantity(item.stockOnHand)} stock on
                                      hand
                                    </span>
                                  ) : (
                                    <span className={salesStyle.noStockTag}>
                                      No Stock Available
                                    </span>
                                  )}
                                </div>
                              }
                            </div>
                          </div>
                        </td>
                      ) : (
                        <td className={salesStyle.productPackageNameCol}>
                          <div className={salesStyle.productPackageName}>
                            {item.packageName}
                          </div>
                        </td>
                      )}
                      <td>{item.unitName}</td>

                      <td
                        style={{
                          width: "10%",
                        }}
                      >
                        {item.type !== "packageProduct" ? (
                          <div>
                            {userDetailsSuccess ? (
                              userDetails.data.permissions.includes(
                                "change_sale_price"
                              ) ? (
                                <NoBorderInput
                                  type="text"
                                  name="unitPrice"
                                  value={item.unitPrice}
                                  onChange={(unitPrice) =>
                                    handleOrderItemChange({
                                      type: "price",
                                      target: {
                                        value: unitPrice,
                                        key: "unitPrice",
                                        index: index1,
                                      },
                                    })
                                  }
                                />
                              ) : (
                                <PriceText num={item.unitPrice} />
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td
                        style={{
                          width: "10%",
                        }}
                      >
                        {item.type !== "packageProduct" ? (
                          <NoBorderPercentInput
                            type="text"
                            name="discount"
                            value={item.discount}
                            onChange={(discount) =>
                              handleOrderItemChange({
                                type: "price",
                                target: {
                                  value: discount,
                                  key: "discount",
                                  index: index1,
                                },
                              })
                            }
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>
                        {item.type !== "packageProduct" ? (
                          <div>
                            {item.discount ? (
                              <PriceText
                                num={convtPrice(
                                  (1 - Number(item.discount) / 100) *
                                    Number(item.unitPrice)
                                )}
                              />
                            ) : (
                              <PriceText num={item.unitPrice} />
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td
                        style={{
                          width: "10%",
                        }}
                      >
                        {item.type !== "package" ? (
                          <NoBorderInput
                            type="text"
                            name="quantity"
                            value={item.quantity}
                            onChange={(quantity) =>
                              handleOrderItemChange({
                                type: "quantity",
                                target: {
                                  value: quantity,
                                  key: "quantity",
                                  index: index1,
                                },
                              })
                            }
                          />
                        ) : (
                          <NoBorderInput
                            type="text"
                            name="quantity"
                            value={item.quantity}
                            onChange={(quantity) =>
                              handlePackageQuantityChange({
                                type: "quantity",
                                target: {
                                  value: quantity,
                                  key: "quantity",
                                  index: index1,
                                  packageId: item.packageId,
                                },
                              })
                            }
                          />
                        )}
                      </td>

                      <td
                        style={{
                          width: "10%",
                          maxWidth: "15%",
                        }}
                      >
                        {item.type !== "packageProduct" ? (
                          <PriceText num={item.totalPrice} />
                        ) : (
                          <></>
                        )}
                      </td>

                      <td
                        style={{
                          borderLeft: "1px solid var(--border-color)",
                          width: "4%",
                        }}
                      >
                        {item.type !== "packageProduct" ? (
                          <XDeleteButton
                            action={() =>
                              deleteOrderItem(index1, item.type, item.packageId)
                            }
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="7" className={commonStyle.tableFootName}>
                    Total
                  </td>

                  <td>
                    <PriceText num={orderSummary.productsTotal} />
                  </td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
            <div className="w-full mt-8">
              <div className={purchaseStyle.summaryWrapper}>
                <div className={purchaseStyle.costCon}>
                  {/* additional cost */}
                  <SubTitle name="Additional Charge" />
                  <table className={commonStyle.pageTable}>
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Price</th>
                        <th
                          style={{
                            borderLeft: "1px solid var(--border-color)",
                          }}
                        ></th>
                      </tr>
                    </thead>

                    <tbody>
                      {addtionalCosts.map((c, index2) => {
                        return (
                          <tr key={index2}>
                            <td
                              className={purchaseStyle.productNameCol}
                              style={{
                                width: "60%",
                              }}
                            >
                              <NoBorderInput
                                type="text"
                                name="costName"
                                value={c.costName}
                                placeHolder="Eg.shipping cost / tax "
                                onChange={(costName) =>
                                  handleCostChange({
                                    target: {
                                      value: costName,
                                      key: "costName",
                                      index: index2,
                                    },
                                  })
                                }
                              />
                            </td>

                            <td
                              style={{
                                width: "36%",
                              }}
                            >
                              <NoBorderInput
                                type="text"
                                name="cost"
                                value={c.cost}
                                onChange={(cost) =>
                                  handleCostChange({
                                    type: "price",
                                    target: {
                                      value: cost,
                                      key: "cost",
                                      index: index2,
                                    },
                                  })
                                }
                              />
                            </td>

                            <td
                              style={{
                                borderLeft: "1px solid var(--border-color)",
                                width: "4%",
                              }}
                            >
                              <XDeleteButton
                                action={() => deleteCostItem(index2)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className={commonStyle.tableFootName}>Total</td>

                        <td>
                          <PriceText num={orderSummary.additionalCostTotal} />
                        </td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                  <AddItem
                    name="Add Additional Charge"
                    action={addAdditionalCost}
                  />

                  <div className="mt-4">
                    <OrderAttachments
                      onUploadSuccess={getAttachments}
                      initialFiles={orderFiles}
                    />
                  </div>
                </div>

                <div className={purchaseStyle.summaryCon}>
                  {/* summary total price */}
                  <SubTitle name="Order Summary" />
                  <table
                    className={commonStyle.summaryTable}
                    style={{ marginTop: "12px" }}
                  >
                    {orderInfo.taxIncluded ? (
                      <tbody>
                        <tr>
                          <td className={commonStyle.tableFootName}>
                            Products Total
                          </td>

                          <td style={{ width: "30%" }}>
                            <PriceText num={orderSummary.productsTotal} />
                          </td>
                        </tr>
                        <tr>
                          <td className={commonStyle.tableFootName}>
                            Additional Charge Total
                          </td>
                          <td style={{ width: "30%" }}>
                            <PriceText num={orderSummary.additionalCostTotal} />
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.summaryTableTotalName}>
                            Order Total{" "}
                            <span className={commonStyle.taxText}>
                              Tax Incl.
                            </span>
                          </td>

                          <td className={commonStyle.summaryTableTotalValue}>
                            {orderSummary.productsTotal ? (
                              <PriceText
                                num={
                                  Number(orderSummary.productsTotal) +
                                  Number(orderSummary.additionalCostTotal)
                                }
                              />
                            ) : (
                              "0.00"
                            )}{" "}
                            {orderInfo.currency}
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.tableFootName}>
                            Total{" "}
                            <span className={commonStyle.taxText}>
                              Tax Excl.
                            </span>
                          </td>

                          <td style={{ width: "30%" }}>
                            {orderInfo.taxRate ? (
                              <PriceText
                                num={convtPrice(
                                  (Number(orderSummary.productsTotal) +
                                    Number(orderSummary.additionalCostTotal)) /
                                    (1 + Number(orderInfo.taxRate.rate) / 100)
                                )}
                              />
                            ) : (
                              "0.00"
                            )}
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.tableFootName}>
                            Tax
                            <span className={commonStyle.taxText}>
                              {orderInfo.taxRate
                                ? "-" + orderInfo.taxRate.value
                                : ""}
                            </span>
                          </td>

                          <td style={{ width: "30%" }}>
                            {orderInfo.taxRate ? (
                              <PriceText
                                num={convtPrice(
                                  Number(orderSummary.productsTotal) +
                                    Number(orderSummary.additionalCostTotal) -
                                    (Number(orderSummary.productsTotal) +
                                      Number(
                                        orderSummary.additionalCostTotal
                                      )) /
                                      (1 + Number(orderInfo.taxRate.rate) / 100)
                                )}
                              />
                            ) : (
                              "0.00"
                            )}
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td className={commonStyle.tableFootName}>
                            Products Total
                          </td>

                          <td style={{ width: "30%" }}>
                            <PriceText num={orderSummary.productsTotal} />
                          </td>
                        </tr>
                        <tr>
                          <td className={commonStyle.tableFootName}>
                            Additional Charge Total
                          </td>
                          <td style={{ width: "30%" }}>
                            <PriceText num={orderSummary.additionalCostTotal} />
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.tableFootName}>
                            Total{" "}
                            <span className={commonStyle.taxText}>
                              Tax Excl.
                            </span>
                          </td>

                          <td style={{ width: "30%" }}>
                            {orderInfo.taxRate ? (
                              <PriceText
                                num={
                                  Number(orderSummary.productsTotal) +
                                  Number(orderSummary.additionalCostTotal)
                                }
                              />
                            ) : (
                              "0.00"
                            )}
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.tableFootName}>
                            Tax
                            <span className={commonStyle.taxText}>
                              {orderInfo.taxRate
                                ? "-" + orderInfo.taxRate.value
                                : ""}
                            </span>
                          </td>

                          <td style={{ width: "30%" }}>
                            {orderInfo.taxRate ? (
                              <PriceText
                                num={convtPrice(
                                  (Number(orderSummary.productsTotal) +
                                    Number(orderSummary.additionalCostTotal)) *
                                    (Number(orderInfo.taxRate.rate) / 100)
                                )}
                              />
                            ) : (
                              "0.00"
                            )}
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.summaryTableTotalName}>
                            Order Total{" "}
                            <span className={commonStyle.taxText}>
                              Tax Incl.
                            </span>
                          </td>

                          <td className={commonStyle.summaryTableTotalValue}>
                            {orderSummary.productsTotal && orderInfo.taxRate ? (
                              <PriceText
                                num={convtPrice(
                                  Number(orderSummary.productsTotal) +
                                    Number(orderSummary.additionalCostTotal) +
                                    (Number(orderSummary.productsTotal) +
                                      Number(
                                        orderSummary.additionalCostTotal
                                      )) *
                                      (Number(orderInfo.taxRate.rate) / 100)
                                )}
                              />
                            ) : (
                              "0.00"
                            )}
                            {orderInfo.currency}
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex items-center justify-center mt-4">
        <div className="w-full">
        <SimpleBlueButton
            name="Save"
            action={checkValidation}
          />

        </div>
      </div>
    </div>
  );
}
