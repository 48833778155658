import React from "react";
import styledComponentsStyle from "../style/styledEditComponentsStyle.module.css";
import { BsPlusCircle, BsInboxesFill, BsX } from "react-icons/bs";
import {
  AiFillEdit,
  AiOutlineDelete,
  AiOutlineClose,
  AiOutlineCloudDownload,
  AiOutlineBarcode,
} from "react-icons/ai";
import { LuRefreshCcw } from "react-icons/lu";

import {
  MdOutlinePreview,
  MdFileCopy,
  MdLocalShipping,
  MdSimCardDownload,
  MdOutlineCancel,
  MdOutlineEmail,
  MdOutlineAssignmentReturned,
} from "react-icons/md";
import { NavLink } from "react-router-dom";
import { Loader } from "./viewComponents";

// screenTitle component
function ScreenTitle({ title, status, buttonComponent = "" }) {
  let className;
  if (status === "New") {
    className = styledComponentsStyle.pageStatusNew;
  } else if (status === "Edit") {
    className = styledComponentsStyle.pageStatusEdit;
  }

  return (
    <div className={styledComponentsStyle.pageTitleContainer}>
      <div style={{display:"flex", alignItems:"center"}}>
      {status ? <div className={className}>{status}</div> : <></>}
      <h2 className="text-[16px] font-medium">{title}</h2>
      </div>
      {
        buttonComponent ? buttonComponent : ""
      }
    </div>
  );
}

// ratio button
function SingleRatioButton({ name, label, isOn, onChange, labelSize }) {
  return (
    <div className={styledComponentsStyle.singleRatioCon}>
      <label
        className={
          labelSize
            ? `${styledComponentsStyle.checkboxContainer} ${styledComponentsStyle.labelSize}`
            : styledComponentsStyle.checkboxContainer
        }
      >
        {label}
        <input
          type="checkbox"
          name={name}
          checked={isOn}
          onChange={(e) => onChange(e.target.checked)}
        />
        <span className={styledComponentsStyle.checkmark}></span>
      </label>
    </div>
  );
}

// ratio button
function RatioButton({ id, name, value, label, isOn, onChange }) {
  return (
    <>
      <label className={styledComponentsStyle.checkboxContainer}>
        {label}
        <input
          title={label}
          id={id}
          type="checkbox"
          name={name}
          checked={isOn}
          onChange={(e) => onChange(e)}
        />
        <span className={styledComponentsStyle.checkmark}></span>
      </label>
    </>
  );
}
// add new button component
function DownloadButton({ name, action }) {
  return (
    <div className={styledComponentsStyle.styledDownloadButtonContainer}>
      <div className={styledComponentsStyle.addBtnArea} onClick={action}>
        <AiOutlineCloudDownload />
        <span className={styledComponentsStyle.styledAddButton}>{name}</span>
      </div>
    </div>
  );
}

function AddButton({ name, action }) {
  return (
    <div className={styledComponentsStyle.styledAddButtonContainer}>
      <div className={styledComponentsStyle.addBtnArea} onClick={action}>
        <BsPlusCircle />
        <span className={styledComponentsStyle.styledAddButton}>{name}</span>
      </div>
    </div>
  );
}

function AddOutlineButton({ name, action }) {
  return (
    <div className={styledComponentsStyle.styledAddButtonContainer}>
      <div className={styledComponentsStyle.addBtnOutlineArea} onClick={action}>
        <BsPlusCircle />
        <span className={styledComponentsStyle.styledAddButton}>{name}</span>
      </div>
    </div>
  );
}

function AddLinkButton({ name, path, data }) {
  return (
    <div className={styledComponentsStyle.styledAddButtonContainer}>
      <NavLink to={path} state={data} style={{ textDecoration: "none" }}>
        <div className={styledComponentsStyle.addBtnArea}>
          <BsPlusCircle />
          <span className={styledComponentsStyle.styledAddButton}>{name}</span>
        </div>
      </NavLink>
    </div>
  );
}

function AddLinkOutlineButton({ name, path, data }) {
  return (
    <div className={styledComponentsStyle.styledAddButtonContainer}>
      <NavLink to={path} state={data} style={{ textDecoration: "none" }}>
        <div className={styledComponentsStyle.addBtnOutlineArea}>
          <BsPlusCircle />
          <span className={styledComponentsStyle.styledAddButton}>{name}</span>
        </div>
      </NavLink>
    </div>
  );
}

// edit button component
function RentalReturnButton({ action }) {
  return (
    <div className={styledComponentsStyle.actionBtn}>
      <MdOutlineAssignmentReturned
        className={styledComponentsStyle.editBtn}
        onClick={action}
      />

      <div className={styledComponentsStyle.actionBtnTitle}>Return</div>
    </div>
  );
}

// edit button component
function EditButton({ action }) {
  return (
    <div className={styledComponentsStyle.actionBtn}>
      <AiFillEdit
        className={styledComponentsStyle.editBtn}
        // className={`${styledComponentsStyle.editBtn} ${styledComponentsStyle.actionBtn}`}
        onClick={action}
      />

      <div className={styledComponentsStyle.actionBtnTitle}>Edit</div>
    </div>
  );
}

// edit button component
function EditButtonLink({ path, data }) {
  return (
    <div className={styledComponentsStyle.actionBtn}>
      <NavLink to={path} state={data}>
        <AiFillEdit className={styledComponentsStyle.editBtn} />
      </NavLink>
      <div className={styledComponentsStyle.actionBtnTitle}>Edit</div>
    </div>
  );
}

// view button component
function ViewButton({ action }) {
  return (
    <div className={styledComponentsStyle.actionBtn}>
      <MdOutlinePreview
        className={styledComponentsStyle.viewBtn}
        onClick={action}
      />
      <div className={styledComponentsStyle.actionBtnTitle}>Preview</div>
    </div>
  );
}

function ViewButtonLink({ path, data }) {
  return (
    <div className={styledComponentsStyle.actionBtn}>
      <NavLink to={path} state={data}>
        <MdOutlinePreview className={styledComponentsStyle.viewBtn} />
      </NavLink>
      <div className={styledComponentsStyle.actionBtnTitle}>Preview</div>
    </div>
  );
}

function CloneButtonLink({ path, data }) {
  return (
    <div className={styledComponentsStyle.actionBtn}>
      <NavLink to={path} state={data}>
        <MdFileCopy className={styledComponentsStyle.cloneBtn} />
      </NavLink>
      <div className={styledComponentsStyle.actionBtnTitle}>Clone</div>
    </div>
  );
}

// delete button component
function DeleteButton({ action }) {
  return (
    <div className={styledComponentsStyle.actionBtn}>
      <AiOutlineDelete
        className={styledComponentsStyle.deleteBtn}
        onClick={action}
      />
      <div className={styledComponentsStyle.actionBtnTitle}>Delete</div>
    </div>
  );
}

// delete button component
function XDeleteButton({ action }) {
  return (
    <div className={styledComponentsStyle.actionBtn}>
      <AiOutlineClose
        className={styledComponentsStyle.deleteBtn}
        onClick={action}
      />
      <div className={styledComponentsStyle.actionBtnTitle}>Delete</div>
    </div>
  );
}

function CircleDeleteButton({ action }) {
  return (
    <div className={styledComponentsStyle.actionBtn}>
      <MdOutlineCancel
        className={styledComponentsStyle.circleDeleteBtn}
        onClick={action}
      />
      <div className={styledComponentsStyle.actionBtnTitle}>Delete</div>
    </div>
  );
}

function SelectedFilterButton({ name, action }) {
  return (
    <div className="flex flex-row items-center bg-lightbg rounded-full pl-2 py-1 mr-4">
      <span className="text-sm text-brandColor mr-1">{name}</span>
      <div className={styledComponentsStyle.actionBtn}>
        <MdOutlineCancel
          className={styledComponentsStyle.circleDeleteBtn}
          onClick={action}
        />
        <div className={styledComponentsStyle.actionBtnTitle}>Delete</div>
     </div>
    </div>
  
  );
}

function DownloadPDFLink({ path, data }) {
  return (
    <div className={styledComponentsStyle.actionBtn}>
      <NavLink to={path} state={data} target="_blank">
        <MdSimCardDownload className={styledComponentsStyle.downloadBtn} />
      </NavLink>
      <div className={styledComponentsStyle.actionBtnTitle}>Download</div>
    </div>
  );
}

function DownloadPDFButton({ name, action }) {
  return (
    <div className={styledComponentsStyle.downloadBtnCon} onClick={action}>
      <MdSimCardDownload className={styledComponentsStyle.downloadBtn} />
      <span>{name}</span>
    </div>
  );
}

function DownloadInvoiceButton({ name, action }) {
  return (
    <div className={styledComponentsStyle.downloadBtnCon} onClick={action}>
      <div className={styledComponentsStyle.downloadInvoiceBtn}></div>
      <span>{name}</span>
    </div>
  );
}

// view button component
function EmailButton({ name, action }) {
  return (
    <div className={styledComponentsStyle.emailBtnCon} onClick={action}>
      <MdOutlineEmail className={styledComponentsStyle.downloadBtn} />
      <span>{name}</span>
    </div>
  );
}

function ActionText({ action, title }) {
  return (
    <div className={styledComponentsStyle.actionText} onClick={action}>
      {title}
    </div>
  );
}





function InvoiceButton({ name, action }) {
  return (
    <div className={styledComponentsStyle.actionNameBtn} onClick={action}>
      <div className={styledComponentsStyle.downloadInvoiceBtn}> </div>
      <span className={styledComponentsStyle.actionName}>{name}</span>

      <div className={styledComponentsStyle.actionBtnNameTitle}>
        Download Invoice
      </div>
    </div>
  );
}

function ReceiveStockButton() {
  return (
    <div className={styledComponentsStyle.actionBtn}>
      <BsInboxesFill
        className={styledComponentsStyle.receiveStockBtn}
        // className={`${styledComponentsStyle.editBtn} ${styledComponentsStyle.actionBtn}`}
      />

      <div className={styledComponentsStyle.actionBtnTitle}>Receive Stock</div>
    </div>
  );
}
// delete button component

function ShippingButton({ action }) {
  return (
    <div className={styledComponentsStyle.actionBtn}>
      <MdLocalShipping
        className={styledComponentsStyle.editBtn}
        onClick={action}
      />
      <div className={styledComponentsStyle.actionBtnTitle}>Ship</div>
    </div>
  );
}

//  button component with green color
function GreenButton({ name, action, fullWidth=true, marginRight=false }) {

  const clickAction = (e) => {
    e.preventDefault();
    action();
  }

  return (
    <button className={`${fullWidth ? "w-full" : "w-fit"} ${marginRight ? "mr-4" : ""} h-[40px] bg-brandGreen text-white hover:bg-brandGreenHover rounded px-3`} onClick={clickAction}>
      <span>{name}</span>
    </button>
  );
}

// add new button component
function RightBlueButton({ name, action }) {
  return (
    <div className={styledComponentsStyle.styledAddButtonContainer}>
      <div className={styledComponentsStyle.addBtnArea} onClick={action}>
        <span className={styledComponentsStyle.styledAddButton}>{name}</span>
      </div>
    </div>
  );
}
// button component with blue color
function BlueButton({ name, action }) {
  const handleClick = (event) => {
    event.preventDefault(); // Prevent auto-submit
    action(); // Call the original action
  };

  return (
    <button
      className={styledComponentsStyle.noMarginblueBtnCon}
      onClick={handleClick}
    >
      {name}
    </button>
  );
}

// button component with blue color
function SimpleBlueButton({ name, action }) {
  const handleClick = (event) => {
    event.preventDefault(); // Prevent auto-submit
    action(); // Call the original action
  };

  return (
    <button className="w-full h-[40px] bg-brandColor text-white hover:bg-brandHover rounded px-3" onClick={handleClick}>
      {name}
    </button>
  );
}

// button component with blue color
function SimpleOutlineButton({ name, action }) {
  const handleClick = (event) => {
    event.preventDefault(); // Prevent auto-submit
    action(); // Call the original action
  };

  return (
    <button
      className={styledComponentsStyle.outlineBtnCon}
      onClick={handleClick}
    >
      <span>{name}</span>
    </button>
  );
}

// button component with blue color
function SimpleOutlineDeleteButton({ name, action }) {
  const handleClick = (event) => {
    event.preventDefault(); // Prevent auto-submit
    action(); // Call the original action
  };

  return (
    <button
      className="h-[40px] w-full border border-solid border-red-500 text-red-500 rounded hover:bg-red-500 hover:text-white"
      onClick={handleClick}
    >
      <span>{name}</span>
    </button>
  );
}

// button component with blue color
function XeroButton({ name, action }) {
  return (
    <div className={styledComponentsStyle.xeroBtnCon} onClick={action}>
      <div className={styledComponentsStyle.xeroLogo}></div>
      <span>{name}</span>
    </div>
  );
}

function LoadingButton({ name }) {
  return (
    <div className={styledComponentsStyle.loadingBtnCon}>
      <Loader /> <span>{name}</span>
    </div>
  );
}
// button component with blue color
function DisableBlueButton({ name }) {
  return (
    <div className={styledComponentsStyle.disableblueBtnCon}>
      <span>{name}</span>
    </div>
  );
}

// button component with blue color
function SimpleBlueButtonLink({ path, name, data }) {
  return (
    <div style={{ width: "100%" }}>
      <NavLink
        to={path}
        state={data}
        className={styledComponentsStyle.blueBtnLinkCon}
        style={{ textDecoration: "none" }}
      >
        {name}
      </NavLink>
    </div>
  );
}

function SimpleOutlineButtonLink({ path, name, data }) {
  return (
    <div>
      <NavLink
        to={path}
        state={data}
        className={styledComponentsStyle.outlineBtnCon}
        style={{ textDecoration: "none" }}
      >
        {name}
      </NavLink>
    </div>
  );
}

// button component with blue color
function TextLink({ path, name }) {
  return (
    <NavLink className={styledComponentsStyle.textLinkName} to={path}>
      {name}
    </NavLink>
  );
}

function SimpleLightBackButton({ name, action }) {
  return (
    <div className={styledComponentsStyle.lightBackBtnCon} onClick={action}>
      <span>{name}</span>
    </div>
  );
}

// edit button component
function GoToSettingLink({ path, text }) {
  return (
    <NavLink to={path} className={styledComponentsStyle.gotoSettingBtn}>
      {text}
    </NavLink>
  );
}

// button component with blue color
function InlineSaveButton({ name, action }) {
  return (
    <div className={styledComponentsStyle.inlineSaveBtnCon} onClick={action}>
      <span>{name}</span>
    </div>
  );
}

function InlineCancelButton({ name, action }) {
  return (
    <div className={styledComponentsStyle.inlineCancelBtnCon} onClick={action}>
      <span>{name}</span>
    </div>
  );
}

// Popup Window button component

// default label component
function DefaultLabel({ name }) {
  return <span className={styledComponentsStyle.defaultlabel}>{name}</span>;
}

//  button component with green color
function CloseButton({ action }) {
  return (
    <div className={styledComponentsStyle.closeIconContainer} onClick={action}>
      <BsX className={styledComponentsStyle.closeIcon} />
    </div>
  );
}

function AddItem({ action, name }) {
  const clickAction = (e) => {
    e.preventDefault();
    action()

  }
  return (
    <button className="flex items-center text-green-500 hover:underline mt-2" onClick={clickAction}>
      <BsPlusCircle />
      <p className="ml-1">
        {name}
      </p>
    </button>
  );
}

function BarcodeScanButton({ name, action }) {
  return (
    <div className={styledComponentsStyle.barcodeScanCon} onClick={action}>
      <AiOutlineBarcode />
      <span style={{ marginLeft: "4px" }}>{name}</span>
    </div>
  );
}
function RefreshButton({ action, name }) {
  return(
    <div className={styledComponentsStyle.refreshButCon} onClick={action}>
    <LuRefreshCcw />
    <span style={{marginLeft:"4px"}}>{name}</span>
  </div>

  )
}

export {
  ScreenTitle,
  SingleRatioButton,
  RatioButton,
  AddButton,
  AddLinkButton,
  AddLinkOutlineButton,
  EditButton,
  EditButtonLink,
  DeleteButton,
  ViewButton,
  ViewButtonLink,
  CloneButtonLink,
  InvoiceButton,
  GoToSettingLink,
  GreenButton,
  BlueButton,
  RightBlueButton,
  DefaultLabel,
  CloseButton,
  SimpleBlueButton,
  AddItem,
  XDeleteButton,
  SimpleLightBackButton,
  ReceiveStockButton,
  InlineSaveButton,
  InlineCancelButton,
  ShippingButton,
  DownloadPDFButton,
  ActionText,
  SimpleBlueButtonLink,
  CircleDeleteButton,
  DownloadButton,
  DisableBlueButton,
  LoadingButton,
  EmailButton,
  DownloadPDFLink,
  SimpleOutlineButton,
  BarcodeScanButton,
  DownloadInvoiceButton,
  XeroButton,
  TextLink,
  AddOutlineButton,
  RentalReturnButton,
  SimpleOutlineButtonLink,
  RefreshButton,
  SimpleOutlineDeleteButton,
  SelectedFilterButton
};
