import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { emailTemplatesListRequest } from "../../actions/orgSettingsActions";
import { ScreenTitle } from "../../components/editComponents";
import { ErrorMessage, Loader } from "../../components/viewComponents";
import commonStyle from "../../style/commonStyle.module.css";
import Breadcrumbs from "../../components/Breadcrumbs";
import useDocumentTitle from "../../useDocumentTitle";
import VerticalTabs from "../../components/VerticalTabs";
import EmailTemplateForm from "./EmailTemplateForm";

export default function EmailTemplatesScreen() {
  useDocumentTitle("Email Templates");
  // set the Modal

  // access check for the actions
  const { loading, templates, success, error } = useSelector(
    (state) => state.emailTemplateList
  );

  const [activeTab, setActiveTab] = useState("po");

  const tabs = [
    { id: 1, tabName: "Purchase order", value: "po" },
    { id: 2, tabName: "Sale order(invoice)", value: "so" },
    { id: 3, tabName: "Quote", value: "quote" },
  ];

  const changeTabAction = (tabId) => {
    setActiveTab(tabId);
  };

  const getTemplateContent = () => {
    if (templates.length) {
      let activeTabContent = templates.filter((x) => x.module === activeTab);
      if (activeTabContent.length) {
        return activeTabContent[0].content;
      } else {
        return "";
      }
    }
    return "";
  };

  const getTemplateSubject =() => {
    if (templates.length) {
      let activeTabContent = templates.filter((x) => x.module === activeTab);
      if (activeTabContent.length) {
        return activeTabContent[0].subject;
      } else {
        return "";
      }
    }
    return "";
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(emailTemplatesListRequest());
  }, [dispatch]);

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Email Templates" />
      <ScreenTitle title="Email Templates" />
      <div className={commonStyle.pageContentContainer}>
      <div className={commonStyle.pageContentContainerTwoColumn}>
        <VerticalTabs
          tabsData={tabs}
          changeTab={changeTabAction}
          activeTab={activeTab}
        />
        <div className={commonStyle.templatesContentWrapper}>
        {loading ? (
          <Loader mess="Requesting template data..." />
        ) : success ? (
          <EmailTemplateForm
            module={activeTab}
            content={getTemplateContent}
            subject={getTemplateSubject}
            changeTab={activeTab}
          />
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )}
        </div>
      </div>
      </div>
    </div>
  );
}
