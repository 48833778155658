import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../components/Breadcrumbs";
import {
  EditButton,
  RefreshButton,
  ScreenTitle,
  SimpleBlueButton,
} from "../../components/editComponents";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import XeroIntegrationTab from "./XeroIntegrationTab";
import ImportXeroCustomers from "./importXeroData/ImportXeroCustomers";
import ImportXeroSuppliers from "./importXeroData/ImportXeroSuppliers";
import ImportXeroProducts from "./importXeroData/ImportXeroProducts";
import { importXeroDataTaskListRequest } from "../../actions/xeroActions";
import {
  ErrorMessage,
  ImportXeroDataErrors,
  Loader,
  SimpleDialog,
  SubTitle,
  Tag,
  ViewDateWithTime,
} from "../../components/viewComponents";
import Pagination from "../../components/Pagination";
import {
  systemSettingsDetailsRequest,
  updateSystemSettingsRequest,
} from "../../actions/systemSettingsActions";
import { ReadOnlyField, StyledSelect } from "../../components/inputFields";
import { locationListRequest } from "../../actions/orgSettingsActions";

export default function XeroSettingsScreen() {
  const dispatch = useDispatch();
  useDocumentTitle("Xero Settings");

  const INVOICE_OPTIONS = [
    { id: 1, label: "Yes", value: "yes" },
    { id: 2, label: "No", value: "no" },
  ];

  const BILL_NUMBER_OPTIONS = [
    {
      id: 1,
      label: "Use purchase order number as the Xero bill number",
      value: "po_order_number",
      notes: "The purchase order number will be sent to Xero and used as the bill number and reference. The order receive date will be used as the bill date, and the purchase order bill terms will determine the bill due date."
    },
    {
      id: 2,
      label: "Use supplier invoice number as the Xero bill number",
      value: "po_supplier_invoice_number",
      notes: "The supplier invoice number from the purchase order will be sent to Xero and used as the bill number and reference. The supplier invoice date will be used as the bill date, and the due date will follow the supplier invoice’s due date. If no supplier invoice number is provided, the order number will be used instead."
    },
  ];

  const { loading, settingDetails, error, success } = useSelector(
    (state) => state.systemSettingDetails
  );
  const {
    loading: locationLoading,
    locations,
    error: locationError,
    success: locationSuccess,
  } = useSelector((state) => state.locationList);

  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
  } = useSelector((state) => state.updateSystemSettings);

  const [formState, setFormState] = useState({
    hookInvoiceRule: {},
    hookDefaultLocation: "",
    xeroBillNumberRule: {},
  });

  const [formErrors, setFormErrors] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);

  useEffect(() => {
    dispatch(systemSettingsDetailsRequest("xero"));
    dispatch(locationListRequest("selectOptions"));
  }, [dispatch]);

  useEffect(() => {
    if (success && locationSuccess) {
      let findHookInvoiceRule = settingDetails.find(
        (x) => x.settingName === "xero_webhook_invoice"
      );
      let hook_rule_value = "";
      if (findHookInvoiceRule) {
        hook_rule_value = INVOICE_OPTIONS.find(
          (x) => x.value === findHookInvoiceRule.settingValue
        );
      }

      let findDefaultLocation = settingDetails.find(
        (x) => x.settingName === "xero_webhook_default_location_id"
      );

      let location_value = "";
      if (findDefaultLocation) {
        location_value = locations.find(
          (x) => Number(x.id) === Number(findDefaultLocation.settingValue)
        );
      }

      // find xero bill number rule
      let findXeroBillNumberRule = settingDetails.find(
        (x) => x.settingName === "xero_bill_number_rule"
      );
      let bill_number_rule_value = "";
      if (findXeroBillNumberRule) {
        bill_number_rule_value = BILL_NUMBER_OPTIONS.find(
          (x) => x.value === findXeroBillNumberRule.settingValue
        );
      }

      setFormState({
        hookDefaultLocation: location_value ? location_value : "",
        hookInvoiceRule: hook_rule_value ? hook_rule_value : {},
        xeroBillNumberRule: bill_number_rule_value
          ? bill_number_rule_value
          : {},
      });
    }
  }, [success, locationSuccess]);

  useEffect(() => {
    if(updateSuccess){
      dispatch(systemSettingsDetailsRequest("xero"));
    }
  }, [dispatch, updateSuccess]);



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const checkValidation = () => {
    const hasErrors = validate(formState);

    if (Object.values(hasErrors).length === 0) {
      // setEdit(false);
      let submitValues = {
        hookInvoiceRule: formState.hookInvoiceRule
          ? formState.hookInvoiceRule.value
          : "no",
        hookDefaultLocationId: formState.hookDefaultLocation.id,
        xeroBillNumberRule: formState.xeroBillNumberRule
          ? formState.xeroBillNumberRule.value
          : "",
      };
      saveChanges(submitValues);
    } else {
      setFormErrors(hasErrors);
    }
  };

  // check input validation
  const validate = (values) => {
    const errors = {};

    if (!values.hookDefaultLocation) {
      errors.hookDefaultLocation = "Please select a default location.";
    }
    if (!values.hookInvoiceRule) {
      errors.hookInvoiceRule =
        "Please select whether sync invoice from Xero to StockUnify.";
    }
    if (!values.xeroBillNumberRule) {
      errors.xeroBillNumberRule = "Please select a rule for generating Xero bill numbers";
    }
    return errors;
  };

  const saveChanges = (values) => {
    dispatch(updateSystemSettingsRequest("xero", values));
    setUpdateDialog(true);
    setIsEdit(false);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Update Xero Settings"
        successMessage="Updated successfully."
        isDialogOpen={updateDialog}
        closeDialog={() => setUpdateDialog(false)}
        loading={updateLoading}
        loadingMessage="Processing request..."
        success={updateSuccess}
        error={updateError}
        btnValue="OK"
        confirmAction={() => setUpdateDialog(false)}
      />

      <Breadcrumbs screenName="Xero Settings" />
      <ScreenTitle title="Xero Settings" />

      <div className={commonStyle.tabsContainer}>
        <XeroIntegrationTab tabName="settings" />
        <div className={commonStyle.settingsContent}>
          <div className="w-full min-h-[980px]">
            {loading ? (
              <Loader mess="Requesting details, please wait a moment..." />
            ) : success ? (
              <div className="w-1/2 h-full">
                <div className="w-full flex flex-row justify-between mt-8">
                  <p className="text-brandColor">Xero Settings</p>
                  {!isEdit && <EditButton action={() => setIsEdit(true)} />}
                </div>

                <div className="mt-6">
                  <p className="text-brandColor">Xero Webhook Settings</p>
                  <div className="bg-green-50 p-4 rounded mt-4">
                    <p>
                      * Enable Xero Webhook to automatically sync invoices from
                      Xero to StockUnify. When an invoice is created and saved
                      as approved in Xero, a corresponding sales order will be
                      generated in StockUnify with a 'Draft' status.
                    </p>
                  </div>

                  <div className="mt-4">
                    {isEdit ? (
                      <StyledSelect
                        label={
                          "Automatically Sync Invoices from Xero to StockUnify?"
                        }
                        clearable={false}
                        placeHolder="Select tax rule"
                        selectOptions={INVOICE_OPTIONS}
                        value={formState.hookInvoiceRule}
                        onChange={(hookInvoiceRule) =>
                          handleChange({
                            target: {
                              value: hookInvoiceRule,
                              name: "hookInvoiceRule",
                            },
                          })
                        }
                        error={formErrors.hookInvoiceRule}
                      />
                    ) : (
                      <ReadOnlyField
                        label={
                          "Automatically Sync Invoices from Xero to StockUnify?"
                        }
                        value={formState.hookInvoiceRule.label}
                      />
                    )}
                  </div>

                  <div className="mt-8">
                    {isEdit ? (
                      <StyledSelect
                        label={
                          "Please select a default location for the auto-created sales order"
                        }
                        clearable={false}
                        placeHolder="Select location"
                        selectOptions={locationSuccess ? locations : []}
                        value={formState.hookDefaultLocation}
                        onChange={(hookDefaultLocation) =>
                          handleChange({
                            target: {
                              value: hookDefaultLocation,
                              name: "hookDefaultLocation",
                            },
                          })
                        }
                        error={formErrors.hookDefaultLocation}
                      />
                    ) : (
                      <ReadOnlyField
                        label={
                          "Please select a default location for the auto-created sales order"
                        }
                        value={formState.hookDefaultLocation.label}
                      />
                    )}
                  </div>
                </div>
                <div className="mt-6">
                  <p className="text-brandColor">Xero Bill Settings</p>

                  <div className="mt-4">
                    {isEdit ? (
                      <StyledSelect
                        label={"Please select a rule for generating Xero bill numbers"}
                        clearable={false}
                        placeHolder="Select..."
                        selectOptions={BILL_NUMBER_OPTIONS}
                        value={formState.xeroBillNumberRule}
                        onChange={(xeroBillNumberRule) =>
                          handleChange({
                            target: {
                              value: xeroBillNumberRule,
                              name: "xeroBillNumberRule",
                            },
                          })
                        }
                        error={formErrors.xeroBillNumberRule}
                      />
                    ) : (
                      <ReadOnlyField
                        label={"Please select a rule for generating Xero bill numbers"}
                        value={formState.xeroBillNumberRule.label}
                      />
                    )}
                    {formState.xeroBillNumberRule && <p className="mt-2 text-[12px]">{formState.xeroBillNumberRule.notes}</p>}
                  </div>
                </div>

                {isEdit && (
                  <div className="w-full mt-6">
                    <SimpleBlueButton
                      name="Save Changes"
                      action={checkValidation}
                    />
                  </div>
                )}
              </div>
            ) : error ? (
              <ErrorMessage mess={error} />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
