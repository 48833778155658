import React, { useEffect, useState } from "react";
import { FaFileCsv } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  importCsvRequest,
  importExportLogsRequest,
} from "../../../actions/IOActions.js";
import Breadcrumbs from "../../../components/Breadcrumbs.js";
import UploadFileField from "../../../components/UploadFileField.js";
import {
  RefreshButton,
  ScreenTitle,
} from "../../../components/editComponents.js";
import {
  ErrorMessage,
  ImportErrors,
  Loader,
  SimpleDialog,
  Tag,
  ViewDateWithTime,
} from "../../../components/viewComponents.js";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle.js";
import ioStyle from "../../io/ioStyle.module.css";

export default function ImportProductsForUpdateScreen() {
  useDocumentTitle("Import Products for Update");
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const {
    loading: logsLoading,
    logsList,
    error: logsError,
    success: logsSuccess,
  } = useSelector((state) => state.IOLogs);

  ////////////////////////////////////////////////////////////////////////////////////////////

  const { loading, success, error, showBtn } = useSelector(
    (state) => state.importCsv
  );

  ////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////
  const [importDialog, setImportDialog] = useState(false);

  const uploadProductsFile = (file) => {
    const formData = new FormData();
    formData.append("csv", file);
    dispatch(importCsvRequest(formData, "productsupdate"));
    setImportDialog(true);
    // setShowUploadBtn(false);
  };

  useEffect(() => {
    dispatch(importExportLogsRequest("productsupdate", "IMPORT"));
  }, [dispatch, success]);

  const refreshData = () => {
    dispatch(importExportLogsRequest("productsupdate", "IMPORT"));
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Upload CSV"
        successMessage="CSV file uploaded successfully. The data is currently being processed and saved to the database. You can check the results in below."
        isDialogOpen={importDialog}
        closeDialog={() => setImportDialog(false)}
        loading={loading}
        loadingMessage="Uploading file..."
        success={success}
        error={error}
        confirmAction={() => setImportDialog(false)}
      />

      <Breadcrumbs screenName={"Bulk Update Products"} />
      <ScreenTitle title={"Bulk Update Products"} />

      <div className={commonStyle.pageContentContainer}>
        <div className="bg-green-50 p-4 rounded">
          <p>
            You can use the [Bulk Update Products] feature to update details
            like name, category, sale price, barcode, and latest cost.
          </p>
          <p>
            <span className="font-bold mr-1">Important:</span>The product code
            (Code) is required for each entry, and must match an existing
            product in the system.
          </p>
          <p>
            Only fields with values will be updated. Fields left blank will keep
            their current values. For example, to update only the latest cost,
            leave all other fields blank.
          </p>
        </div>
        <div className={ioStyle.importTips}>
          <p>
            1. Download the bulk update product CSV template and fill in the
            required fields.
          </p>
          <a
            href={`https://d1o1skvu5gf7wj.cloudfront.net/rui/StockUnify_bulk_update_products_template_Mar2025.csv`}
            download={"products_update_template"}
            target="_blank"
            rel="noreferrer"
            className={ioStyle.fileName}
          >
            <p>Bulk Update Products Template</p>
          </a>
        </div>

        <div className={ioStyle.importTips}>
          <p>
            2. Drag and drop your CSV file into the area below to upload, or
            click anywhere in the area to select your CSV file for upload.
          </p>
        </div>
        <div className={ioStyle.importTips}>
          <p>(Only .csv format files are supported.)</p>
        </div>

        <UploadFileField
          requiredFileType={"csv"}
          onSubmit={uploadProductsFile}
          showBtn={showBtn ? showBtn : true}
          btnName={"Start to Import Data"}
          uploadFileSuccess={success}
        />

        <div
          style={{
            marginTop: "40px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h2>Update Product Tasks</h2>
          <RefreshButton name="Refresh Task Status" action={refreshData} />
        </div>
        {logsLoading ? (
          <Loader mess="Requesting data, please wait a moment..." />
        ) : logsSuccess ? (
          <table className={commonStyle.pageTable}>
            <thead>
              <tr>
                {/* <th></th> */}
                <th>Date</th>
                <th>File</th>
                <th>Operator</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {logsList.length > 0 ? (
                logsList.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <ViewDateWithTime value={item.atime} />
                    </td>
                    <td>
                      <a
                        href={item.inputFileUrl}
                        download={item.inputFileUrl}
                        rel="noreferrer"
                        className={ioStyle.fileName}
                      >
                        <FaFileCsv className={ioStyle.csvLogo} />
                        <p>{item.inputFile}</p>
                      </a>
                    </td>
                    <td>
                      {item.operatorFirstName} {item.operatorLastName}{" "}
                    </td>
                    <td>
                      {item.status === "QUEUED" ? (
                        <Tag name={item.status} color="yellow" />
                      ) : item.status === "PROCESSING" ? (
                        <Tag name={item.status} color="green" />
                      ) : item.status === "SUCCESS" ||
                        item.status === "PARTIAL SUCCESS" ? (
                        <Tag name={item.status} color="blue" />
                      ) : item.status === "FAILED" ? (
                        <Tag name={item.status} color="red" />
                      ) : (
                        <></>
                      )}
                    </td>
                    <td>
                      {item.errors ? (
                        <div>
                          {Number(item.successCnt) > 0 ? (
                            <p>Success imported quantity: {item.successCnt}</p>
                          ) : (
                            <></>
                          )}
                          {Number(item.errorCnt) > 0 ? (
                            <p>Failed quantity: {item.errorCnt}</p>
                          ) : (
                            <></>
                          )}
                          <div className="mt-2">
                            <p>{item.errors}</p>
                            {item.errorDetails &&
                            item.errorDetails.length > 0 ? (
                              <div className="pl-4">
                                <ImportErrors errors={item.errorDetails} />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}{" "}
                      <a
                        href={item.logFileUrl}
                        target="_blank"
                        rel="noreferrer"
                        className="mt-2 block text-brandColor hover:cursor-pointer hover:underline "
                      >
                        {" "}
                        Import logs
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>There are no import records.</td>
                </tr>
              )}
            </tbody>
          </table>
        ) : logsError ? (
          <ErrorMessage mess={logsError} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
