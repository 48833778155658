import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userlogout } from "../../actions/authAction.js";
import { productOptionsRequest } from "../../actions/productActions";
import { stockAdjustmentReportRequest } from "../../api/reportingServices";
import Breadcrumbs from "../../components/Breadcrumbs";
import Pagination from "../../components/Pagination";
import {
  LoadingButton,
  ScreenTitle,
  SimpleBlueButton,
  SimpleOutlineButton,
} from "../../components/editComponents";
import { DateInput, StyledInput, StyledMultiSelect } from "../../components/inputFields";
import {
  AdjustTypeTag,
  DollarTag,
  ErrorMessage,
  Loader,
  Tag,
  TextErrorMessage,
  VariantSkuTag,
  VariantTag,
  ViewDateText,
} from "../../components/viewComponents";
import { formatDate } from "../../functions/functions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import { CsvWriter } from "./CsvWriter.js";

export default function StockAdjustReportScreen() {
  useDocumentTitle("Stock Adjustment Report");
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const {
    loading: optionsLoading,
    options,
    error: optionsError,
    success: optionsSuccess,
  } = useSelector((state) => state.productOptions);

  // pagination

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);

  const [reportData, setReportDate] = useState([]);
  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [downloadStatus, setDownloadStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [showCost, setShowCost] = useState(false);

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  useEffect(() => {
    if (!options) {
      dispatch(productOptionsRequest());
    }
    if (options && !Object.keys(options).length > 0) {
      dispatch(productOptionsRequest());
    }
  }, [dispatch, options]);

  useEffect(() => {
    if(userDetailsSuccess){
      if(userDetails.data.permissions.includes("view_product_cost")){
        setShowCost(true);
      }else{
        setShowCost(false);
      }
    }
  }, [userDetailsSuccess]);

  // filter and search  function
  const [filterLocation, setFilterLocation] = useState([]);
  const [filterCategory, setFilterCategory] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterKeyword, setFilterKeyword] = useState("");
  const [filterError, setFilterError] = useState("");
  

  const applyFilter = () => {
    getProducts("filter");
    setPageNum(0);
  };

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  const downloadFile = async () => {
    // get full stocktake list
    let stockListdata = [];

    let startTimestamp = filterStartDate
      ? Math.floor(filterStartDate.setHours(0, 0, 0, 0) / 1000)
      : "";
    let endTimestamp = filterEndDate
      ? Math.floor(filterEndDate.setHours(23, 59, 59, 999) / 1000)
      : "";

    if (startTimestamp > endTimestamp) {
      setFilterError("The start date is greater than end date.");
      return;
    }

    let selectedFilterCategory = [];
    let selectedFilterLocation = [];

    if (filterCategory.length > 0) {
      filterCategory.forEach((cate) => {
        selectedFilterCategory.push(cate.id);
      });
    }

    if (filterLocation.length > 0) {
      filterLocation.forEach((loc) => {
        selectedFilterLocation.push(loc.id);
      });
    }

    try {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: true,
      }));

      const responseData = await stockAdjustmentReportRequest(
        0,
        99999999999,
        selectedFilterCategory.join(),
        selectedFilterLocation.join(),
        startTimestamp,
        endTimestamp,
        filterKeyword,
        config
      );

      if (responseData.success) {
        stockListdata = responseData.data.products;

        setDownloadStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      }else if(responseData.error === -3){
        dispatch(userlogout());
      }
    } catch (error) {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }

    //get csv file
    let csvString = "";
    if(showCost){
      let csvWriter = new CsvWriter([
        "Issue Date",
        "Location",
        "Adjust Type",
        "Code",
        "Product Name",
        "Category",
        "Adjust Qty",
        "Unit Cost",
        "Total Cost",
        "Notes",
        "Reason",
        "Creator",
        "Project",
        "Serial/Batch Number",
        "Expire Date",
      ]);
      for (let line of stockListdata) {
          // get expire date string
          let expire_dates = [];
          if(line.expireDates.length){
            expire_dates = line.expireDates.map(date => 
              date ? formatDate(new Date(Number(date) * 1000), 
                                userDetailsSuccess ? userDetails.data.orgInfo.date_format : "DD/MM/YYYY",
                                userDetailsSuccess ? userDetails.data.orgInfo.time_zone : "Pacific/Auckland") : ""
            )
          }

        csvWriter.addLine([
          line.issueTime ? formatDate(
            new Date(line.issueTime * 1000),
            userDetails.data.orgInfo.date_format,
            userDetails.data.orgInfo.time_zone
          ) : "",
          line.location,
          line.type==="RECEIVESTOCK" ? "Receive Stock" : line.type==="STOCKDEDUCTION" ? "Deduct Stock" : "Unknown",
          line.variantSku,
          line.name,
          line.category ? line.category : "",
          line.adjust_qty,
          line.unit_cost,
          line.total_cost,
          line.reasonName ? line.reasonName : "",
          line.notes,
          line.creatorFirstName + " " +( line.creatorLastName ? line.creatorLastName : ""),
          line.projectCode ? line.projectCode + "-" + line.projectName : "",
          line.serialBatchNumbers.length > 0 ? line.serialBatchNumbers.join(", ") : "",
          expire_dates.length ? expire_dates.join(", ") : ""
        ]);
      }
      csvString = csvWriter.content;
    }else{
      let csvWriter = new CsvWriter([
        "Issue Date",
        "Location",
        "Adjust Type",
        "Code",
        "Product Name",
        "Category",
        "Adjust Qty",
        "Notes",
        "Reason",
        "Creator",
        "Project",
        "Serial/Batch Number",
        "Expire Date",
      ]);
      for (let line of stockListdata) {
          // get expire date string
          let expire_dates = [];
          if(line.expireDates.length){
            expire_dates = line.expireDates.map(date => 
              date ? formatDate(new Date(Number(date) * 1000), 
                                userDetailsSuccess ? userDetails.data.orgInfo.date_format : "DD/MM/YYYY",
                                userDetailsSuccess ? userDetails.data.orgInfo.time_zone : "Pacific/Auckland") : ""
            )
          }

        csvWriter.addLine([
          line.issueTime ? formatDate(
            new Date(line.issueTime * 1000),
            userDetails.data.orgInfo.date_format,
            userDetails.data.orgInfo.time_zone
          ) : "",
          line.location,
          line.type==="RECEIVESTOCK" ? "Receive Stock" : line.type==="STOCKDEDUCTION" ? "Deduct Stock" : "Unknown",
          line.variantSku,
          line.name,
          line.category ? line.category : "",
          line.adjust_qty,
          line.reasonName ? line.reasonName : "",
          line.notes,
          line.creatorFirstName + " " +( line.creatorLastName ? line.creatorLastName : ""),
          line.projectCode ? line.projectCode + "-" + line.projectName : "",
          line.serialBatchNumbers.length > 0 ? line.serialBatchNumbers.join(", ") : "",
          expire_dates.length ? expire_dates.join(", ") : ""
        ]);
      }
      csvString = csvWriter.content;

    }


    // create a download link
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "stock-adjustment-report.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getProducts("initial");
  }, [pageNum]);

  const getProducts = async (type) => {
    let startTimestamp = filterStartDate
      ? Math.floor(filterStartDate.setHours(0, 0, 0, 0) / 1000)
      : "";
    let endTimestamp = filterEndDate
      ? Math.floor(filterEndDate.setHours(23, 59, 59, 999) / 1000)
      : "";

    if (startTimestamp > endTimestamp) {
      setFilterError("The start date is greater than end date.");
      return;
    }

    let selectedFilterCategory = [];
    let selectedFilterLocation = [];

    if (filterCategory.length > 0) {
      filterCategory.forEach((cate) => {
        selectedFilterCategory.push(cate.id);
      });
    }

    if (filterLocation.length > 0) {
      filterLocation.forEach((loc) => {
        selectedFilterLocation.push(loc.id);
      });
    }

    try {
      setRequestStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await stockAdjustmentReportRequest(
        type === "filter" ? 0 : pageNum,
        pageSize,
        selectedFilterCategory.join(),
        selectedFilterLocation.join(),
        startTimestamp,
        endTimestamp,
        filterKeyword,
        config
      );

      if (responseData.success) {
        setFilterError("");
        setReportDate(responseData.data);
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      }else if(responseData.error === -3){
        dispatch(userlogout());
      }
    } catch (error) {
      setRequestStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const DownloadBtn = () => {
    return (
      <div style={{ width: "auto" }}>
        {downloadStatus.loading ? (
          <LoadingButton name="Downloading.." />
        ) : (
          <SimpleBlueButton name="Download" action={downloadFile} />
        )}
      </div>
    );
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Stock Adjustment Report" />

      <ScreenTitle
        title="Stock Adjustment Report"
        buttonComponent={<DownloadBtn />}
      />
      <div className={commonStyle.pageContentContainer}>
        <div className="relative z-20" style={{ width: "100%", display: "flex", alignItems: "flex-end" }}>
          <div
            style={{
              width: "80%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "45%" }}>
              <DateInput
                label="Start Date"
                value={filterStartDate}
                disableFuturedate
                onChange={(startDate) => setFilterStartDate(startDate)}
              />
              <StyledMultiSelect
                label="Location"
                selectOptions={optionsSuccess ? options.location : []}
                value={filterLocation}
                onChange={(location) => setFilterLocation(location)}
              />
              <StyledInput
                label="Notes"
                type="text"
                name="filterKeyword"
                value={filterKeyword}
                onChange={(filterKeyword) => setFilterKeyword(filterKeyword)}
              />
            </div>
            <div style={{ width: "45%" }}>
              <DateInput
                label="End Date"
                value={filterEndDate}
                disableFuturedate
                onChange={(endDate) => setFilterEndDate(endDate)}
              />
              <StyledMultiSelect
                label="Category"
                selectOptions={optionsSuccess ? options.category : []}
                value={filterCategory}
                onChange={(category) => setFilterCategory(category)}
              />
            </div>
          </div>
          <div
            style={{
              width: "20%",
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "-20px",
            }}
          >
            <SimpleOutlineButton name="Apply Filter" action={applyFilter} />
          </div>
        </div>

        {requestStatus.loading ? (
          <Loader mess="Requesting report, please wait a moment..." />
        ) : requestStatus.success ? (
          <div className={commonStyle.longDataTable}>
            {/* hold the product list table */}
            {filterError ? (
              <div style={{ marginTop: "20px" }}>
                <TextErrorMessage mess={filterError} />
              </div>
            ) : (
              <></>
            )}
            <div className={commonStyle.longDataTableContainer}>
            <div className={commonStyle.tableWrapper}>
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th className={`min-w-[100px] ${commonStyle.stickyColumn}`}>Issue Date</th>
                  <th className={`min-w-[100px] ${commonStyle.stickyColumn}`}>Location</th>
                  <th className="min-w-[100px]">Adjust Type</th>
                  <th className="min-w-[100px]">Code</th>
                  <th className="min-w-[240px]">Product Name</th>
                  <th className="min-w-[100px]">Category</th>
                  <th className="min-w-[80px]">Adjust Quantity</th>
                  {showCost && <th className="min-w-[80px]">Unit Cost</th>}
                  {showCost && <th className="min-w-[80px]">Total Cost</th>}
                  <th className="min-w-[100px]">Reason</th>
                  <th className="min-w-[200px]">Notes</th>
                  <th className="min-w-[100px]">Creator</th>
                  <th className="min-w-[240px]">Project</th>
                  <th className="min-w-[160px]">Serial/Batch Number</th>
                  <th className="min-w-[100px]">Expire Date</th>
                </tr>
              </thead>
              <tbody>
                {reportData.products.length > 0 ? (
                  reportData.products.map((item) => (
                    <React.Fragment key={item.id}>
                      <tr>
                        <td className={`min-w-[100px] ${commonStyle.stickyColumn}`}>
                          <ViewDateText value={item.issueTime} />
                        </td>
                        <td className={`min-w-[100px] ${commonStyle.stickyColumn}`}>
                          <Tag name={item.location} color="gray" />
                        </td>
                        <td>
                          <AdjustTypeTag name={item.type} />
                        </td>
                        <td> <VariantSkuTag name={item.variantSku} /></td>
                        <td>{item.name}</td>
                        <td>{item.category}</td>
                        <td>{item.adjust_qty}</td>

                        {showCost && <td><DollarTag />{item.unit_cost}</td>}
                        {showCost && <td><DollarTag />{item.total_cost}</td>}
                        <td>{item.reasonName ? item.reasonName : ""}</td>
                        <td>{item.notes}</td>
                        <td>{item.creatorFirstName + " " +( item.creatorLastName ? item.creatorLastName : "")}</td>
                        <td>{item.projectCode ? item.projectCode + "-" + item.projectName : ""}</td>
                        <td>
                          {item.serialBatchNumbers.length ? item.serialBatchNumbers.map((item, index) => <p key={index}>{item}</p> ) : <></>}
                        </td>
                        <td>
                          {item.expireDates.length ? item.expireDates.map((item, index) => <div className="flex flex-col"><ViewDateText key={index} value={item} /></div> ) : <></>}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td>
                      <p>There are no data in selected period.</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            </div>
            </div>
            {reportData.products.length > 0 ? (
              <Pagination
                totalPageNum={reportData.total_pages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : requestStatus.error ? (
          <ErrorMessage mess="Requesting report data failed, please try again later." />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
